import React from "react";
import "./Background.css";
import { isMobile } from "react-device-detect";
import { generateFromTemplate } from "./Backgrounds/BackgroundGenerator";
import { LocalSettings } from "../configuration/AppConfig";

const Background = (props: BackgroundProperties) => {
  let content;
  if (props.useTemplate) content = renderContentWithTemplate(props.children);
  else content = props.children;

  const logoClassName = LocalSettings.useColorMobileLogo ? "" : "background-logo-inverted";
  return (
    <div
      className="App-content"
      style={{
        backgroundImage: `url(${getBackgroundImageContent()})`,
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      <div
        style={{ display: isMobile || props.hideMainLogo ? "none" : "inherit" }}
      >
        <img
          className={logoClassName}
          src={LocalSettings.logoMobileUrl}
          style={{
            imageRendering: "-webkit-optimize-contrast",
            position: "absolute",
            left: 20,
            top: LocalSettings.bgLogoTopOffsetDesktop,
            width: 220
          }}
          alt="..."
        />
        <img
          className={logoClassName}
          src={LocalSettings.logoPoweredByWhiteUrl}
          style={{
            display: LocalSettings.logoPoweredByVisible ? "inherit" : "none",
            imageRendering: "-webkit-optimize-contrast",
            position: "absolute",
            right: 20,
            top: -10,
            width: 220
          }}
          alt="..."
        />
      </div>
      {content}
    </div>
  );


  function getBackgroundImageContent(): string {
    if (props.useSvg) {
      const data = `'data:image/svg+xml,${generateFromTemplate(props.image)}'`
      return data;
    } else {
      return getStaticBackgroundImage(props.image);
    }
  }


  function getStaticBackgroundImage(imageName: string) {
    switch (imageName) {
      case "finish":
      default:
        return LocalSettings.finishBackgroundImage;
    }
  }

  function renderContentWithTemplate(children: any) {
    let displayLogo = props.useLogo && isMobile;
    let className = "merchant-logo-stage-1";
    if (!LocalSettings.useColorMobileLogo){
      className += " background-logo-inverted";
    }
    return (
      <div>
        <div
          className="background-animated background-fadeInBottom"
          style={{ position: "relative" }}
        >
          <div>
            <img
              style={{
                display: displayLogo ? "inherit" : "none",
                imageRendering: "-webkit-optimize-contrast"
              }}
              
              className={className}
              src={LocalSettings.logoMobileUrl}
              alt="merchant_logo"
            ></img>
          </div>
          <div className="central-template" style={{ paddingBottom: 20 }}>
            <div>{children}</div>
          </div>
        </div>
        {/* {renderBackgroundFooter()} */}
      </div>
    );
  }

  function renderBackgroundFooter() {
    if (props.useFooter) {
      let imageDimensionSize, fontSize;
      if (isMobile) {
        imageDimensionSize = 40;
        fontSize = 16;
      } else {
        imageDimensionSize = 50;
        fontSize = "1.25rem";
      }

      return (
        <div className="background-footer">
          <div>
            <img
              width={imageDimensionSize}
              height={imageDimensionSize}
              src={`${process.env.PUBLIC_URL}/assets/svg/lock.svg`}
              alt="lock"
            />
            <span style={{ fontSize: fontSize }} className="lead">
              Protected by bank-level security
            </span>
          </div>
        </div>
      );
    }
  }
};

export default Background;

type BackgroundProperties = {
  image: string;
  children: any;
  useTemplate: boolean;
  useFooter: boolean;
  useLogo?: boolean;
  useSvg?: boolean;
  hideMainLogo?: boolean;
};
