import React from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { Form, Col, Row, Label } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import AddressLookup from "../AddressLookup";
import { isMobile } from "react-device-detect";

export default function StepBusinessAddress() {
  const dispatch = useDispatch();
  const data = useStepBusinessAddress();

  const businessAddressStepTranslation =
    LocalSettings.translation.applicationPage.businessAddressStep;

  //   let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  //   let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";
  let labelStyle = { fontSize: "1rem" };
  return (
    <div id="stepBusinessAddress">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {businessAddressStepTranslation.mainTitle}
      </h2>
      {/* <h4
        style={{
          color: Styles.textColorTitleSecondary,
          textAlign: "center" as "center",
        }}
      >
        
      </h4> */}
      <div className="stepper-form">
        <Form>
          <Row form>
            <Col md={12}>
              <AddressLookup
                onAddressChange={data.handleChangeManual}
                placeholder={businessAddressStepTranslation.addressPlaceHolder}
                value={data.businessAddress}
                name="businessAddress"
                label={businessAddressStepTranslation.businessAddress}
                labelStyle={labelStyle}
                className="stepper-item"
                bsSize={size}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  function useStepBusinessAddress(): StepBusinessAddressState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );
    const handleChangeManual = useCallback(
      (value: string, propertyName: string) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        }),
      []
    );
    return {
      businessAddress: input.businessAddress,
      handleChange: handleChange,
      handleChangeManual: handleChangeManual,
    };
  }
}

type StepBusinessAddressState = {
  businessAddress: string;
  handleChange: Function;
  handleChangeManual: (splitAddress: string, propertyName: string) => void;
};
