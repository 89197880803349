import React, { ReactComponentElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import InitialBackground from './InitialBackground';
import StepperBackground from './StepperBackground';

export const generateFromTemplate = (name: string) => {
    let component: any;
    let staticMarkup: any;
    switch (name) {
        case "stepper":
            component = <StepperBackground />
            break;
        case "initial":
            component = <InitialBackground />
            break;
    }
    staticMarkup = ReactDOMServer.renderToStaticMarkup(component);
    return encodeURIComponent(staticMarkup);
}


