import React, { useRef, useState } from "react";
import { LocalSettings, Styles } from "../configuration/AppConfig";
import { Button } from "reactstrap";
import { RemainingItem } from "../store/Store";
import ModalDialog from "../components/ModalDialog";
import UploadDropZone from "../components/UploadDropZone";
import { useParams } from "react-router-dom";

export const DocumentPageUploadFile = (props: DocumentPageUploadProps) => {
  const styles = {
    uploadCaption: {
      float: "left",
    },
    uploadButton: {
      backgroundColor: Styles.textColorStart,
      borderColor: Styles.textColorStart,
      color: Styles.defaultColor,
      width: 120,
    },
    body1: {
      fontSize: 10,
    },
    caption: {
      fontSize: 14,
    },
    completedButton: {
      backgroundColor: Styles.defaultColor,
      borderColor: Styles.defaultColor,
      color: Styles.textColorSecondary,
      boxShadow: "unset",
      width: 120
    },
    actionContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: Styles.documentUploadBorderRadius,
      backgroundColor: Styles.defaultColor,
      boxShadow:
        "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
      padding: 10,
      margin: "auto",
    },
    textItem: {
      color: Styles.textColorPrimary,
      fontSize: "1rem",
    },
    textItemDescription: {
      color: "rgb(117, 117, 117)",
      fontSize: "0.8rem",
    },
    icon: {
      margin: 10,
    },
    itemName: {
      width: 300,
      textAlign: "left" as "left",
    },
  };

  const uploadMethodRef = useRef<() => void>();
  const [uploadState, setUploadState] = useState<UploadStateEnum>(
    UploadStateEnum.Opened
  );
  const [modalDialogOpen, setModalDialogOpen] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>(
    props.status as FileUploadStatus
  );
  const [
    fileUploadStatusDescription,
    setFileUploadStatusDescription,
  ] = useState(null);

  const documentsTranslation = LocalSettings.translation.documentsPage;

  const data = useDocumentPageUploadFile(props);
  return (
    <div>
      <div className="action-container" style={styles.actionContainer}>
        <div>
          <img
            className="action-icon"
            alt="action-icon"
            src={renderImage(fileUploadStatus)}
            style={styles.icon}
          />
        </div>
        <div style={styles.itemName}>
          <div style={styles.textItem} className="item-name">
            {props.itemName}
          </div>
          <div style={styles.textItemDescription}>{props.description}</div>
        </div>
        <div>
          <div>{renderUploadButton(fileUploadStatus)}</div>
          {/* <div>{renderUploadButton(props.status)}</div> */}
        </div>
      </div>
      <div>{renderUploadDialog()}</div>
    </div>
  );

  function handleUploadFinished(response: any): void {
    setFileUploadStatus(FileUploadStatus.SUCCESS);
  }

  function handleErrorUpload(errorResponse: any): void {
    setFileUploadStatusDescription(errorResponse.data.message);
    setFileUploadStatus(FileUploadStatus.ERROR);
  }

  function renderImage(fileUploadStatus: FileUploadStatus) {
    switch (fileUploadStatus) {
      case FileUploadStatus.SUCCESS:
        return `${process.env.PUBLIC_URL}/assets/svg/completed.svg`;
      default:
        return `${process.env.PUBLIC_URL}/assets/svg/warning.svg`;
    }
  }

  function renderUploadButton(status: FileUploadStatus) {
    switch (status) {
      // Submitted
      case FileUploadStatus.SUCCESS:
        return (
          <div>
            <Button disabled style={styles.completedButton}>
              {documentsTranslation.doneButtonText}
            </Button>
          </div>
        );
      // Not Submitted
      default:
        return (
          <Button
            style={styles.uploadButton}
            onClick={() => setModalDialogOpen(true)}
          >
            {documentsTranslation.uploadButtonText}
          </Button>
        );
    }
  }

  function renderUploadDialog() {
    let agreeText: string;
    let uploadLaterText: string = documentsTranslation.ufUploadLaterText;
    let agreeEnabled: boolean = false;
    let hideCloseButton: boolean = false;
    let handleAgreed: Function;
    let handleClose: Function = () => {
      setUploadState(UploadStateEnum.Opened);
      setModalDialogOpen(false);
    };
    switch (uploadState) {
      case UploadStateEnum.Opened:
        agreeText = documentsTranslation.ufUploadText;
        agreeEnabled = false;
        hideCloseButton = false;
        handleAgreed = () => {};
        break;
      case UploadStateEnum.Selected:
        agreeText = documentsTranslation.ufUploadSelected;
        agreeEnabled = true;
        hideCloseButton = false;
        handleAgreed = () => {
          setUploadState(UploadStateEnum.Processing);
          if (uploadMethodRef.current) uploadMethodRef.current();
        };
        break;
      case UploadStateEnum.Processing:
        agreeText = documentsTranslation.ufUploadSelected;
        agreeEnabled = false;
        hideCloseButton = false;
        handleAgreed = () => {};
        break;
      case UploadStateEnum.Uploaded:
        agreeText = documentsTranslation.ufUploadFinish;
        agreeEnabled = true;
        hideCloseButton = true;
        handleAgreed = () => {
          setModalDialogOpen(false);
        };
        break;
      default:
        throw new Error("Unknown status!");
    }

    return (
      <div>
        <ModalDialog
          title={`${documentsTranslation.dzPleaseUploadYour} ${data.itemName}`}
          agreeText={agreeText}
          agreeEnabled={agreeEnabled}
          disagreeText={uploadLaterText}
          textStyle={{ color: "#fff", fontSize: "20px", fontWeight: 400 }}
          handleClose={handleClose}
          handleAgreed={handleAgreed}
          handleDisagreed={handleClose}
          contentStyle={{
            backgroundColor: Styles.textColorPrimary,
          }}
          open={modalDialogOpen}
          hideCloseButton={hideCloseButton}
          buttonAgreeStyle={{
            backgroundColor: Styles.textColorSecondaryAlt,
            borderColor: Styles.textColorSecondaryAlt,
            color: Styles.defaultColor,
            boxShadow: "unset",
          }}
          buttonDisagreeStyle={{
            display: hideCloseButton ? "none" : "inherit",
            backgroundColor: Styles.textColorSecondaryAlt,
            borderColor: Styles.textColorSecondaryAlt,
            color: Styles.defaultColor,
            boxShadow: "unset",
          }}
        >
          <UploadDropZone
            uploadFinishedCallback={handleUploadFinished}
            uploadErrorCallback={handleErrorUpload}
            externalId={data.externalId}
            applicationId={data.applicationId}
            itemName={data.itemName}
            itemStatus={fileUploadStatus}
            uploadMethodRef={uploadMethodRef}
            uploadState={uploadState}
            setUploadState={setUploadState}
          />
        </ModalDialog>
      </div>
    );
  }

  function useDocumentPageUploadFile(item: RemainingItem): UploadFileData {
    const { applicationId } = useParams<{ applicationId: string }>();
    if (!applicationId) throw new Error("applicationId should be specified!");
    return {
      applicationId: applicationId,
      externalId: item.externalId,
      itemName: item.itemName,
      status: item.status,
    };
  }
};

type UploadFileData = {
  applicationId: string;
  externalId: string;
  itemName: string;
  status: string;
};

type DocumentPageUploadProps = {
  externalId: string;
  itemName: string;
  description: string;
  status: string;
};

export enum UploadStateEnum {
  Opened = "opened",
  Selected = "selected",
  Processing = "processing",
  Uploaded = "uploaded",
}

export enum FileUploadStatus {
  SUCCESS = "Submitted",
  ERROR = "Cancelled",
  NEW = "Requested",
  RESUBMISSION_REQUESTED = "Re-Requested",
}
