import React from "react";
import { useEffect, useRef } from "react";
import { LocalSettings } from "../configuration/AppConfig";
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    //@ts-ignore
    if (window.Trustpilot) {
      //@ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  let locale: "en-US" | "fr-CA";
  let subdomain: "ca" | "fr";
  switch (LocalSettings.language) {
    case "fr":
      locale = "fr-CA";
      subdomain = "fr";
      break;
    default:
      locale = "en-US";
      subdomain = "ca";
      break;
  }

  return (
    <div
      className="trustpilot-widget"
      data-locale={locale}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5cedcbc17383ae000100fd13"
      data-style-height="120px"
      data-style-width="100%"
      data-theme="light"
      ref={ref}
    >
      <a
        href={`https://www.${subdomain}.trustpilot.com/review/merchantgrowth.com`}
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
