import React, { useEffect, useState } from "react";
import Dropzone from "dropzone";
import {
  Button,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Spinner,
  Progress,
} from "reactstrap";
import Api from "../utils/Api";
import { LocalSettings, Styles } from "../configuration/AppConfig";
import { isMobile } from "react-device-detect";
import { FileUploadStatus, UploadStateEnum } from "./DocumentPageUploadFile";

Dropzone.autoDiscover = false;
let dropZone: Dropzone;

function UploadDropZone(props: DropZoneProps) {
  const styles = {
    dropZoneMessage: {
      minHeight: 250,
      display: "flex",
      backgroundColor: Styles.bgDropzone,
    },
    dropZoneDescription: {
      maxHeight: 50,
      margin: "auto",
      color: Styles.defaultColor,
    },
    uploadItemName: {
      fontSize: "30px",
    },
    uploadButtonMobile: {
      backgroundColor: Styles.bgDropzone,
      height: 100,
      width: 250,
      display: "flex",
      justifyContent: "center",
      border: "1px dashed #DEE2E6",
      boxSizing: "border-box" as "border-box",
      borderRadius: 4,
      margin: "auto",
    },
  };

  const documentsTranslation = LocalSettings.translation.documentsPage;

  const [percentage, setPercentage] = useState(0);
  const [
    mobileFilesToUploadCount,
    setMobileFilesToUploadCount,
  ] = useState<number>(0);
  let mobileFilesToUpload: FileList;

  const uploadFiles = (): void => {
    if (isMobile) {
      if (mobileFilesToUpload) handleFileUpload(mobileFilesToUpload);
    } else {
      dropZone.processQueue();
    }
  };

  useEffect(() => {
    if (!isMobile) {
      setupDropzone();
    } else {
      setUploadMethodReference();
    }
  }, []);

  let data = useDropZone(props);

  if (isMobile) {
    return renderMobileUploader();
  } else {
    return renderDropzone();
  }

  function useDropZone(props: DropZoneProps): DropZoneState {
    return {
      externalId: props.externalId,
      applicationId: props.applicationId,
      itemName: props.itemName,
      itemStatus: props.itemStatus,
    };
  }

  function setupDropzone() {
    const { setUploadState } = props;

    let dropZoneElement = document.getElementById("dropzone-multiple");
    if (!dropZoneElement) throw new Error("Cannot find dropzone element!");

    dropZone = new Dropzone(dropZoneElement, {
      url: Api.getUploadUrl(),
      thumbnailWidth: undefined,
      thumbnailHeight: undefined,
      //@ts-ignore
      paramName: getFileName,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-multiple"
      )[0] as HTMLElement,
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0]
        .innerHTML,
      // maxFiles: 1,
      acceptedFiles: undefined,
      autoProcessQueue: false,
      parallelUploads: 100,
      uploadMultiple: true,
      init: function () {
        this.on("addedfiles", function (files) {
          setUploadState(UploadStateEnum.Selected);
        });
        this.on("removedfile", function (file) {
          if (dropZone.files.length === 0) {
            setUploadState(UploadStateEnum.Opened);
          }
        });
        this.on("sendingmultiple", function (files, xhr, formData) {
          formData.append("applicationId", data.applicationId);
          formData.append("externalId", data.externalId);
          formData.append("type", "Self");
        });
        this.on("successmultiple", function (file: any, response: any) {
          dropZone.removeAllFiles();
          setUploadState(UploadStateEnum.Uploaded);
          props.uploadFinishedCallback(response);
        });
        this.on("uploadprogress", function (file: any) {
          const { bytesSent, total } = file.upload;
          let progressEvent = { loaded: bytesSent, total: total };
          handleSetUploadPercentageFromEvent(progressEvent);
        });
      },
    });

    setUploadMethodReference();

    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
  }

  function setUploadMethodReference() {
    // Setting the upload method  reference to be used from the modal dialog:
    const { uploadMethodRef } = props;
    uploadMethodRef.current = uploadFiles;
  }

  function getFileName(): string {
    return "files";
  }

  function handleSetUploadPercentageFromEvent(progressEvent: any) {
    const { loaded, total } = progressEvent;
    let newValue = Math.round((loaded * 100) / total);
    setPercentage(newValue);
  }

  function handleFileSelect(files: FileList) {
    const { setUploadState } = props;
    mobileFilesToUpload = files;
    setMobileFilesToUploadCount(files.length);
    setUploadState(UploadStateEnum.Selected);
  }

  function handleFileUpload(files: FileList): void {
    const { setUploadState } = props;

    let formData: FormData = new FormData();
    formData.append("applicationId", data.applicationId);
    formData.append("externalId", data.externalId);
    formData.append("type", "Self");
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });

    // Setting uploading state to "Processing":
    setUploadState(UploadStateEnum.Processing);

    Api.uploadFile(
      formData,
      (response: any) => {
        setUploadState(UploadStateEnum.Uploaded);
        props.uploadFinishedCallback(response);
      },
      (errorResponse: any) => {
        props.uploadErrorCallback(errorResponse);
      },
      (progressEvent: any) => {
        handleSetUploadPercentageFromEvent(progressEvent);
      }
    );
  }

  function renderDropzone() {
    const { itemName } = data;
    const { uploadState } = props;

    let displayStyle: string;
    switch (uploadState) {
      case UploadStateEnum.Opened:
      case UploadStateEnum.Selected:
        displayStyle = "inherit";
        break;
      case UploadStateEnum.Processing:
      case UploadStateEnum.Uploaded:
        displayStyle = "none";
        break;
    }

 

    return (
      <div>
        <p style={{ color: Styles.defaultColor }}>{documentsTranslation.dzChooseFilesText.replace('$itemName', itemName)}</p>
        <div className="dropzone dropzone-multiple" id="dropzone-multiple">
          <div className="fallback">
            <div className="custom-file">
              <input
                className="custom-file-input"
                id="customFileUploadMultiple"
                multiple={true}
                type="file"
              />
              <label
                className="custom-file-label"
                htmlFor="customFileUploadMultiple"
              >
              </label>
            </div>
          </div>
          <ListGroup
            style={{
              display: displayStyle,
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: 250,
            }}
            className="dz-preview dz-preview-multiple list-group-lg"
            flush
          >
            <ListGroupItem className="px-0 animated slide-in-left">
              <Row className="align-items-center" style={{ padding: 5 }}>
                <Col className="col-auto">
                  <div className=" avatar">
                    <img
                      alt="..."
                      className="avatar-img rounded"
                      data-dz-thumbnail
                      src={`${process.env.PUBLIC_URL}/assets/svg/mobileuploadimg.svg`}
                    />
                  </div>
                </Col>
                <div className="col ml--3">
                  <h4 className="mb-1" data-dz-name>
                    ...
                  </h4>
                  <p className="small text-muted mb-0" data-dz-size>
                    ...
                  </p>
                </div>
                <Col className="col-auto">
                  <Button size="sm" color="danger" data-dz-remove>
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="dz-progress">
                  <span className="dz-upload" data-dz-uploadprogress></span>
                </div>
              </Row>
            </ListGroupItem>
          </ListGroup>
          {renderDropZoneProgress(percentage)}
          <div className="dz-default dz-message" style={styles.dropZoneMessage}>
            <div style={styles.dropZoneDescription}>
              {documentsTranslation.dzDropFilesToUploadText}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDropZoneProgress(percentage: number) {
    const { uploadState } = props;
    switch (uploadState) {
      case UploadStateEnum.Uploaded:
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: 50, height: 50, margin: 15 }}
              src={`${process.env.PUBLIC_URL}/assets/svg/completed.svg`}
              alt="completed"
            ></img>
            <span style={{ color: Styles.defaultColor, margin: 30 }}>
              {documentsTranslation.dzFilesUploadedText}
            </span>
          </div>
        );
      case UploadStateEnum.Processing:
        return renderProgressBar(percentage);
    }
  }

  function renderUploaderButton(itemPercentage: number) {
    const { uploadState } = props;
    switch (uploadState) {
      case UploadStateEnum.Selected:
        return (
          <h5 style={{ color: Styles.defaultColor }}>
            {mobileFilesToUploadCount} {documentsTranslation.dzFilesSelected}{" "}
          </h5>
        );
      case UploadStateEnum.Processing:
        return renderProgressBar(itemPercentage);
      case UploadStateEnum.Opened:
        return (
          <div style={styles.uploadButtonMobile}>
            <div style={{ height: 50, margin: "auto" }}>
              <div>
                <input
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                  multiple
                  name={`upload_input_${data.externalId}`}
                  style={{ display: "none" }}
                  id={`upload_input_${data.externalId}`}
                  type="file"
                  onChange={(e) => {
                    if (e.target.files && e.target.files?.length > 0) {
                      let files: FileList = e.target.files;
                      handleFileSelect(files);
                    }
                  }}
                />
                <label htmlFor={`upload_input_${data.externalId}`}>
                  <div style={{ width: "100%" }}>
                    <img
                      style={{ width: 50, height: 50 }}
                      src={`${process.env.PUBLIC_URL}/assets/svg/mobileuploadimg.svg`}
                      alt="mobile_upload"
                    />{" "}
                    {documentsTranslation.dzTapToUploadText}
                  </div>
                </label>
              </div>
            </div>
          </div>
        );
    }
  }

  function renderMobileUploader() {
    const { itemName } = data;
    const { uploadState } = props;
    let contents;

    switch (uploadState) {
      case UploadStateEnum.Uploaded:
        contents = (
          <div>
            <img
              style={{ width: 150, height: 150 }}
              src={`${process.env.PUBLIC_URL}/assets/svg/completed.svg`}
              alt="completed"
            ></img>
            <div style={styles.uploadItemName}>{documentsTranslation.dzThankYou}</div>
            <h5 style={{ color: Styles.defaultColor }}>
              {mobileFilesToUploadCount} {documentsTranslation.dzFilesWereUploadedText}
            </h5>
            <div>{documentsTranslation.dzTapFinishToContinueText}</div>
            <div>
              {documentsTranslation.dzUploadMorePart1Text}{" "}
              <a
                style={{ textDecoration: "underline" }}
                onClick={uploadMoreMobile}
              >
                {documentsTranslation.dzUploadMorePart2Text}
              </a>{" "}
              {documentsTranslation.dzUploadMorePart3Text}
            </div>
          </div>
        );
        break;
      default:
        contents = (
          <div>
            <img
              style={{ width: 150, height: 150 }}
              src={`${process.env.PUBLIC_URL}/assets/svg/upload.svg`}
              alt="upload"
            ></img>
            <div>{documentsTranslation.dzPleaseUploadYour}</div>
            <div style={styles.uploadItemName}>{itemName}</div>
            <div>{renderUploaderButton(percentage)}</div>
          </div>
        );
        break;
    }
    return (
      <div style={{ textAlign: "center", color: Styles.defaultColor }}>
        {contents}
      </div>
    );
  }

  function uploadMoreMobile() {
    const { setUploadState } = props;
    setUploadState(UploadStateEnum.Opened);
  }

  function renderProgressBar(percentage: number) {
    if (percentage === 100) {
      return (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Spinner color="secondary" size="md" style={{ margin: 30 }} />
          <span style={{ color: Styles.defaultColor, margin: 30 }}>
            {documentsTranslation.dzProcessingFiles}
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ margin: "30px 30px 0px 30px" }}>
          <Progress variant="determinate" color="primary" value={percentage} />
        </div>
      );
    }
  }
}

type DropZoneState = {
  externalId: string;
  applicationId: string;
  itemName: string;
  itemStatus: FileUploadStatus;
};

type DropZoneProps = {
  externalId: string;
  applicationId: string;
  itemName: string;
  itemStatus: FileUploadStatus;
  uploadFinishedCallback: Function;
  uploadErrorCallback: Function;
  uploadMethodRef: React.MutableRefObject<(() => void) | undefined>;
  uploadState: UploadStateEnum;
  setUploadState: React.Dispatch<React.SetStateAction<UploadStateEnum>>;
};

export default UploadDropZone;
