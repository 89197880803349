import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import React from "react";
import { LocalSettings } from "../configuration/AppConfig";

export function TextMaskYears(props: any) {
  const { ...other } = props;
  return <InputMask {...other} mask="99" type="tel" maskChar=" " />;
}

export function TextMaskPostalCode(props: any) {
  const { ...other } = props;

  function beforeMaskedValueChange(
    newState: any,
    oldState: any,
    userInput: string
  ) {
    if (!userInput) return newState;

    if (userInput === " ") {
      return oldState;
    }

    let returnValue = newState.value;
    return {
      value: returnValue,
      selection: newState.selection,
    };
  }

  return (
    <InputMask
      {...other}
      mask="*** ***"
      type="text"
      beforeMaskedValueChange={beforeMaskedValueChange}
      maskChar=" "
    />
  );
}

export function TextMaskDate(props: any) {
  const { ...other } = props;

  function beforeMaskedValueChange(
    newState: any,
    oldState: any,
    userInput: string
  ) {
    if (!userInput) return newState;

    let separator = "/";
    if (userInput.includes("-")) {
      separator = "-";
    } else if (userInput.includes(".")) {
      separator = ".";
    }

    let usrSplitInput = userInput.split(separator);
    let returnValue = newState.value;
    // If the date is in YYYY/MM/DD format
    if (usrSplitInput.length === 3 && usrSplitInput[0].length === 4) {
      returnValue =
        usrSplitInput[2] + "/" + usrSplitInput[1] + "/" + usrSplitInput[0];
    }

    let numericValue = returnValue.replace(/\D/g, "");

    if (numericValue.length < 8) {
      let mask = LocalSettings.translation.applicationPage.customerDetailsStep.dateOfBirthTextmask;// "MMDDYYYY";
      returnValue = numericValue + mask.substring(numericValue.length);
    }

    return {
      value: returnValue,
      selection: newState.selection,
    };
  }

  return (
    <InputMask
      {...other}
      beforeMaskedValueChange={beforeMaskedValueChange}
      mask="**/**/****"
      type="tel"
    />
  );
}

export function TextMaskPhone(props: any) {
  const { ...other } = props;
  return <InputMask {...other} type="tel" mask="(999) 999-9999" maskChar="#" />;
}

export function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { floatValue } = values;
        return (
          floatValue === undefined || (floatValue > 0 && floatValue <= 10000000)
        );
      }}
      type="tel"
      thousandSeparator
      prefix="$"
      readOnly={props.readOnly}
    />
  );
}
