import React from "react";
import "./IFrameLoader.css";

export default function IFrameLoader(props: any) {
  return renderIframe();

  function renderIframe() {
    const { ...rest } = props;
    return (
      <div>
        <iframe className="iframe" onLoad={() => props.onLoad()} {...rest}></iframe>
      </div>
    );
  }
}
