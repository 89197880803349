import { Styles } from "../../configuration/AppConfig";
import React from "react";
import "./CheckIcon.css";

export default function CheckIcon() {
    return (
        <div className="icon-check">
            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5835 27.5C4.5835 14.85 14.8502 4.58337 27.5002 4.58337C40.1502 4.58337 50.4168 14.85 50.4168 27.5C50.4168 40.15 40.1502 50.4167 27.5002 50.4167C14.8502 50.4167 4.5835 40.15 4.5835 27.5ZM45.8335 27.5C45.8335 17.3938 37.6064 9.16671 27.5002 9.16671C17.3939 9.16671 9.16683 17.3938 9.16683 27.5C9.16683 37.6063 17.3939 45.8334 27.5002 45.8334C37.6064 45.8334 45.8335 37.6063 45.8335 27.5Z" fill={Styles.iconFill} />
                <path d="M23.4597 33.4896L17.5522 27.5821L15.5405 29.5796L23.4597 37.4987L40.4597 20.4987L38.4622 18.5012L23.4597 33.4896Z" fill={Styles.iconFill} />
            </svg>
        </div>
    )
}

export type CheckIconProps = {
    fill: string;
}