import { LocalSettings } from "./AppConfig";

export const EnglishTranslation: Translation = {
  loading: {
    mainMessage: "Loading, please wait",
    description: "We're preparing your application, it won't take long!",
  },
  initialPage: {
    description: "Get instant quote in 2 or less minutes!",
    pageName: "Welcome",
    greetingPrimary: "Let's get started!",
    greetingSecondary:
      "Fill out the form below to get funds for your business in 24 hours or less",
    greetingSecondaryEcommerce:
      "Fill out the form below to get funds for your e-commerce business in 24 hours or less",
    firstName: "First Name",
    firstNameInvalid: "Please enter your first name",
    lastName: "Last Name",
    lastNameInvalid: "Please enter your last name",
    email: "Email Address",
    emailInvalid: "Please enter your email",
    emailInvalidFormat: "Invalid email format",
    businessName: "Business name",
    businessNameInvalid: "Please enter your business name",
    mobilePhone: "Mobile Phone (optional)",
    mobilePhoneInvalid: "Mobile phone has invalid format",
    submit: "Let's go",
    items: [
      "Funds in 24 hours",
      "$5,000 to $800,000",
      "No hidden fees, no collateral",
      "Applying doesn't impact credit score",
      "Simple requirements: at least 6 months in business, $10,000 monthly revenue, and located in Canada",
    ],
    items_ecommerce: [
      "Get an instant quote in just 2 minutes",
      "Receive funds in 24 hours",
      "Grow your business",
      "No hidden fees",
      "Applying won't impact your credit score",
    ],
    bottomTextEcommerceParagraph: `Get funds for your ecommerce business in just 24 hours. From
    launching a marketing campaign, to scaling your inventory,
    grow your business with Merchant Growth. We make securing
    capital for your e-commerce business easy with a fast
    application process, and easy to understand terms with no
    hidden surprises. Apply today. Get funds for your online
    business tomorrow.`,
    bottomTextFirstParagraph:
      "Why apply for small business financing with Merchant Growth instead of a plain old business loan with another company? Not all business loans in Canada are created equally, and we're not your average lender. We help small business owners get fast & easy, no-collateral access to the cash they need so they can focus on their business. It's all we do, and we love doing it! Unlike a traditional small loan in Canada, Merchant Growth has incredible customer service, complete transparency with repayment (no hidden fees), and we use the newest and best technology (that's what makes us faster than the other guys). Getting funding for a business doesn’t have to be complicated, and it doesn’t have to take away from you actually running your business. We've helped thousands of Canadian small businesses grow!",
    bottomTextSecondParagraph:
      "Let's talk about how we can help your business. There are no restrictions on how you use your cash. You might be looking for a small business loan for renos, for staffing, for seasonal inventory, for growth... whatever it is that you need it for, our business financing has got you covered.",
    testimonialText:
      "On behalf of all of us at Urban Concierge Services I want to extend my thanks for the loans you have provided us. Having access to these funds provided us a financial platform to grow our clientele, refine our operations, and adapt to our rapidly changing industry. The funds provided also aided us in eliminating part of our higher interest debt, evening out our financial performance.",
    testimonialAuthor:
      "Christopher Gunn, President/Founder of Urban Concierge Services",
    address: "Address",
    callUs: "Call Us",
    emailUs: "Email Us",
    trustedByCanadians:
      "Trusted by thousands of Canadians...You may have seen us before...",
    protectedBy: "Protected by bank-level security",
    referenceSource: "Where did you hear about us? (optional)",
    referenceSourceDropdown: {
      google: "Google/Bing",
      direct: "Direct Mail",
      tv: "TV Commercial",
      facebook: "Facebook/Instagram Ad",
      banner: "Internet Banner Ad",
      youtube: "YouTube Ad",
      friend: "Friend/Family",
      referral: "Referral",
      yourAccountant:"Your Accountant",
      other: "Other",
    }
  },
  bankingPage: {
    pageName: "Banking",
    mainTitle:
      "We need to have a quick look at your business bank account to process your application.",
    returningCustomerNote: "(Returning customer? Please connect the same account being used for repayments)",
    secondaryTitle: "Don’t worry, this won’t affect your credit.",
    protectedBy: "Protected by bank-level security",
    proceedButton: "Connect my bank later",
    proceedTitle: ``,
    proceedText: `Applicants who connect their bank at this step get funded faster.
    Connect your bank now to avoid delays in processing your application!`,
    proceedContinue: "I’m ok with delays",
    proceedCancel: "Get funded faster",
    flinksLoadingMessage: "Loading, please wait",
    notListed: "My financial institution is not listed",
  },
  finishPage: {
    pageName: "Finish",
    mainTitle: "Your application has been submitted",
    secondaryTitle:
      "One of our funding specialists will be in contact with you within the next 24 hours",
  },
  documentsPage: {
    pageName: "Documents",
    businessInfo: "Business & Owner Information",
    connectBank: "Connect your Bank Account",
    doneButtonText: "Done",
    mainTitle: "Want to get approved faster?",
    secondaryTitle: "Complete the checklist below to finalize your application",
    startButtonText: "Start",
    completedButtonText: "Completed",
    continueButtonText: "Continue",
    termsAndConditions: "Terms & Conditions",
    uploadButtonText: "Upload",
    requestedButtonText: "Start",
    submitButtonText: "Submit your application",
    actionBusinessInformation:
      "Please provide us the general information about your business",
    actionTermsAndConditions: "Please review our terms and conditions",
    actionConnectBank:
      "Please connect your bank account to get our best offers!",
    actionContinueConnectingBank:
      "You can continue connecting your bank or upload paper statements instead.",
    actionBankConnectedText: "Your bank was connected, thank you!",
    actionPaperStatements:
      "It takes longer for us to review pdf or paper bank statements, connect your business bank account now to get approved faster!",
    actionBankNotListed:
      "Are you sure your bank isn’t listed? Check again to refresh the list of banks and credit unions you can connect!",
    tcAgree: "I Agree",
    tcCancel: "Cancel",
    dzChooseFilesText:
      "Please upload your $itemName by dragging the files into the box below or clicking on it",
    dzUploadFilesText: "Choose files",
    dzDropFilesToUploadText: "Drop files here to upload",
    dzFilesUploadedText:
      "Files were uploaded. Thank you! Please click Finish to continue.",
    dzFinishText: "Finish",
    dzFilesSelected: "File(s) were selected",
    dzTapToUploadText: "Tap here to upload",
    dzThankYou: "Thank you!",
    dzFilesWereUploadedText: "File(s) were uploaded",
    dzTapFinishToContinueText: "Please tap Finish to continue",
    dzUploadMorePart1Text: "or tap",
    dzUploadMorePart2Text: "here",
    dzUploadMorePart3Text: "to upload more",
    dzProcessingFiles: "Processing files",
    dzPleaseUploadYour: "Please upload your",
    ufUploadLaterText: "Upload later",
    ufUploadText: "Upload",
    ufUploadSelected: "Upload selected",
    ufUploadFinish: "Finish",
  },
  applicationPage: {
    pageName: "Application",
    stepper: {
      minutesFromFinish: "minute(s) from finish",
      back: "Previous",
      start: "Let's Go!",
      continue: "Next",
      submit: "Submit My Application!",
      submitMobile: "Submit",
    },
    industryStep: {
      mainTitle: "Let's Begin!",
      industry: "What is your business industry?",
      industryPlaceholder: "Please start typing, we will look it up for you!",
    },
    requestedAmountStep: {
      mainTitle: "Requested Financing Amount",
      secondaryTitle: "How much financing do you need?",
    },
    annualRevenueStep: {
      mainTitle: "Last 12 Months' Revenue",
      secondaryTitle: "What is your annual revenue range?",
      selectRange: "Please select range",
      range0_250: ">$0-$250K",
      range250_500: "$250K-$500K",
      range500_1: "$500K-$1M",
      range1_2: "$1M-$2M",
      range2_3: "$2M-$3M",
      range3: ">$3M",
    },
    annualRevenueRangeStep: {
      mainTitle: "Last 12 Months' Revenue",
      range: "What is your annual revenue range?",
      rangePerYear: " / year",
      rangeSpecific: "Can you be more specific?",
    },
    yearsInBusinessRangeStep: {
      mainTitle: "Time in business",
      secondaryTitle: "What is time in business range?",
      selectRange: "Please select range",
      range_1: "Less than 1 year",
      range_2: "1-10 Years",
      range_3: "10-20+ Years",
    },
    yearsInBusinessStep: {
      mainTitle: "Time in business",
      howManyYears: "How many years have you been in business?",
      howManyMonths: "How many months have you been in business?",
      year: "Year",
      years: "Years",
      month: "Month",
      months: "Months",
    },
    businessAddressStep: {
      mainTitle: "Where is your business located?",
      businessAddress: "Business Address",
      addressPlaceHolder: "Please start typing, we will look it up for you",
    },
    homeAddressStep: {
      mainTitle: "Where are you located?",
      homeAddress: "Home Address",
      addressPlaceHolder: "Please start typing, we will look it up for you",
      skipBusinessAddress: "Same as Business Address",
    },
    addresses: {
      manualEntry: "Let me enter the address manually!",
      addressSelected: "The address you've selected is",
      changeMyAddress: "Change my address!",
      lookupAddress: "Lookup my address!",
      selectProvince: "Please select the province",
      street: "Street",
      city: "City",
      province: "Province",
      postalCode: "Postal Code",
      streetInvalid: "Please enter street",
      cityInvalid: "Please enter city",
      provinceInvalid: "Please select province",
      postalCodeInvalid: "Please enter valid Postal Code",
      provinceNames: {
        alberta: "Alberta",
        bc: "British Columbia",
        manitoba: "Manitoba",
        nb: "New Brunswick",
        nfl: "Newfoundland and Labrador",
        nwt: "Northwest Territories",
        ns: "Nova Scotia",
        nunavut: "Nunavut",
        on: "Ontario",
        pei: "Prince Edward Island",
        qb: "Quebec",
        sc: "Saskatchewan",
        yk: "Yukon",
      },
    },
    legalBusinessNameStep: {
      mainTitle: "We'd love to learn more about your business",
      legalBusinessName: "What is the legal name of your business?",
      legalBusinessNamePlaceholder: "Legal Business Name",
      legalBusinessNameSymbols: "Should not be less than 4 symbols",
      legalBusinessNameMissing: "Please enter the legal name of your business",
    },
    termsAndConditionsStep: {
      mainTitle: "Please review our terms and conditions",
      agreeSwitch: "I agree with the Terms and Conditions",
      termsLink: "Please click here to review our terms and conditions",
      termsAndConditionsTitle: "Terms & Conditions",
      termsAgree: "I Agree",
      termsDisagree: "Cancel",
    },
    customerDetailsStep: {
      mainTitle: "We would like to know more about you",
      dateOfBirth: "Date Of Birth",
      dateOfBirthPlaceholder: "MM/DD/YYYY",
      dateOfBirthTextmask: "MMDDYYYY",
      dobFormatInvalid: "Should be in MM/DD/YYYY format. e.g. 06/08/1987",
      dobDateInvalid: "Please enter valid date",
      dobAgeInvalid: "You must be at least 18 years old",
      dobDateMissing: "Please enter your date of birth",
      mobilePhone: "Mobile Phone",
      mobilePhonePlaceholder: "The phone number to reach you at",
      mobilePhoneInvalid: "Please enter a valid phone number",
      mobilePhoneMissing: "Please enter phone number",
    },
  },
};

export const FrenchTranslation: Translation = {
  loading: {
    mainMessage: "Chargement en cours, veuillez patienter",
    description: "Nous préparons votre demande, ça ne sera pas long!",
  },
  initialPage: {
    description: "Obtenez un devis instantané en 2 minutes ou moins!",
    pageName: "Bienvenue",
    greetingPrimary: "Commençons!",
    greetingSecondary:
      "Remplissez le formulaire ci-dessous pour obtenir des fonds pour votre entreprise en 24 heures ou moins",
    greetingSecondaryEcommerce:
      "Remplissez le formulaire ci-dessous pour obtenir des fonds pour votre entreprise de commerce électronique en 24 heures ou moins",
    firstName: "Prénom",
    firstNameInvalid: "Veuillez entrer votre prénom",
    lastName: "Nom de famille",
    lastNameInvalid: "Veuillez entrer votre nom de famille",
    email: "Adresse électronique",
    emailInvalid: "Veuillez entrer votre adresse électronique",
    emailInvalidFormat: "Le format de l'e-mail n'est pas valide",
    businessName: "Nom de l'entreprise",
    businessNameInvalid: "Veuillez entrer votre nom de l'entreprise",
    mobilePhone: "Téléphone cellulaire (facultatif)",
    mobilePhoneInvalid: "Le format du téléphone portable n'est pas valide",
    submit: "Allons-y",
    items: [
      "Fonds en 24 heures",
      "De 5 000 à 800 000 dollars",
      "Pas de frais cachés, pas de garantie",
      "La demande n'a pas d'impact sur la cote de crédit",
      "Exigences simples : au moins 6 mois d'activité, 10 000 $ de revenus mensuels, et situé au Canada"
    ],
    items_ecommerce: [
      "Obtenez un devis instantané en seulement 2 minutes",
      "Recevez des fonds en 24 heures",
      "Développez votre entreprise",
      "Pas de frais cachés",
      "Faire une demande n'aura aucune incidence sur votre pointage de crédit",
    ],
    bottomTextEcommerceParagraph: `Obtenez des fonds pour votre entreprise de commerce électronique en seulement 24 heures. De
    lancer une campagne marketing, pour faire évoluer votre inventaire,
    Développez votre entreprise avec Merchant Growth. Nous faisons la sécurisation
    capital pour votre entreprise de commerce électronique facile avec un rapide
    processus de candidature et termes faciles à comprendre sans
    surprises cachées. Postuler aujourd'hui. Obtenez des fonds pour votre en ligne
    les affaires de demain.`,
    bottomTextFirstParagraph:
      "Pourquoi demander un financement pour petite entreprise à Merchant Growth plutôt qu'un simple prêt commercial auprès d'une autre société ? Tous les prêts commerciaux au Canada ne sont pas créés de la même façon, et nous ne sommes pas un prêteur ordinaire. Nous aidons les propriétaires de petites entreprises à obtenir rapidement et facilement, sans garantie, l'argent dont ils ont besoin pour se concentrer sur leur entreprise. C'est tout ce que nous faisons, et nous aimons le faire! Contrairement à un petit prêt traditionnel au Canada, Merchant Growth offre un service à la clientèle incroyable, une transparence totale en matière de remboursement (pas de frais cachés), et nous utilisons la meilleure et la plus récente technologie (c'est ce qui nous rend plus rapides que les autres). Obtenir du financement pour une entreprise ne doit pas être compliqué, et cela ne doit pas vous empêcher de gérer votre entreprise. Nous avons aidé des milliers de petites entreprises canadiennes à se développer!",
    bottomTextSecondParagraph:
      "Voyons comment nous pouvons aider votre entreprise. Il n'y a aucune restriction quant à l'utilisation de vos liquidités. Vous pouvez rechercher un prêt aux petites entreprises pour des rénovations, pour du personnel, pour des stocks saisonniers, pour la croissance... quel que soit l'usage que vous en fassiez, notre financement des entreprises vous couvre.",
    testimonialText:
      "Au nom de nous tous à Urban Concierge Services, je tiens à vous remercier pour les prêts que vous nous avez accordés. L'accès à ces fonds nous a fourni une plateforme financière pour développer notre clientèle, optimiser nos opérations et nous adapter à l’évolution rapide de notre industrie. Les fonds fournis nous ont également aidés à éliminer une partie de notre dette à taux d'intérêt plus élevé et à équilibrer notre performance financière.",
    testimonialAuthor:
      "Christopher Gunn, président/fondateur de Urban Concierge Services",
    address: "Adresse",
    callUs: "Appelez-nous",
    emailUs: "L' e-mail",
    trustedByCanadians:
      "Des milliers de Canadiens font confiance ... Vous nous avez peut-être déjà vus ...",
    protectedBy: "Données protégées par une sécurité de niveau bancaire.",
    referenceSource: "Où avez-vous entendu parler de nous? (facultatif)",
    referenceSourceDropdown: {
      google : "Google/Bing",
      direct : "Courrier direct",
      tv : "Publicité télévisée",
      facebook : "Publicité Facebook/Instagram",
      banner : "Bannière publicitaire sur Internet",
      youtube : "Publicité YouTube",
      friend : "Ami/Famille",
      referral: "Lien de référence",
      yourAccountant:"Votre comptable",
      other : "Autre",
    }
  },
  applicationPage: {
    pageName: "Application",
    stepper: {
      minutesFromFinish: "minute(s) jusqu'à la fin",
      start: "Allons-y!",
      back: "Retourner",
      continue: "Continuer!",
      submit: "Soumettre ma demande!",
      submitMobile: "Soumettre",
    },
    industryStep: {
      mainTitle: "Commençons!",
      industry: "Quel est votre secteur d'activité?",
      industryPlaceholder: "Commencez à taper, les secteurs vont s’afficher!",
    },
    requestedAmountStep: {
      mainTitle: "Montant du financement demandé",
      secondaryTitle: "De combien de financement avez-vous besoin?",
    },
    annualRevenueStep: {
      mainTitle: "Revenus des 12 derniers mois",
      secondaryTitle: "Quel est votre revenu annuel?",
      selectRange: "Veuillez sélectionner parmi ces options",
      range0_250: "0 à 250 000 $",
      range250_500: "250 000 à 500 000 $",
      range500_1: "500 000 à 1 000 000 $",
      range1_2: "1 000 000 à 2 000 000 $",
      range2_3: "2 000 000 à 3 000 000 $",
      range3: "Plus de 3 000 000 $",
    },
    annualRevenueRangeStep: {
      mainTitle: "Revenus des 12 derniers mois",
      range: "Quel est votre revenu annuel?",
      rangePerYear: " / année",
      rangeSpecific: "Pourriez-vous être plus précis?",
    },
    yearsInBusinessRangeStep: {
      mainTitle: "Années d'opération",
      secondaryTitle: "Quelle est votre plage d'années de fonctionnement?",
      selectRange: "Veuillez sélectionner parmi ces options",
      range_1: "Moins d'un an",
      range_2: "1 à 10 ans",
      range_3: "10 à 20+ ans",
    },
    yearsInBusinessStep: {
      mainTitle: "Années d'opération",
      howManyYears:
        "Depuis combien d'années votre entreprise est-elle en activité?",
      howManyMonths:
        "Depuis combien de mois votre entreprise est-elle en activité?",
      year: "année",
      years: "années",
      month: "mois",
      months: "mois",
    },
    businessAddressStep: {
      mainTitle: "Où est située votre entreprise?",
      businessAddress: "Adresse de l’entreprise",
      addressPlaceHolder: "Commencez à taper, nous la recherchons pour vous",
    },
    homeAddressStep: {
      mainTitle: "Où vous situez-vous?",
      homeAddress: "Adresse du domicile",
      addressPlaceHolder: "Commencez à taper, nous la recherchons pour vous",
      skipBusinessAddress: "Identique à l'adresse professionnelle",
    },
    addresses: {
      manualEntry: "Laissez-moi taper l'adresse manuellement!",
      addressSelected: "L'adresse que vous avez sélectionnée est:",
      changeMyAddress: "Changer mon adresse!",
      lookupAddress: "Cherchez mon adresse!",
      selectProvince: "Veuillez sélectionner la province",
      street: "Rue",
      city: "Ville",
      province: "Province",
      postalCode: "Code Postal",
      streetInvalid: "Veuillez entrer la rue",
      cityInvalid: "Veuillez entrer la ville",
      provinceInvalid: "Veuillez sélectionner la province",
      postalCodeInvalid: "Veuillez saisir un code postal valide",
      provinceNames: {
        alberta: "l’Alberta",
        bc: "la Colombie-Britannique",
        manitoba: "le Manitoba",
        nb: "le Nouveau-Brunswick",
        nfl: "la Terre-Neuve-et-Labrador",
        nwt: "les Territoires du Nord-Ouest",
        ns: "la Nouvelle-Écosse",
        nunavut: "le Nunavut",
        on: "l’Ontario",
        pei: "l’île du Prince-Édouard",
        qb: "le Québec",
        sc: "la Saskatchewan",
        yk: "le Yukon",
      },
    },
    legalBusinessNameStep: {
      mainTitle: "Nous aimerions en savoir plus sur votre entreprise",
      legalBusinessName: "Quel est le nom légal de votre entreprise?",
      legalBusinessNamePlaceholder: "Nom légal de votre entreprise",
      legalBusinessNameSymbols: "Ne devrait pas être inférieur à 4 symboles",
      legalBusinessNameMissing:
        "Veuillez saisir le nom légal de votre entreprise",
    },
    termsAndConditionsStep: {
      mainTitle: "Veuillez consulter nos termes et conditions",
      agreeSwitch: "J'accepte les termes et conditions",
      termsLink: "Veuillez cliquer ici pour consulter nos termes et conditions",
      termsAndConditionsTitle: "Termes et Conditions",
      termsAgree: "Je suis d'accord",
      termsDisagree: "Annuler",
    },
    customerDetailsStep: {
      mainTitle: "Nous aimerions en savoir plus sur vous",
      dateOfBirth: "Date de naissance",
      dateOfBirthPlaceholder: "MM/JJ/AAAA",
      dateOfBirthTextmask: "MMJJAAAA",
      dobFormatInvalid:
        "Doit être au format MM/JJ/AAAA. par exemple 06/08/1987",
      dobDateInvalid: "Veuillez saisir une date valide",
      dobAgeInvalid: "Vous devez avoir au moins 18 ans",
      dobDateMissing: "Veuillez saisir votre date de naissance",
      mobilePhone: "Numéro de téléphone cellulaire",
      mobilePhonePlaceholder: "Un autre numéro de téléphone pour vous joindre",
      mobilePhoneInvalid: "Veuillez saisir de numéro de téléphone valide",
      mobilePhoneMissing: "Veuillez saisir de numéro de téléphone",
    },
  },
  bankingPage: {
    pageName: "Bancaire",
    mainTitle:
      "Nous devons examiner rapidement le compte bancaire de votre entreprise pour traiter votre demande.",
    returningCustomerNote: "(Client existant? Veuillez connecter le même compte utilisé pour les remboursements)",
    secondaryTitle:
      "Ne vous inquiétez pas, cela n'affectera pas votre cote de crédit.",
    protectedBy: "Données protégées par une sécurité de niveau bancaire.",
    proceedButton: "Connecter ma banque plus tard",
    proceedText: `Les candidats qui connectent leur banque à cette étape sont financés plus rapidement.
    Connectez votre banque dès maintenant pour éviter les retards dans le traitement de votre demande!`,
    proceedTitle: ``,
    proceedContinue: "Je suis d'accord avec les retards",
    proceedCancel: "Obtenez un financement plus rapidement",
    flinksLoadingMessage: "Chargement, veuillez patienter",
    notListed: "Mon institution n'est pas répertoriée",
  },
  finishPage: {
    pageName: "Finir",
    mainTitle: "Votre demande a été soumise",
    secondaryTitle:
      "Un de nos spécialistes en financement sera en contact avec vous dans les 24 prochaines heures",
  },
  documentsPage: {
    pageName: "Des documents",
    businessInfo: "Informations sur l'entreprise et le propriétaire",
    connectBank: "Connectez votre compte bancaire",
    doneButtonText: "Fini",
    mainTitle: "Vous voulez être approuvé plus rapidement?",
    secondaryTitle:
      "Remplissez la liste de contrôle ci-dessous pour finaliser votre demande",
    startButtonText: "Début",
    completedButtonText: "Complété",
    termsAndConditions: "Accepter les termes et conditions",
    uploadButtonText: "Télécharger",
    requestedButtonText: "Commencer",
    submitButtonText: "Soumettre ma demande!",
    continueButtonText: "Continuez",
    actionBusinessInformation:
      "Veuillez nous fournir les informations générales sur votre entreprise",
    actionTermsAndConditions: "Veuillez consulter nos termes et conditions",
    actionConnectBank:
      "Veuillez connecter votre compte bancaire pour bénéficier de nos meilleures offres!",
    actionContinueConnectingBank:
      "Continuez à connecter votre banque ou téléchargez vos relevés bancaires récents",
    actionBankConnectedText: "Votre banque était connectée, merci!",
    actionPaperStatements:
      "Il nous faut plus de temps pour examiner les PDF ou les relevés bancaires numérisés, connectez votre compte bancaire d'entreprise maintenant pour obtenir une approbation plus rapidement!",
    actionBankNotListed:
      "Etes-vous sûr que votre banque ne figure pas dans la liste? Vérifiez à nouveau pour actualiser la liste des banques et coopératives de crédit qui peuvent être connectées!",
    tcAgree: "J'accepte",
    tcCancel: "Annuler",
    dzChooseFilesText:
      "Veuillez télécharger votre $itemName en faisant glisser les fichiers dans la case ci-dessous ou en cliquant dessus",
    dzUploadFilesText: "Choisir les fichiers",
    dzDropFilesToUploadText: "Déposer des fichiers ici pour télécharger",
    dzFilesUploadedText:
      "Les fichiers ont été téléchargés. Merci! Veuillez cliquer sur Terminer pour continuer.",
    dzFinishText: "Terminer",
    dzFilesSelected: "fichier(s) ont été sélectionnés",
    dzTapToUploadText: "Télécharger",
    dzThankYou: "Merci!",
    dzFilesWereUploadedText: "fichier(s) ont été téléchargés",
    dzTapFinishToContinueText: "Veuillez appuyer sur Terminer pour continuer",
    dzUploadMorePart1Text: "ou appuyez",
    dzUploadMorePart2Text: "ici",
    dzUploadMorePart3Text: "pour télécharger plus",
    dzProcessingFiles: "Traitement des fichiers",
    dzPleaseUploadYour: "Veuillez télécharger votre",
    ufUploadLaterText: "Télécharger plus tard",
    ufUploadText: "Télécharger",
    ufUploadSelected: "Télécharger la sélection",
    ufUploadFinish: "Terminer",
  },
};

export type Translation = {
  loading: {
    mainMessage: string;
    description: string;
  };
  initialPage: {
    description: string;
    pageName: string;
    greetingPrimary: string;
    greetingSecondary: string;
    greetingSecondaryEcommerce: string;
    firstName: string;
    firstNameInvalid: string;
    lastName: string;
    lastNameInvalid: string;
    email: string;
    emailInvalid: string;
    emailInvalidFormat: string;
    businessName: string;
    businessNameInvalid: string;
    mobilePhone: string;
    mobilePhoneInvalid: string;
    submit: string;
    items: string[];
    items_ecommerce: string[];
    testimonialText: string;
    testimonialAuthor: string;
    bottomTextEcommerceParagraph: string;
    bottomTextFirstParagraph: string;
    bottomTextSecondParagraph: string;
    address: string;
    callUs: string;
    emailUs: string;
    trustedByCanadians: string;
    protectedBy: string;
    referenceSource: string;
    referenceSourceDropdown: {
      google: string;
      direct: string;
      tv: string;
      facebook: string;
      banner: string;
      youtube: string;
      friend: string;
      referral: string;
      yourAccountant: string;
      other: string;
    }
  };
  applicationPage: {
    pageName: string;
    stepper: {
      minutesFromFinish: string;
      start: string;
      back: string;
      continue: string;
      submit: string;
      submitMobile: string;
    };
    industryStep: {
      mainTitle: string;
      industry: string;
      industryPlaceholder: string;
    };
    requestedAmountStep: {
      mainTitle: string;
      secondaryTitle: string;
    };
    annualRevenueStep: {
      mainTitle: string;
      secondaryTitle: string;
      selectRange: string;
      range0_250: string;
      range250_500: string;
      range500_1: string;
      range1_2: string;
      range2_3: string;
      range3: string;
    };
    annualRevenueRangeStep: {
      mainTitle: string;
      range: string;
      rangePerYear: string;
      rangeSpecific: string;
    };
    yearsInBusinessRangeStep: {
      mainTitle: string;
      secondaryTitle: string;
      selectRange: string;
      range_1: string;
      range_2: string;
      range_3: string;
    };
    yearsInBusinessStep: {
      mainTitle: string;
      howManyYears: string;
      howManyMonths: string;
      year: string;
      years: string;
      month: string;
      months: string;
    };
    businessAddressStep: {
      mainTitle: string;
      businessAddress: string;
      addressPlaceHolder: string;
    };
    homeAddressStep: {
      mainTitle: string;
      homeAddress: string;
      addressPlaceHolder: string;
      skipBusinessAddress: string;
    };
    addresses: {
      manualEntry: string;
      lookupAddress: string;
      addressSelected: string;
      changeMyAddress: string;
      selectProvince: string;
      street: string;
      city: string;
      province: string;
      postalCode: string;
      streetInvalid: string;
      cityInvalid: string;
      provinceInvalid: string;
      postalCodeInvalid: string;
      provinceNames: {
        alberta: string;
        bc: string;
        manitoba: string;
        nb: string;
        nfl: string;
        nwt: string;
        ns: string;
        nunavut: string;
        on: string;
        pei: string;
        qb: string;
        sc: string;
        yk: string;
      };
    };
    legalBusinessNameStep: {
      mainTitle: string;
      legalBusinessName: string;
      legalBusinessNamePlaceholder: string;
      legalBusinessNameSymbols: string;
      legalBusinessNameMissing: string;
    };
    termsAndConditionsStep: {
      mainTitle: string;
      agreeSwitch: string;
      termsLink: string;
      termsAndConditionsTitle: string;
      termsAgree: string;
      termsDisagree: string;
    };
    customerDetailsStep: {
      mainTitle: string;
      dateOfBirth: string;
      dateOfBirthPlaceholder: string;
      dateOfBirthTextmask: string;
      dobFormatInvalid: string;
      dobDateInvalid: string;
      dobDateMissing: string;
      dobAgeInvalid: string;
      mobilePhone: string;
      mobilePhonePlaceholder: string;
      mobilePhoneInvalid: string;
      mobilePhoneMissing: string;
    };
  };
  bankingPage: {
    pageName: string;
    mainTitle: string;
    returningCustomerNote: string;
    secondaryTitle: string;
    protectedBy: string;
    proceedButton: string;
    proceedText: string;
    proceedTitle: string;
    proceedContinue: string;
    proceedCancel: string;
    flinksLoadingMessage: string;
    notListed: string;
  };
  documentsPage: {
    pageName: string;
    mainTitle: string;
    secondaryTitle: string;
    businessInfo: string;
    termsAndConditions: string;
    connectBank: string;
    startButtonText: string;
    doneButtonText: string;
    uploadButtonText: string;
    completedButtonText: string;
    continueButtonText: string;
    requestedButtonText: string;
    submitButtonText: string;
    actionBusinessInformation: string;
    actionTermsAndConditions: string;
    actionConnectBank: string;
    actionContinueConnectingBank: string;
    actionBankConnectedText: string;
    actionPaperStatements: string;
    actionBankNotListed: string;
    tcAgree: string;
    tcCancel: string;
    dzChooseFilesText: string;
    dzUploadFilesText: string;
    dzDropFilesToUploadText: string;
    dzFilesUploadedText: string;
    dzFinishText: string;
    dzFilesSelected: string;
    dzTapToUploadText: string;
    dzThankYou: string;
    dzFilesWereUploadedText: string;
    dzTapFinishToContinueText: string;
    dzUploadMorePart1Text: string;
    dzUploadMorePart2Text: string;
    dzUploadMorePart3Text: string;
    dzProcessingFiles: string;
    dzPleaseUploadYour: string;
    ufUploadLaterText: string;
    ufUploadText: string;
    ufUploadSelected: string;
    ufUploadFinish: string;
  };
  finishPage: {
    pageName: string;
    mainTitle: string;
    secondaryTitle: string;
  };
};
