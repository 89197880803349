import React, { useState } from "react";
import VizSensor from "react-visibility-sensor";
import "./VisibilityAwareContent.css";

export default function VisibilityAwareContent(props: VisibilityAwareContentProps) {
  const [isVisible, setVisibility] = useState<boolean>();
  return (
    <VizSensor
      partialVisibility={props.partialVisibility ? props.partialVisibility : true}
      onChange={(isVisible: boolean) => {
        setVisibility(isVisible);
      }}
    >
      <div className={isVisible ? props.animationClassName : "invisible-content"} style={{overflow: "hidden"}}>
        {props.children}
      </div>    
    </VizSensor>
  );
}

type VisibilityAwareContentProps = {
  animationClassName: string;
  children: any;
  partialVisibility?: boolean;
};
