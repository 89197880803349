import React, { ChangeEvent } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import { isMobile } from "react-device-detect";
import { callbackify } from "util";

const stepStyles = {
  inputFeedback: {
    position: "absolute" as "absolute",
    // top: 0,
    left: isMobile ? 10 : 40,
    bottom: 0,
  },
};

export default function StepAnnualRevenueRange(
  props: StepAnnualRevenueRangeProps
) {
  const data = useStepAnnualRevenueRange();
  const dispatch = useDispatch();

  const revenueStepTranslation =
    LocalSettings.translation.applicationPage.annualRevenueStep;

  //   let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  //   let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";

  return (
    <div id="stepAmounts">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {revenueStepTranslation.mainTitle}
      </h2>
      <div className="stepper-form">
        <Row form>
          <Col md={12}>
            <FormGroup className="stepper-item">{renderRange()}</FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );

  function renderRange() {
    const selectedStyle = {
      backgroundColor: Styles.textColorSecondary,
      borderColor: Styles.textColorSecondary,
      color: Styles.defaultColor,
      width: "210px",
      boxShadow: "unset",
      marginTop: "10px",
    };

    const unSelectedStyle = {
      backgroundColor: Styles.textColorPrimary,
      borderColor: Styles.textColorPrimary,
      color: Styles.defaultColor,
      width: "210px",
      boxShadow: "unset",
      marginTop: "10px",
    };

    return (
      <Container style={{ textAlign: "center" }}>
        <Label>{revenueStepTranslation.secondaryTitle}</Label>
        <Row style={{ padding: isMobile ? "0px 0px" : "0px 32px" }}>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "1"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "1");
              }}
              size="lg"
            >
              {revenueStepTranslation.range0_250}
            </Button>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "2"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "2");
              }}
              size="lg"
            >
              {revenueStepTranslation.range250_500}
            </Button>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "3"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "3");
              }}
              size="lg"
            >
              {revenueStepTranslation.range500_1}
            </Button>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "4"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "4");
              }}
              size="lg"
            >
              {revenueStepTranslation.range1_2}
            </Button>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "5"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "5");
              }}
              size="lg"
            >
              {revenueStepTranslation.range2_3}
            </Button>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Button
              style={
                data.approximateAnnualRevenueRange === "6"
                  ? selectedStyle
                  : unSelectedStyle
              }
              onClick={() => {
                raiseCallback();
                data.handleChangeManual("approximateAnnualRevenueRange", "6");
              }}
              size="lg"
            >
              {revenueStepTranslation.range3}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  function raiseCallback() {
    if (data.approximateAnnualRevenueRange == "") {
      props.callback();
    }
  }

  function useStepAnnualRevenueRange(): StepAnnualRevenueRangeState {
    const { input } = useMappedState((state) => state);
    const handleChangeManual = useCallback(
      (propertyName: string, value: any) => {
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        });
      },
      []
    );

    return {
      approximateAnnualRevenueRange: input.approximateAnnualRevenueRange,
      handleChangeManual,
    };
  }
}

type StepAnnualRevenueRangeState = {
  approximateAnnualRevenueRange: string;
  handleChangeManual: Function;
};

type StepAnnualRevenueRangeProps = {
  callback: () => void;
};
