import React from "react";
import { LocalSettings, Styles } from "../configuration/AppConfig";
import "./LoadingScreen.css";
import { Spinner } from "reactstrap";
import { isMobile } from "react-device-detect";



export default function LoadingScreen(props: LoadingProps) {
  const loadingState = useLoadingState(props);
  const showLoadingScreen = props.showMessage === undefined ? true : props.showMessage;

  const styles = {
    labelStyle: {
      textAlign: "center" as "center",
      margin: 15,
      color: Styles.textColorPrimary,
      fontWeight: 400
    },
    labelStyleLarge: {
      textAlign: "center" as "center",
      margin: 15,
      color: Styles.textColorPrimary,
      fontSize: isMobile ? "1rem" : "1.25rem",
      fontWeight: 400
    },
    descriptionStyle: {
      textAlign: "center" as "center",
      margin: 15,
      color: Styles.textColorPrimary,
      fontSize: isMobile ? "0.9rem" : "1.0rem",
    }
  };

  return (
    <div className="loading-screen-content loading-screen-overlay">
      {getLoadingImage()}
      {showLoadingScreen && getLoadingMessage()}
      {showLoadingScreen && getLoadingAnimation()}
    </div>
  );

  function getLoadingAnimation() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Spinner type="grow" style={{color: Styles.textColorPrimary}} />
        <Spinner type="grow" style={{color: Styles.textColorPrimary}} />
        <Spinner type="grow" style={{color: Styles.textColorPrimary}} />
        <Spinner type="grow" style={{color: Styles.textColorPrimary}} />
      </div>
    )
  }

  function getLoadingMessage() {
    return (
      <div>
        <div style={styles.labelStyleLarge}>
          <div id="loadingScreen">
            <div className="loading-animation">
              {loadingState.message}
              {/* <span>.</span>
              <span>.</span>
              <span>.</span> */}
            </div>
            <div style={styles.descriptionStyle}>
              {loadingState.messageDescription}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getLoadingImage() {
    return (
      <div style={{ textAlign: "center", marginBottom: 30 }}>
        <img
          width={300}
          src={`${process.env.PUBLIC_URL}/assets/img/${loadingState.animation}`}
          alt="loading_image"
        ></img>
      </div>
    );
  }
}

function useLoadingState(props: LoadingProps): LoadingState {
  let animation = props.animation || LoadingAnimation.DEFAULT;
  let loadingMessage = props.loadingMessage || LocalSettings.translation.loading.mainMessage;
  let loadingMessageDescription =
    props.loadingMessageDescription || LocalSettings.translation.loading.description;
  return {
    animation: animation,
    message: loadingMessage,
    messageDescription: loadingMessageDescription
  };
}

export enum LoadingAnimation {
  DEFAULT = "animations_processing_small.gif",
  QUOTING = "animations_quoting_small.gif",
  SCORING = "animations_scoring_small.gif",
  PROCESSING = "animations_processing_small.gif"
}

type LoadingState = {
  animation: LoadingAnimation;
  message: string;
  messageDescription: string;
};

type LoadingProps = {
  animation?: LoadingAnimation;
  loadingMessage?: string;
  loadingMessageDescription?: string;
  showMessage?: boolean;
};
