import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import { FormGroup, Form, Label, Input, Row, Col } from "reactstrap";
import useScript from "../hooks/UseScript";
import useLink from "../hooks/UseLink";
import { HelperFunctions } from "../utils/HelperFunctions";
import "./AddressLookup.css";
import { TextMaskPostalCode } from "./InputMasks";
import { isMobile } from "react-device-detect";
import AddressLookupGooglePlaces, {
  AddressLookupResult,
} from "./AddressLookupGooglePlaces";
import { LocalSettings, Styles } from "../configuration/AppConfig";

export default function AddressLookup(props: AddressParameters) {
  let address = {
    searchText: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
  };

  let formattedValue: string | null = null;
  if (!HelperFunctions.isEmpty(props.value)) {
    let splitValue = props.value.split(";");
    address.street = splitValue[0];
    address.city = splitValue[1];
    address.province = splitValue[2];
    address.postalCode = splitValue[3];
    formattedValue = formatAddress(
      address.street,
      address.city,
      address.province,
      address.postalCode
    );
  }

  const styles = {
    inputFeedback: {
      position: "absolute" as "absolute",
      // top: 0,
      // left: isMobile ? 10 : 40,
      bottom: 0,
    },
  };

  const [searchText, setSearchText] = useState(formattedValue);
  const [street, setStreet] = useState(address.street);
  const [city, setCity] = useState(address.city);
  const [province, setProvince] = useState(address.province);
  const [postalCode, setPostalCode] = useState(address.postalCode);
  const [addressIsValid, setAddressIsValid] = useState(false);
  const [addressType, setAddressType] = useState(AddressType.AUTO);

  const [streetIsValid, setStreetIsValid] = useState(true);
  const [cityIsValid, setCityIsValid] = useState(true);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(true);

  const addressTranslation =
    LocalSettings.translation.applicationPage.addresses;

  function formatAddress(
    street: string,
    city: string,
    province: string,
    postalCode: string
  ) {
    return `${street},${city},${province},${postalCode}`;
  }

  function validateStreet(streetStr: string) {
    setStreetIsValid(streetStr.length > 0);
  }

  function validateCity(cityStr: string) {
    setCityIsValid(cityStr.length > 0);
  }

  function validatePostalCode(postalCodeStr: string) {
    setPostalCodeIsValid(HelperFunctions.postalCodeIsValid(postalCodeStr));
  }

  let [typingTimeoutStreet, setTypingTimeoutStreet] = useState(0);
  let [typingTimeoutCity, setTypingTimeoutCity] = useState(0);
  let [typingTimeoutPostalCode, setTypingTimeoutPostalCode] = useState(0);

  let streetRef = useRef<HTMLInputElement | null>(null);
  let cityRef = useRef<HTMLInputElement | null>(null);
  let provinceRef = useRef<HTMLInputElement | null>(null);
  let postalCodeRef = useRef<HTMLInputElement | null>(null);
  let formattedAddressRef = useRef<HTMLInputElement | null>(null);
  let searchRef = useRef<HTMLInputElement | null>(null);

  // useScript(
  //   // `${process.env.PUBLIC_URL}/assets/js/addresscomplete.js`
  //   "https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=pb53-bz12-bk79-xu66&culture=en-CA"
  // );

  // useLink(
  //   `${process.env.PUBLIC_URL}/assets/css/addresscomplete.css`,
  //   // "http://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=pb53-bz12-bk79-xu66&culture=en-CA",
  //   "text/css",
  //   "stylesheet"
  // );

  useEffect(() => {
    // overrideAutoFillChange(searchRef.current);

    overrideValueChangeForUnmanagedInput(streetRef.current, (value: string) => {
      setStreet(value);
    });
    overrideValueChangeForUnmanagedInput(cityRef.current, (value: string) => {
      setCity(value);
    });
    overrideValueChangeForUnmanagedInput(
      provinceRef.current,
      (value: string) => {
        setProvince(value);
      }
    );
    overrideValueChangeForUnmanagedInput(
      postalCodeRef.current,
      (value: string) => {
        setPostalCode(value);
      }
    );
  }, []);

  // Do not run address validation on initial render
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    validateAndSetAddress();
  }, [street, city, province, postalCode]);

  return (
    <div>
      <div
        style={{
          display:
            addressIsValid && addressType === AddressType.AUTO
              ? "inherit"
              : "none",
        }}
        className={
          addressIsValid ? "address-animated address-fadeInBottom" : ""
        }
      >
        {renderValidAddressBlock()}
      </div>
      <div
        style={{
          display:
            !addressIsValid || addressType === AddressType.MANUAL
              ? "inherit"
              : "none",
        }}
      >
        <div>{renderSearch()}</div>
        <div>{renderAddressSwitchLink()}</div>
      </div>
    </div>
  );

  function validateAndSetAddress() {
    let formattedAddress, splittedAddress;
    if (
      street &&
      street.length > 0 &&
      city &&
      city.length > 0 &&
      HelperFunctions.postalCodeIsValid(postalCode) &&
      province &&
      province.length > 0
    ) {
      formattedAddress = formatAddress(street, city, province, postalCode);
      splittedAddress = `${street};${city};${province};${postalCode}`;
      setAddressIsValid(true);
    } else {
      formattedAddress = "";
      splittedAddress = "";
    }
    props.onAddressChange(splittedAddress, props.name);
    setSearchText(formattedAddress);
  }

  function overrideValueChangeForUnmanagedInput(
    input: HTMLInputElement | null,
    callback: Function
  ) {
    if (input == null) throw new Error("Input is null");

    Object.defineProperty(input, "value", {
      get: function () {
        return this._value;
      },
      set: function (v) {
        this._value = v;
        callback(v);
      },
    });
  }

  function overrideAutoFillChange(input: HTMLInputElement | null) {
    if (input == null) throw new Error("Input is null");
    Object.defineProperty(input, "autocomplete", {
      get: function () {
        return this._value;
      },
      set: function (v) {
        if (v !== "off") this._value = v;
      },
    });
  }

  function renderValidAddressBlock() {
    return (
      <div style={{ textAlign: "center", padding: 15 }}>
        <div className="arrow_box">
          <h5>{addressTranslation.addressSelected}</h5>
          <div>
            {searchText}
            <span className="check"></span>
          </div>
        </div>
        <div
          className="stepper-item"
          style={{ textAlign: "center", cursor: "pointer", margin: 20 }}
        >
          <a
            href=""
            style={{ color: Styles.textColorPrimary }}
            onClick={(e: any) => {
              e.preventDefault();
              setAddressType(AddressType.MANUAL);
              setAddressIsValid(false);
            }}
          >
            {addressTranslation.changeMyAddress}
          </a>
        </div>
      </div>
    );
  }

  function renderAddressSwitchLink() {
    let newAddressType: AddressType;
    let addressSwitchCaption: string;

    switch (addressType) {
      case AddressType.AUTO:
        newAddressType = AddressType.MANUAL;
        addressSwitchCaption = addressTranslation.manualEntry;
        break;
      case AddressType.MANUAL:
        newAddressType = AddressType.AUTO;
        addressSwitchCaption = addressTranslation.lookupAddress;
        break;
    }

    return (
      <div
        className="stepper-item"
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        <a
          style={{
            color: Styles.textColorPrimary,
          }}
          href=""
          onClick={(e: any) => {
            e.preventDefault();
            setAddressType(newAddressType);
          }}
        >
          {addressSwitchCaption}
        </a>
      </div>
    );
  }
  function renderSearch() {
    // style={{display: toggleSearch? "inherit" : "none"}}
    // style={{display: toggleSearch? "none" : "inherit"}}
    return (
      <Form>
        {/* <Row
          style={{
            display: addressType === AddressType.MANUAL ? "none" : "inherit",
          }}
        >
          <Col md={12}>
            <div style={{ textAlign: "center" }}>
              <img
                width={300}
                src={`${process.env.PUBLIC_URL}/assets/img/animations_loading_small.gif`}
              ></img>
            </div>
          </Col>
        </Row> */}
        <Row
          form
          style={{
            display: addressType === AddressType.MANUAL ? "none" : "inherit",
          }}
        >
          <Col md={12}>
            <FormGroup className={props.className}>
              <Label style={props.labelStyle}>{props.label}</Label>
              {/* <Input
                value={searchText}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchText(e.target.value);
                }}
                onInput={() => {
                  setCity("");
                  setPostalCode("");
                  setProvince("");
                  setStreet("");
                }}
                innerRef={searchRef}
                name="search_input"
                placeholder={props.placeholder}
                autoComplete="new-password"
                id="search_input"
                type="text"
                bsSize={props.bsSize}
                //   invalid={true}
              /> */}
              {/* <AddressLookupGooglePlaces
                id={props.name + "_auto_complete"}
                onChanged={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchText(e.target.value);
                }}
                onInput={() => {
                  setCity("");
                  setPostalCode("");
                  setProvince("");
                  setStreet("");
                }}
              /> */}

              <AddressLookupGooglePlaces
                placeholder={props.placeholder}
                onSelected={(result: AddressLookupResult) => {
                  setCity(result.city);
                  setStreet(result.street);
                  setProvince(result.province);
                  setPostalCode(result.postalCode);
                }}
                onInput={() => {
                  setCity("");
                  setPostalCode("");
                  setProvince("");
                  setStreet("");
                }}
                formattedValue={formattedValue}
              />

              <div className="invalid-feedback text-focus-in">
                Please type in your address or make a selection!
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row
          form
          style={{
            display: addressType === AddressType.MANUAL ? "flex" : "none",
          }}
        >
          <Col md={12}>
            <FormGroup>
              <Label style={props.labelStyle}>{addressTranslation.street}</Label>
              <Input
                style={{ display: "none" }}
                name="street"
                id="street"
                type="text"
                innerRef={streetRef}
                bsSize={props.bsSize}
              />
              <Input
                name="street_managed"
                id="street_managed"
                autoComplete="street-address"
                value={street}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.value;
                  if (typingTimeoutStreet > 0) {
                    clearTimeout(typingTimeoutStreet);
                  }
                  setStreet(event.target.value);

                  let timeout = !streetIsValid ? 0 : 800;
                  setTypingTimeoutStreet(
                    setTimeout(() => {
                      validateStreet(value);
                    }, timeout)
                  );
                }}
                invalid={!streetIsValid}
                type="text"
                bsSize={props.bsSize}
              />
              <div
                className="invalid-feedback text-focus-in"
                style={styles.inputFeedback}
              >
                {addressTranslation.streetInvalid}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row
          form
          style={{
            display: addressType === AddressType.MANUAL ? "flex" : "none",
          }}
        >
          <Col md={4}>
            <FormGroup>
              <Label style={props.labelStyle}>{addressTranslation.city}</Label>
              <Input
                name="city"
                style={{ display: "none" }}
                id="city"
                type="text"
                innerRef={cityRef}
                bsSize={props.bsSize}
              />
              <Input
                name="city_managed"
                autoComplete="address-level2"
                id="city_managed"
                value={city}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.value;
                  if (typingTimeoutCity > 0) {
                    clearTimeout(typingTimeoutCity);
                  }
                  setCity(event.target.value);

                  let timeout = !cityIsValid ? 0 : 800;
                  setTypingTimeoutCity(
                    setTimeout(() => {
                      validateCity(value);
                    }, timeout)
                  );
                }}
                invalid={!cityIsValid}
                type="text"
                bsSize={props.bsSize}
              />
              <div
                className="invalid-feedback text-focus-in"
                style={styles.inputFeedback}
              >
                {addressTranslation.cityInvalid}
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label style={props.labelStyle}>{addressTranslation.province}</Label>
              <Input
                name="province"
                style={{ display: "none" }}
                id="province"
                type="text"
                innerRef={provinceRef}
                bsSize={props.bsSize}
              />
              <Input
                name="province_managed"
                autoComplete="address-level1"
                id="province_managed"
                value={province}
                type="select"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setProvince(e.target.value);
                }}
                bsSize={props.bsSize}
              >
                <option value="" disabled>
                  {addressTranslation.selectProvince}
                </option>
                <option value="NL">{addressTranslation.provinceNames.nfl}</option>
                <option value="PE">{addressTranslation.provinceNames.pei}</option>
                <option value="NS">{addressTranslation.provinceNames.ns}</option>
                <option value="NB">{addressTranslation.provinceNames.nb}</option>
                <option value="QC">{addressTranslation.provinceNames.qb}</option>
                <option value="ON">{addressTranslation.provinceNames.on}</option>
                <option value="MB">{addressTranslation.provinceNames.manitoba}</option>
                <option value="SK">{addressTranslation.provinceNames.sc}</option>
                <option value="AB">{addressTranslation.provinceNames.alberta}</option>
                <option value="YT">{addressTranslation.provinceNames.yk}</option>
                <option value="BC">{addressTranslation.provinceNames.bc}</option>
                <option value="NT">{addressTranslation.provinceNames.nwt}</option>
                <option value="NU">{addressTranslation.provinceNames.nunavut}</option>
              </Input>
              <div className="invalid-feedback text-focus-in">
                {addressTranslation.selectProvince}
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label style={props.labelStyle}>{addressTranslation.postalCode}</Label>
              <Input
                name="postal_code"
                style={{ display: "none" }}
                id="postal_code"
                innerRef={postalCodeRef}
                type="text"
                bsSize={props.bsSize}
              />
              <Input
                name="postal_code_managed"
                autoComplete="postal-code"
                id="postal_code_managed"
                value={postalCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.value;
                  if (typingTimeoutPostalCode > 0) {
                    clearTimeout(typingTimeoutPostalCode);
                  }
                  setPostalCode(event.target.value);

                  let timeout = !postalCodeIsValid ? 0 : 800;
                  setTypingTimeoutPostalCode(
                    setTimeout(() => {
                      validatePostalCode(value);
                    }, timeout)
                  );
                }}
                invalid={!postalCodeIsValid}
                type="text"
                bsSize={props.bsSize}
                tag={TextMaskPostalCode}
              />
              <div
                className="invalid-feedback text-focus-in"
                style={styles.inputFeedback}
              >
                {addressTranslation.postalCodeInvalid}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row form style={{ display: "none" }}>
          <Col md={12}>
            <FormGroup className={props.className}>
              {/* <Label>{props.label}</Label> */}
              <Input
                name="formatted_address"
                id="formatted_address"
                //   invalid={true}
                bsSize={props.bsSize}
                innerRef={formattedAddressRef}
                placeholder={props.placeholder}
              />
              <div className="invalid-feedback text-focus-in">
                Please type in your address or make a selection!
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

type AddressParameters = {
  name: string;
  className: string;
  placeholder: string;
  label: string;
  labelStyle: any;
  value: string;
  bsSize: "sm" | "lg" | undefined;
  onAddressChange: (splittedAddress: string, propName: string) => void;
};

export enum AddressType {
  MANUAL = "manual",
  AUTO = "search",
}
