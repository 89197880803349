import React from "react";
import { Styles } from "../configuration/AppConfig";

export function StepperProgress(props: StepperProgressProps) {
    return (
        <div>
            <div className="progress" style={{ height: 8 }}>
                <div className="progress-bar" role="progressbar" style={{ width: `${props.value}%`, backgroundColor: Styles.textColorPrimary }} ></div>
            </div>
        </div>
    )
}

export type StepperProgressProps = {
    value: number;
}