import React, { useState } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Form, Label, Input, Col, Row } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import { isMobile } from "react-device-detect";

const stepStyles = {
  inputFeedback: {
    position: "absolute" as "absolute",
    // top: 0,
    // left: isMobile ? 10 : 40,
    bottom: 0,
  },
};

export default function StepLegalBusinessName() {
  const dispatch = useDispatch();
  const data = useStepLegalBusinessName();

  const legalStepTranslation = LocalSettings.translation.applicationPage.legalBusinessNameStep;

  // let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  // let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";
  let labelStyle = { fontSize: "1rem" };
  let [
    typingTimeoutLegalBusinessName,
    setTypingTimeoutLegalBusinessName,
  ] = useState(0);

  return (
    <div id="stepLegalBusinessName">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {legalStepTranslation.mainTitle}
      </h2>
      {/* <h4
        style={{
          color: Styles.textColorTitleSecondary,
          textAlign: "center" as "center",
        }}
      >
        How should we call your business?
      </h4>  */}
      <div className="stepper-form">
        <Form>
          {/* <Row>
            <Col md={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  width={300}
                  src={`${process.env.PUBLIC_URL}/assets/img/animations_scoring_small.gif`}
                ></img>
              </div>
            </Col>
          </Row> */}
          <Row form>
            <Col md={12}>
              <FormGroup className="stepper-item">
                <Label style={labelStyle}>{legalStepTranslation.legalBusinessName}</Label>
                <Input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    if (typingTimeoutLegalBusinessName > 0) {
                      clearTimeout(typingTimeoutLegalBusinessName);
                    }
                    data.handleChangeManual(
                      "legalBusinessName",
                      value
                    );
                    let timeout = data.legalBusinessNameValid ? 0 : 800;
                    setTypingTimeoutLegalBusinessName(
                      setTimeout(() => {
                        data.validateLegalBusinessName(value);
                      }, timeout)
                    );
                  }}
                  value={data.legalBusinessName}
                  id="legalBusinessName"
                  placeholder={legalStepTranslation.legalBusinessNamePlaceholder}
                  name="legalBusinessName"
                  type="text"
                  invalid={data.legalBusinessNameValid}
                  bsSize={size}
                />
                <div
                  className="invalid-feedback text-focus-in"
                  style={stepStyles.inputFeedback}
                >
                  {(() => {
                    if (
                      data.legalBusinessName &&
                      data.legalBusinessName.length <= 3
                    )
                      return legalStepTranslation.legalBusinessNameSymbols;
                    else if (data.legalBusinessName === "")
                      return legalStepTranslation.legalBusinessNameMissing;
                  })()}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  function useStepLegalBusinessName(): StepLegalBusinessNameState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );

    const handleChangeManual = useCallback(
      (propertyName: string, value: any) => {
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        });
      },
      []
    );

    const [legalBusinessNameValid, setLegalBusinessNameValid] = useState(false);
    const [legalBusinessNameBlurred, setLegalBusinessNameBlurred] = useState(false);

    function validateLegalBusinessName(legalBusinessName?: string) {
      let name = legalBusinessName || input.legalBusinessName;
      setLegalBusinessNameValid(name.length <= 3);
    }
    return {
      legalBusinessName: input.legalBusinessName,
      legalBusinessNameValid: legalBusinessNameValid,
      legalBusinessNameBlurred: legalBusinessNameBlurred,
      setLegalBusinessNameBlurred: setLegalBusinessNameBlurred,
      validateLegalBusinessName: validateLegalBusinessName,
      handleChange,
      handleChangeManual,
    };
  }
}

type StepLegalBusinessNameState = {
  legalBusinessName: string;
  legalBusinessNameValid: boolean;
  legalBusinessNameBlurred: boolean;
  setLegalBusinessNameBlurred: Function;
  validateLegalBusinessName: Function;
  handleChange: Function;
  handleChangeManual: Function;
};
