import React from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Row, Col, Label } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import AutoSuggestLookup from "../AutoSuggestLookup";
import { HelperFunctions } from "../../utils/HelperFunctions";

export default function StepIndustry() {
  const data = useStepIndustry();
  const dispatch = useDispatch();

  const industryStepTranslation =
    LocalSettings.translation.applicationPage.industryStep;

  let industries: Array<Industry>;
  let selectedSubIndustryValue: string = "";
  switch (LocalSettings.language) {
    case "en":
      industries = getIndustriesList();
      break;
    case "fr":
      industries = getFrenchIndustriesList();
      break;
    default:
      throw new Error("Unknown language!");
  }

  if (LocalSettings.defaultIndustry !== null) {
    industries = industries.filter(
      (i) => i.industry === LocalSettings.defaultIndustry
    );
  }

  if (data.subIndustry !== "") {
    const selectedIndustry = getConcreteIndustryFromSuggestion(
      industries,
      undefined,
      data.subIndustry
    );
    selectedSubIndustryValue =
      selectedIndustry.subIndustry.label ?? selectedIndustry.subIndustry.name;
  } else {
    selectedSubIndustryValue = "";
  }

  return (
    <div id="stepIndustry">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {industryStepTranslation.mainTitle}
      </h2>
      <div className="stepper-form">
        <Row>
          <Col md={12}>
            <FormGroup className="stepper-item">
              <Label>{industryStepTranslation.industry}</Label>
              <AutoSuggestLookup
                getSuggestions={getIndustrySuggestions}
                placeholder={industryStepTranslation.industryPlaceholder}
                value={selectedSubIndustryValue}
                selectedCallback={(value: string, suggestion: any) => {
                  const ret = getConcreteIndustryFromSuggestion(
                    industries,
                    suggestion.code
                  );
                  data.handleChangeManual(
                    "industry",
                    ret.industryCategory?.industry
                  );
                  data.handleChangeManual("subIndustry", ret.subIndustry?.name);
                }}
                changeCallback={() => {
                  data.handleChangeManual("industry", "");
                  data.handleChangeManual("subIndustry", "");
                }}
              ></AutoSuggestLookup>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );

  function getConcreteIndustryFromSuggestion(
    industries: Array<Industry>,
    code?: number,
    name?: string
  ): {
    industryCategory: Industry;
    subIndustry: SubIndustry;
  } {
    const industryCategory = industries.find(
      (i: Industry) =>
        i.subIndustries.find(
          (si: SubIndustry) => si.code === code || si.name === name
        ) !== undefined
    );

    if (!industryCategory) {
      throw new Error("Can't find industry!");
    }

    const subIndustry = industryCategory?.subIndustries.find(
      (si: SubIndustry) => si.code === code || si.name === name
    );

    if (!subIndustry) {
      throw new Error("Can't find subindustry!");
    }

    return {
      industryCategory,
      subIndustry,
    };
  }

  function escapeRegexCharacters(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function getIndustrySuggestions(value: string) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return industries;
    }

    const valueForRegex = HelperFunctions.removeDiacritics(escapedValue);
    const regex = new RegExp(valueForRegex, "i");

    let filteredIndustries = industries
      .map((section) => {
        return {
          industry: section.label ?? section.industry,
          subIndustries: section.subIndustries.filter(
            (subIndustry: SubIndustry) => {
              for (let i = 0; i < subIndustry.keywords.length; i++) {
                const keyword = subIndustry.keywords[i];
                if (regex.test(keyword)) return true;
              }
              const subIndustryNameWithoutDiacritics = HelperFunctions.removeDiacritics(
                subIndustry.name
              );
              let result: boolean = regex.test(
                subIndustryNameWithoutDiacritics
              );
              if (subIndustry.label) {
                const subIndustrylabelWithoutDiacritics = HelperFunctions.removeDiacritics(
                  subIndustry.label
                );
                result =
                  result || regex.test(subIndustrylabelWithoutDiacritics);
              }
              return result;
            }
          ),
        };
      })
      .filter((section) => section.subIndustries.length > 0);
    if (filteredIndustries === undefined || filteredIndustries.length === 0) {
      return industries.map((section) => {
        return {
          industry: section.label ?? section.industry,
          subIndustries: section.subIndustries.filter(
            (subIndustry: SubIndustry) => {
              if (section.industry === "Other") {
                return true;
              } else {
                return subIndustry.name === "Other";
              }
            }
          ),
        };
      });
    } else {
      return filteredIndustries;
    }
  }

  function useStepIndustry(): StepIndustryState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );

    const handleChangeManual = useCallback(
      (propertyName: string, value: any) => {
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        });
      },
      []
    );

    return {
      industry: input.industry,
      subIndustry: input.subIndustry,
      handleChange,
      handleChangeManual,
    };
  }

  function getIndustriesList(): Array<Industry> {
    return [
      {
        industry: "Auto-Related",
        subIndustries: [
          {
            name: "Automotive Sales",
            code: 1,
            keywords: ["Car Sales, Car Dealership, Truck, Auto-Related"],
          },
          {
            name: "Motorsports Sales",
            code: 2,
            keywords: [
              "ATV, Snowmobiles, Recreational Vehicles, Boats, Auto-Related",
            ],
          },
          {
            code: 3,
            name: "Automotive Parts & Services",
            keywords: ["Car Repair, Car Parts, Auto-Related"],
          },
          {
            code: 4,
            name: "Car Washes & Detailing",
            keywords: ["Automotive, Painting, Auto-Related"],
          },
          {
            code: 5,
            name: "Other",
            keywords: ["Auto-Related"],
          },
        ],
      },
      {
        industry: "Food - Beverage",
        subIndustries: [
          {
            code: 6,
            name: "Bars & Lounges",
            keywords: ["Tavern", "Club", "Food", "Food - Beverage"],
          },
          {
            code: 7,
            name: "Casual Dining",
            keywords: [
              "Restaurant",
              "Fast Food",
              "Chain",
              "Drive Through",
              "Franchise",
              "Food - Beverage",
              "Food",
            ],
          },
          {
            code: 8,
            name: "Ice Cream & Summer Restaurants",
            keywords: [
              "Gelato, Frozen Yogurt, Froyo, Sorbet, Ice Milk, Bubble Tea, Food - Beverage, Food",
            ],
          },
          {
            code: 9,
            name: "Deli's",
            keywords: [
              "Delicatessen",
              "Meat Market",
              "Meats",
              "Butcher",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 10,
            name: "Food Manufacturing & Logistics",
            keywords: [
              "Food Processing",
              "Delivery",
              "Import",
              "Export",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 11,
            name: "Grocery",
            keywords: [
              "supermarket, market, greengrocer, food wholesale, Food - Beverage, Food",
            ],
          },
          {
            code: 12,
            name: "Liquor Stores",
            keywords: ["Beer, Wine, Spirits, Alcohol, Food - Beverage, Food"],
          },
          {
            code: 13,
            name: "Cafés",
            keywords: ["Coffee", "Tea", "Coffee Shop", "Food - Beverage, Food"],
          },
          {
            code: 14,
            name: "Caterers",
            keywords: ["Catering", "Event Food", "Food - Beverage, Food"],
          },
          {
            code: 15,
            name: "Bakeries & Confectionery",
            keywords: [
              "Cupcakes",
              "Cake",
              "Boulangerie",
              "Dessert",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 16,
            name: "Breweries, Distilleries & Wineries",
            keywords: ["Beer", "Wine", "Vineyard", "Food - Beverage, Food"],
          },
          {
            code: 17,
            name: "Food Trucks",
            keywords: [
              "Hotdog Stand",
              "Hamburger Stand",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 18,
            name: "Full-Service Restaurants",
            keywords: [
              "Diner",
              "Terrace",
              "Served Food",
              "Brasserie",
              "Cafeteria",
              "Canteen",
              "Grill",
              "Joint",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 19,
            name: "High-End Restaurants",
            keywords: ["Fine dining", "Food - Beverage, Food"],
          },
          {
            code: 20,
            name: "Convenience Stores",
            keywords: [
              "Corner Store",
              "24 Hour Convenience Shop",
              "Food - Beverage, Food",
            ],
          },
          {
            code: 21,
            name: "Other",
            keywords: ["Food - Beverage"],
          },
        ],
      },
      {
        industry: "Health",
        subIndustries: [
          {
            code: 22,
            name: "Dental",
            keywords: ["Dentist, Orthodontist,Health"],
          },
          {
            code: 23,
            name: "Doctors",
            keywords: ["GP, General Practitioner,Health"],
          },
          {
            code: 24,
            name: "Paramedical",
            keywords: [
              "Chriopractor, Home Health Care, Mental Health Practitioner, Therapist, Psychologist, Counselor, Optometrist, Eye Doctor, Massage Therapists, RMT, Physiotherapists, Osteopath, Dietitian, Accupuncture, Naturopathist, Health",
            ],
          },
          {
            code: 25,
            name: "Seniors Homes",
            keywords: [
              "Assisted Living, Long-term Care Facility, Retirement Home, Health",
            ],
          },
          {
            code: 26,
            name: "Other",
            keywords: ["Health"],
          },
        ],
      },
      {
        industry: "Hair & Beauty",
        subIndustries: [
          {
            code: 27,
            name: "Hair / Beauty Salons and Barbers",
            keywords: [
              "Nail Salon, Hairdresser, Beauty Parlour, Dyeing, Manicure, Pedicure, Hair & Beauty",
            ],
          },
          {
            code: 28,
            name: "Beauty Supplies",
            keywords: ["Cosmetics, Perfume, Health, Hair & Beauty"],
          },
          {
            code: 29,
            name: "Spas",
            keywords: ["Massage, Beauty, Wellness, Hair & Beauty"],
          },
          {
            code: 30,
            name: "Tattoo Parlours",
            keywords: ["Piercing, body art, Hair & Beauty"],
          },
          {
            code: 31,
            name: "Other",
            keywords: ["Hair & Beauty"],
          },
        ],
      },
      {
        industry: "Recreation",
        subIndustries: [
          {
            code: 32,
            name: "Fitness Centres & Gyms",
            keywords: [
              "Yoga, Pilates, Swimming Pool, Indoor Cycling, Weight Training, Cardio Facilities, Recreation",
            ],
          },
          {
            code: 33,
            name: "Dance & Martial Arts Studios",
            keywords: ["MMA, Zumba, Karate, Kickboxing, Recreation"],
          },
          {
            code: 34,
            name: "Indoor Recreation Sites",
            keywords: ["Arcade, Museum, Bowling Alley, Leisure, Recreation"],
          },
          {
            code: 35,
            name: "Hotels & Lodging",
            keywords: ["Cabin Rental, Bed & Breakfast, B&B, Recreation"],
          },
          {
            code: 36,
            name: "Recreation Services",
            keywords: [
              "Tour Operator, Sightseeing Transportation, Travel Agencies, Recreation",
            ],
          },
          {
            code: 37,
            name: "Amusement Parks & Outdoor Event Venues",
            keywords: ["Theme Parks, Mini-Golf, Marinas, Recreation"],
          },
          {
            code: 38,
            name: "Camping & Campsites",
            keywords: ["Recreation", "Camping, Campsites"],
          },
          {
            code: 39,
            name: "Golf Courses and Country Clubs",
            keywords: ["Recreation"],
          },
          {
            code: 40,
            name: "Skiing Facilities",
            keywords: ["Ski Hill, Snowboard, Recreation"],
          },
          {
            code: 41,
            name: "Other",
            keywords: ["Recreation"],
          },
        ],
      },
      {
        industry: "Professional Services",
        subIndustries: [
          {
            code: 42,
            name: "Childcare Services & Daycares",
            keywords: ["Professional Services"],
          },
          {
            code: 43,
            name: "Laundry, Drycleaning & Linens",
            keywords: ["Laundering, Dry Cleaners", "Professional Services"],
          },
          {
            code: 44,
            name: "Marketing Services",
            keywords: [
              "Advertising, Online, Web Design, Digital, Graphic Design",
              "Professional Services",
            ],
          },
          {
            code: 45,
            name: "Security Guards",
            keywords: [
              "Security Services, Private Security",
              "Professional Services",
            ],
          },
          {
            code: 46,
            name: "Pet Services & Veterinarians",
            keywords: [
              "Vet, Animal Hospital, Pet Grooming, Professional Services",
            ],
          },
          {
            code: 47,
            name: "Accounting Services",
            keywords: [
              "Bookeeping, Payroll, Financial Planner, Professional Services",
            ],
          },
          {
            code: 48,
            name: "Legal Services",
            keywords: [
              "Lawyer, Law Office, Immigration Services, Notaries, Professional Services",
            ],
          },
          {
            code: 49,
            name: "Brokers, Brokerages and Agents",
            keywords: [
              "Mortgage, Insurance, Leasing, Real Estate, Professional Services",
            ],
          },
          {
            code: 50,
            name: "Educational Services & Schools",
            keywords: [
              "Instructional, Teacher, Training Programs, Professional Services",
            ],
          },
          {
            code: 51,
            name: "Janitorial & Cleaning Services",
            keywords: ["Pest Control, Maid, Professional Services"],
          },
          {
            code: 52,
            name: "Industrial Maintenance & Services",
            keywords: [
              "Aerospace, Marine, Rail, Telecommunications, Utility, Professional Services",
            ],
          },
          {
            code: 53,
            name: "Security Systems & Devices",
            keywords: [
              "Monitoring Services, Alarm Systems, Cameras, Professional Services",
            ],
          },
          {
            code: 54,
            name: "Professional Consulting",
            keywords: [
              "Management, IT, Engineering, Environmental, Interior Design, Professional Services",
            ],
          },
          {
            code: 55,
            name: "Staffing Services",
            keywords: [
              "Labour Organizations, Personnel, Recruiter, Recruitment, Professional Services",
            ],
          },
          {
            code: 56,
            name: "Waste Management",
            keywords: [
              "Garbage Collection, Recycling Services, Professional Services",
            ],
          },
          {
            code: 57,
            name: "Other",
            keywords: ["Professional Services"],
          },
        ],
      },
      {
        industry: "Transportation",
        subIndustries: [
          {
            code: 58,
            name: "Movers",
            keywords: ["Packers, Transportation"],
          },
          {
            code: 59,
            name: "Air, Rail and Water Transportation",
            keywords: ["Utilities Transportation, Cargo, Transportation"],
          },
          {
            code: 60,
            name: "Towing Services & Tow Trucks",
            keywords: ["Recovery, Repo, Transportation"],
          },
          {
            code: 61,
            name: "Couriers",
            keywords: ["Delivery, Transportation"],
          },
          {
            code: 62,
            name: "Trucking / Hauling / Freight / Logistics",
            keywords: ["Moving Van, Moving Truck, Transportation"],
          },
          {
            code: 63,
            name: "Taxis, Cabs & Limousines",
            keywords: ["Private vans, chauffeur, Transportation"],
          },
          {
            code: 64,
            name: "Other",
            keywords: ["Transportation"],
          },
        ],
      },
      {
        industry: "Retail",
        subIndustries: [
          {
            code: 65,
            name: "General & Basic Retail",
            keywords: [
              "Department Store, Merchandise, Household Goods, Wholesale, Retail",
            ],
          },
          {
            code: 66,
            name: "Office Supplies / Stationery",
            keywords: [
              "Notebooks, Pens, Paper, Clipboards, Folders, Elastic bands, Staplers, Clips, Stickies, Highlighters, Penciles, Sharpeners, Erasers, Business Essentials, Printers, Ink, Computers, Office Furniture, Retail",
            ],
          },
          {
            code: 67,
            name: "Clothing & Accessories",
            keywords: [
              "Jewelry, Luggage, Eyewear, Sunglasses, Shoes, Sportswear, Clothes, Retail",
            ],
          },
          {
            code: 68,
            name: "Electronics Store",
            keywords: ["Phones, Laptops, Computers, Retail"],
          },
          {
            code: 69,
            name: "Gas Stations",
            keywords: ["Petrol station, Pump, Retail"],
          },
          {
            code: 70,
            name: "Furniture & Home Furnishings",
            keywords: [
              "Couches, Chairs, Dressers, Beds, Lamps, Curtains, Tables, Carpets, Mirrors, TV Stands, Retail",
            ],
          },
          {
            code: 71,
            name: "Pet & Pet Supply Stores",
            keywords: [
              "Cat Food, Dog Food, Fish Food, Reptile Food, Bird Food, Fish Bowl, Pet Toys, Leashes, Pet Products, Pet Medicine, Retail",
            ],
          },
          {
            code: 72,
            name: "Sporting Goods",
            keywords: [
              "Retail, Bike, Ski, Snowboard, Running, Football, Soccer, Basball, Swimming, Hiking, Jogging, Athletics, Hockey, Basketball, Tennis, Volleyball, Badminton",
            ],
          },
          {
            code: 73,
            name: "Party Rentals and Supplies",
            keywords: ["Retail, Ballons, Theme, Gifts, Costumes"],
          },
          {
            code: 74,
            name: "Photographers and Photography Studios",
            keywords: ["Retail, Cameraman, Picture Studio"],
          },
          {
            code: 75,
            name: "Pawnshops",
            keywords: [
              "Retail, Pawnbroker, Antiques buyer, Artifact buyer, Valuable buyer, Trade & Loan",
            ],
          },
          {
            code: 76,
            name: "Screenprinting and Silkscreening",
            keywords: ["Retail, Ad Printing"],
          },
          {
            code: 77,
            name:
              "Toys, Comics, Hobbies, Gifts, Art Supplies, Books and Collectibles",
            keywords: [
              "Retail, Boardgames, Card games, Painting Supplies, Literature",
            ],
          },
          {
            code: 78,
            name: "Vapes, E-cigarettes, Cigars, Smoke Supplies",
            keywords: [
              "Retail, Vape, E-Juice, Tobacco, Pipe Tobacco, Nicotine, Smoking Accessories",
            ],
          },
          {
            code: 79,
            name: "Vitamins, Supplements and Health Supplies",
            keywords: ["Retail, Protien, Minerals, Wellness, Herbal, Natural"],
          },
          {
            code: 80,
            name: "Garden Stores / Nurseries",
            keywords: [
              "Retail, Plants, Garden Center, Garden Centre, Trees, Shrubs, Plants, Seeds, Bulbs and Sod",
            ],
          },
          {
            code: 81,
            name: "E-commerce Retail",
            keywords: [
              "Retail, Online Sales, Ticketing Services, Online Shopping",
            ],
          },
          {
            code: 82,
            name: "Computer Repair",
            keywords: [
              "Retail, Electronics Repair, Macs, PCs, PC, Laptops, Desktops, LCD Monitor",
            ],
          },
          {
            code: 83,
            name: "Hardware & Building Materials Retail",
            keywords: [
              "Retail, Building Materials, Paints, Doors, Windows, Tools,",
            ],
          },
          {
            code: 84,
            name: "Machinery & Equipment Sales",
            keywords: ["Retail, Outdoor Power Equipment"],
          },
          {
            code: 85,
            name: "Art Galleries",
            keywords: ["Retail, Paintings, Artwork"],
          },
          {
            code: 86,
            name: "Cannabis Retail & Dispensaries",
            keywords: [
              "Retail, Cannabis Cafe, Marijuana Shops, Weed Shops, Bongs",
            ],
          },
          {
            code: 87,
            name: "Florists",
            keywords: [
              "Retail, Flower Shop, Flowers, Floral, Bouquets, Floral Arrangements",
            ],
          },
          {
            code: 88,
            name: "Music & Musical Instrument Stores",
            keywords: [
              "Retail, Guitar, Piano, Violin, Keyboard, Bass, Drums, Cello, Recorder, Trumpet, Harp, Clarinet, Flute",
            ],
          },
          {
            code: 89,
            name: "Vending Machines",
            keywords: ["Retail, Soda, Snacks, Chips, Drinks"],
          },
          {
            code: 90,
            name: "Bridal Shops",
            keywords: ["Retail, Wedding Gown, Wedding Dresses"],
          },
          {
            code: 91,
            name: "Home Appliance Sales & Repair Services",
            keywords: [
              "Retail, Washing Machine, Dryer, Fridge, Refrigerator, Microwave, Dishwasher, Extractor, Stove, Coocktops, Range Hoods",
            ],
          },
          {
            code: 92,
            name: "Hunting and Fishing Supplies",
            keywords: [
              "Retail, Firearms, Ammunition, Handgun, Pistol, Rigle, Shotgun, Weapon, Revolver ",
            ],
          },
          {
            code: 93,
            name: "Other retail to consumers",
            keywords: ["Retail"],
          },
          {
            code: 94,
            name: "Other retail to industrial and commercial customers",
            keywords: ["Retail"],
          },
          {
            code: 95,
            name: "Video / Video Game Sales and Rentals",
            keywords: ["Console, Gamings, VR, PC, Computer Games, Retail"],
          },
          {
            code: 96,
            name: "Winemaking & Brewing Supplies",
            keywords: [
              "Wine, Beer, Homebrew, Fermenting, Filtration, Racking, Retail",
            ],
          },
          {
            code: 124,
            name: "Pharmacies",
            keywords: ["Retail", "Pharmacies"],
          },
          {
            code: 97,
            name: "Other",
            keywords: ["Retail"],
          },
        ],
      },
      {
        industry: "Construction",
        subIndustries: [
          {
            code: 98,
            name: "Construction Trades",
            keywords: [
              "Drywall, Insulation, Finishing, Framing, Glazing, Masonry, Roofing, Siding, Construction",
            ],
          },
          {
            code: 99,
            name: "Flooring Sales & Maintenance",
            keywords: ["Floor, Installation, Construction"],
          },
          {
            code: 100,
            name: "General Construction",
            keywords: [
              "General Contractors, Commercial, Residential, Construction",
            ],
          },
          {
            code: 101,
            name: "Painting",
            keywords: ["Painter, Commercial, Construction"],
          },
          {
            code: 102,
            name: "Landscaping & Snow Removal",
            keywords: ["Gardening, Gardener, Construction"],
          },
          {
            code: 103,
            name: "HVAC Sales & Maintenance",
            keywords: [
              "Installation, Air Conditioning, Heating, Ventilation,Construction",
            ],
          },
          {
            code: 104,
            name: "Maintenance Trades",
            keywords: [
              "Electrician, Locksmith, Plumber, Building Inspector, Elevator Maintenance",
            ],
          },
          {
            code: 105,
            name: "Other",
            keywords: ["Construction"],
          },
        ],
      },
      {
        industry: "Other",
        subIndustries: [
          {
            code: 106,
            name: "Agriculture & Farming",
            keywords: ["Berry Farms, Orchards, Other"],
          },
          {
            code: 107,
            name: "Farm Products Retail",
            keywords: ["Animal Feed, Other"],
          },
          {
            code: 108,
            name: "Manufacturing",
            keywords: [
              "Machining, Texties, Processing, Assembly Lines, Prefabrication, Hardware, Other",
            ],
          },
          {
            code: 109,
            name: "Storage Facilities",
            keywords: ["Storage Units, Self Storage Units, Other"],
          },
          {
            code: 110,
            name: "Forestry",
            keywords: ["Millwork, Sawmills, Wood Preservation, Other"],
          },
          {
            code: 111,
            name: "Energy / Oil & Gas / Mining / Minerals Extraction",
            keywords: ["Resource Extraction, Other"],
          },
          {
            code: 112,
            name: "Oilfield Services",
            keywords: [
              "Maintenance, Processing, Storage, Gasfield, Rig, Other",
            ],
          },
          {
            code: 113,
            name: "Biotechnology and Pharmaceuticals",
            keywords: ["Medicine, medical research, Other"],
          },
          {
            code: 114,
            name: "Utilities & Telecommunications",
            keywords: ["Phone, Electricity, Heat, Other"],
          },
          {
            code: 115,
            name: "Funeral Homes / Crematoriums",
            keywords: ["Memorial Service, Other"],
          },
          {
            code: 116,
            name:
              "Commercial printing, publishing and other marketing supplies",
            keywords: [
              "Packaging, Labelling, Signage, Marketing Supplies, Other",
            ],
          },
          {
            code: 117,
            name: "Parking Facilities",
            keywords: ["Parking Lot, Garages, Other"],
          },
          {
            code: 118,
            name: "Software",
            keywords: [
              "Application, Video Games, Design, Development, Sales, Computer Program, Other",
            ],
          },
          {
            code: 119,
            name: "Entertainment Production",
            keywords: [
              "Music, Film, Theatre, Broadcasting, Events & Conference Coordinator, Other",
            ],
          },
          {
            code: 120,
            name: "Financial Services",
            keywords: ["Lending, Insurance Companies, Banks, Other"],
          },
          {
            code: 121,
            name: "Property Management",
            keywords: ["Rental, Commercial, Other"],
          },
          {
            code: 122,
            name: "Real Estate Rentals",
            keywords: ["Mortgage dealer, Other"],
          },
          {
            code: 123,
            name: "Property Development",
            keywords: [
              "Residential property developer, Housing developer, Other",
            ],
          },
        ],
      },
    ];
  }

  function getFrenchIndustriesList(): Array<Industry> {
    return [
      {
        label: "Automobile",
        industry: "Auto-Related",
        subIndustries: [
          {
            code: 1,
            name: "Automotive Sales",
            label: "Ventes de véhicules automobiles",
            keywords: [
              "Automobile, Vente de voitures, concessionnaires de voitures, camions, activites liees a l'automobile",
            ],
          },
          {
            code: 2,
            name: "Motorsports Sales",
            label: "Ventes de sports motorisés",
            keywords: [
              "Automobile, VTT, motoneiges, vehicules recreatifs, bateaux, produits lies a l'automobile",
            ],
          },
          {
            code: 3,
            name: "Automotive Parts & Services",
            label: "Pièces et services automobiles",
            keywords: [
              "Automobile, Reparation de voitures, pieces detachees pour voitures, liees a l'automobile",
            ],
          },
          {
            code: 4,
            name: "Car Washes & Detailing",
            label: "Lavage et esthétique automobiles",
            keywords: ["Automobile, Automobile, Peinture, Lie a l'automobile"],
          },
          {
            code: 5,
            name: "Other",
            label: "Autres",
            keywords: ["Automobile, Activites liees a l'automobile"],
          },
        ],
      },
      {
        label: "Alimentation et boissons",
        industry: "Food - Beverage",
        subIndustries: [
          {
            code: 6,
            name: "Bars & Lounges",
            label: "Bars et lounges",
            keywords: [
              "Alimentation et boissons",
              "Taverne",
              "Club",
              "Alimentation",
              "Nourriture - Boissons",
            ],
          },
          {
            code: 7,
            name: "Casual Dining",
            label: "Restauration rapide",
            keywords: [
              "Alimentation et boissons",
              "Restaurant",
              "Restauration rapide",
              "Chaine",
              "Service au volant",
              "Franchise",
              "Alimentation - Boissons",
              "Alimentation",
            ],
          },
          {
            code: 8,
            name: "Ice Cream & Summer Restaurants",
            label: "Glaciers",
            keywords: [
              "Alimentation et boissons",
              "Gelato, Yaourt glace, Froyo, Sorbet, Lait glace, Bubble Tea, Alimentation - Boissons, Alimentation",
            ],
          },
          {
            code: 9,
            name: "Deli's",
            label: "Épicerie fine",
            keywords: [
              "Alimentation et boissons",
              "Delicatessen",
              "Marche de la viande",
              "Viandes",
              "Boucherie",
              "Alimentation et boissons, Alimentation",
            ],
          },
          {
            code: 10,
            name: "Food Manufacturing & Logistics",
            label: "Logistique de la fabrication alimentaire",
            keywords: [
              "Alimentation et boissons",
              "Traitement des aliments",
              "Livraison",
              "Importation",
              "Exportation",
              "Alimentation et boissons, Produits alimentaires",
            ],
          },
          {
            code: 11,
            name: "Grocery",
            label: "Épicerie",
            keywords: [
              "Alimentation et boissons",
              "supermarche, marche, primeur, commerce de gros de produits alimentaires, Produits alimentaires - Boissons, Produits alimentaires",
            ],
          },
          {
            code: 12,
            name: "Liquor Stores",
            label: "Magasins d'alcool",
            keywords: [
              "Alimentation et boissons",
              "Biere, Vin, Spiritueux, Alcool, Alimentation - Boissons, Alimentation",
            ],
          },
          {
            code: 13,
            name: "Cafés",
            label: "Cafés",
            keywords: [
              "Alimentation et boissons",
              "Cafe",
              "The",
              "Magasin de cafe",
              "Alimentation et boissons, Alimentation",
            ],
          },
          {
            code: 14,
            name: "Caterers",
            label: "Traiteurs",
            keywords: [
              "Alimentation et boissons",
              "Traiteur",
              "Alimentation evenementielle",
              "Alimentation et boissons, Nourriture",
            ],
          },
          {
            code: 15,
            name: "Bakeries & Confectionery",
            label: "Boulangeries et confiseries",
            keywords: [
              "Alimentation et boissons",
              "Cupcakes",
              "Gateau",
              "Boulangerie",
              "Dessert",
              "Alimentation et boissons, Alimentation",
            ],
          },
          {
            code: 16,
            name: "Breweries, Distilleries & Wineries",
            label: "Brasseries, distilleries et établissements vinicoles",
            keywords: [
              "Alimentation et boissons",
              "Biere",
              "Vin",
              "Vignoble",
              "Nourriture - Boissons, Nourriture",
            ],
          },
          {
            code: 17,
            name: "Food Trucks",
            label: "Camions de restauration",
            keywords: [
              "Alimentation et boissons",
              "Stand de hot-dogs",
              "Stand de hamburgers",
              "Nourriture - Boisson, Nourriture",
            ],
          },
          {
            code: 18,
            name: "Full-Service Restaurants",
            label: "Restaurants à service complet",
            keywords: [
              "Alimentation et boissons",
              "Diner",
              "Terrasse",
              "Nourriture servie",
              "Brasserie",
              "Cafeteria",
              "Cantine",
              "Grill",
              "Joint",
              "Alimentation - Boissons, Alimentation",
            ],
          },
          {
            code: 19,
            name: "High-End Restaurants",
            label: "Restaurants haut de gamme",
            keywords: [
              "Alimentation et boissons",
              "Restaurants haut de gamme",
              "Aliments - Boissons, Alimentation",
            ],
          },
          {
            code: 20,
            name: "Convenience Stores",
            label: "Dépanneurs",
            keywords: [
              "Alimentation et boissons",
              "Magasin du coin",
              "Depanneur 24 heures sur 24",
              "Alimentation - Boisson, Nourriture",
            ],
          },
          {
            code: 21,
            name: "Other",
            label: "Autres",
            keywords: ["Alimentation et boissons"],
          },
        ],
      },
      {
        label: "Santé",
        industry: "Health",
        subIndustries: [
          {
            code: 22,
            name: "Dental",
            label: "Dentaire",
            keywords: ["Sante", "Dentiste, Orthodontiste, Sante"],
          },
          {
            code: 23,
            name: "Doctors",
            label: "Médecins",
            keywords: ["Sante", "Medecin generaliste, Generaliste,Sante"],
          },
          {
            code: 24,
            name: "Paramedical",
            label: "Paramédical",
            keywords: [
              "Sante",
              "Chriopracteur, Soins de sante a domicile, Praticien de la sante mentale, Therapeute, Psychologue, Conseiller, Optometriste, Ophtalmologue, Massotherapeutes, RMT, Physiotherapeutes, Osteopathe, Dieteticien, Accupuncture, Naturopathe, Sante",
            ],
          },
          {
            code: 25,
            name: "Seniors Homes",
            label: "Résidences pour personnes âgées",
            keywords: [
              "Sante",
              "Vie assistee, Etablissement de soins de longue duree, Maison de retraite, Sante",
            ],
          },
          { code: 26, name: "Other", label: "Autres", keywords: ["Sante"] },
        ],
      },
      {
        industry: "Hair & Beauty",
        label: "Coiffure et beauté",
        subIndustries: [
          {
            code: 27,
            name: "Hair / Beauty Salons and Barbers",
            label: "Salons de coiffure / de beauté et barbiers",
            keywords: [
              "Coiffure et beaute",
              "Salon de manucure, coiffeur, salon de beaute, teinture, manucure, pedicure, cheveux et beaute",
            ],
          },
          {
            code: 28,
            name: "Beauty Supplies",
            label: "Fournitures de produits de beauté",
            keywords: [
              "Coiffure et beaute",
              "Cosmetiques, Parfums, Sante, Cheveux et Beaute",
            ],
          },
          {
            code: 29,
            name: "Spas",
            label: "Spas",
            keywords: [
              "Coiffure et beaute",
              "Massage, Beaute, Bien-etre, Cheveux et Beaute",
            ],
          },
          {
            code: 30,
            name: "Tattoo Parlours",
            label: "Salons de tatouage",
            keywords: [
              "Coiffure et beaute",
              "Piercing, art corporel, Cheveux et Beaute",
            ],
          },
          {
            code: 31,
            name: "Other",
            label: "Autres",
            keywords: ["Coiffure et beaute"],
          },
        ],
      },
      {
        industry: "Recreation",
        label: "Loisirs",
        subIndustries: [
          {
            code: 32,
            name: "Fitness Centres & Gyms",
            label: "Centres de conditionnement physique et gyms",
            keywords: [
              "Loisirs",
              "Yoga, Pilates, Piscine, Cyclisme en salle, Musculation, Installations cardio, Loisirs",
            ],
          },
          {
            code: 33,
            name: "Dance & Martial Arts Studios",
            label: "Studios de danse et d'arts martiaux",
            keywords: ["Loisirs", "MMA, Zumba, Karate, Kickboxing, Loisirs"],
          },
          {
            code: 34,
            name: "Indoor Recreation Sites",
            label: "Loisirs d’intérieurs",
            keywords: [
              "Loisirs",
              "Arcade, Musee, Bowling, Loisirs, Recreation",
            ],
          },
          {
            code: 35,
            name: "Hotels & Lodging",
            label: "Hôtels et hébergements",
            keywords: [
              "Loisirs",
              "Location de cabanes, Chambres d'hotes, B&B, Loisirs",
            ],
          },
          {
            code: 36,
            name: "Recreation Services",
            label: "Services de loisirs",
            keywords: [
              "Loisirs",
              "Tour-operateur, Transport touristique, Agences de voyage, Recreation",
            ],
          },
          {
            code: 37,
            name: "Amusement Parks & Outdoor Event Venues",
            label: "Parcs d'attractions et sites d'événements extérieurs",
            keywords: [
              "Loisirs",
              "Parcs a theme, Mini-Golf, Marinas, Recreation",
            ],
          },
          {
            code: 38,
            name: "Camping & Campsites",
            label: "Campings",
            keywords: ["Loisirs", "Camping, campings"],
          },
          {
            code: 39,
            name: "Golf Courses and Country Clubs",
            label: "Terrains de golf et country clubs",
            keywords: ["Loisirs"],
          },
          {
            code: 40,
            name: "Skiing Facilities",
            label: "Installations de ski",
            keywords: ["Centres de ski, Snowboard, Loisirs"],
          },
          { code: 41, name: "Other", label: "Autres", keywords: ["Loisirs"] },
        ],
      },
      {
        industry: "Professional Services",
        label: "Services professionnels",
        subIndustries: [
          {
            code: 42,
            name: "Childcare Services & Daycares",
            label: "Services de garde d’enfants / garderies",
            keywords: ["Services professionnels"],
          },
          {
            code: 43,
            name: "Laundry, Drycleaning & Linens",
            label: "Blanchisserie, nettoyage à sec et linge de maison",
            keywords: [
              "Blanchissage, nettoyage a sec",
              "Services professionnels",
            ],
          },
          {
            code: 44,
            name: "Marketing Services",
            label: "Services de marketing",
            keywords: [
              "Publicite, en ligne, conception de sites Web, numerique, conception graphique",
              "Services professionnels",
            ],
          },
          {
            code: 45,
            name: "Security Guards",
            label: "Agents de sécurité",
            keywords: [
              "Services de securite, Securite privee",
              "Services professionnels",
            ],
          },
          {
            code: 46,
            name: "Pet Services & Veterinarians",
            label: "Services pour animaux et vétérinaires",
            keywords: [
              "Veterinaire, hopital pour animaux, toilettage d'animaux, services professionnels",
            ],
          },
          {
            code: 47,
            name: "Accounting Services",
            label: "Services de comptabilité",
            keywords: [
              "Tenue de livres, Paie, Planificateur financier, Services professionnels",
            ],
          },
          {
            code: 48,
            name: "Legal Services",
            label: "Services juridiques",
            keywords: [
              "Avocat, cabinet juridique, services d'immigration, notaires, services professionnels",
            ],
          },
          {
            code: 49,
            name: "Brokers, Brokerages and Agents",
            label: "Courtiers, maisons de courtage et agents",
            keywords: [
              "Hypotheque, assurance, location, immobilier, services professionnels",
            ],
          },
          {
            code: 50,
            name: "Educational Services & Schools",
            label: "Services éducatifs et écoles",
            keywords: [
              "Programmes d'instruction, d'enseignement et de formation, services professionnels",
            ],
          },
          {
            code: 51,
            name: "Janitorial & Cleaning Services",
            label: "Services de conciergerie et de nettoyage",
            keywords: [
              "Lutte contre les parasites, nettoyage, services professionnels",
            ],
          },
          {
            code: 52,
            name: "Industrial Maintenance & Services",
            label: "Maintenance et services industriels",
            keywords: [
              "Aerospatiale, marine, ferroviaire, telecommunications, services publics, services professionnels",
            ],
          },
          {
            code: 53,
            name: "Security Systems & Devices",
            label: "Systèmes de sécurité",
            keywords: [
              "Services de surveillance, systemes d'alarme, cameras, services professionnels",
            ],
          },
          {
            code: 54,
            name: "Professional Consulting",
            label: "Conseillers professionnels",
            keywords: [
              "Gestion, informatique, ingenierie, environnement, decoration interieure, services professionnels",
            ],
          },
          {
            code: 55,
            name: "Staffing Services",
            label: "Services de recrutement",
            keywords: [
              "Organisations syndicales, personnel, recruteur, recrutement, services professionnels",
            ],
          },
          {
            code: 56,
            name: "Waste Management",
            label: "Gestion des déchets",
            keywords: [
              "Collecte des ordures, services de recyclage, services professionnels",
            ],
          },
          {
            code: 57,
            name: "Other",
            label: "Autres",
            keywords: ["Services professionnels"],
          },
        ],
      },
      {
        industry: "Transportation",
        label: "Transports",
        subIndustries: [
          {
            code: 58,
            name: "Movers",
            label: "Déménageurs",
            keywords: ["Emballeurs, Transports"],
          },
          {
            code: 59,
            name: "Air, Rail and Water Transportation",
            label: "Transport aérien, ferroviaire et maritime",
            keywords: ["Services publics Transport, Cargo, Transports"],
          },
          {
            code: 60,
            name: "Towing Services & Tow Trucks",
            label: "Services de remorquage",
            keywords: ["Recuperation, Repo, Transports"],
          },
          {
            code: 61,
            name: "Couriers",
            label: "Messageries",
            keywords: ["Livraison, Transports"],
          },
          {
            code: 62,
            name: "Trucking / Hauling / Freight / Logistics",
            label: "Camionnage / Fret / Logistique",
            keywords: [
              "Camionnette de demenagement, Camion de demenagement, Transports",
            ],
          },
          {
            code: 63,
            name: "Taxis, Cabs & Limousines",
            label: "Taxis, Cabines et Limousines",
            keywords: ["Fourgonnettes privees, chauffeur, Transports"],
          },
          {
            code: 64,
            name: "Other",
            label: "Autres",
            keywords: ["Transports"],
          },
        ],
      },
      {
        industry: "Retail",
        label: "Vente au détail",
        subIndustries: [
          {
            code: 65,
            name: "General & Basic Retail",
            label: "Commerce de détail général et de base",
            keywords: [
              "Vente au detail",
              "Grand magasin, marchandise, articles menagers, gros, detail",
            ],
          },
          {
            code: 66,
            name: "Office Supplies / Stationery",
            label: "Fournitures de bureau / papeterie",
            keywords: [
              "Vente au detail",
              "Cahiers, stylos, papier, porte-blocs, chemises, elastiques, agrafeuses, clips, autocollants, surligneurs, crayons, taille-crayons, gommes, fournitures de bureau, imprimantes, encre, ordinateurs, mobilier de bureau, vente au detail.",
            ],
          },
          {
            code: 67,
            name: "Clothing & Accessories",
            label: "Vêtements et accessoires",
            keywords: [
              "Vente au detail",
              "Bijoux, Bagages, Lunettes, Lunettes de soleil, Chaussures, Vetements de sport, Vetements, Vente au detail",
            ],
          },
          {
            code: 68,
            name: "Electronics Store",
            label: "Magasin d'électronique",
            keywords: [
              "Vente au detail",
              "Telephones, Ordinateurs portables, Ordinateurs, Vente au detail",
            ],
          },
          {
            code: 69,
            name: "Gas Stations",
            label: "Stations-service",
            keywords: [
              "Vente au detail",
              "Station-service, Pompe, Vente au detail",
            ],
          },
          {
            code: 70,
            name: "Furniture & Home Furnishings",
            label: "Magasin de meubles",
            keywords: [
              "Vente au detail",
              "Canapes, Chaises, Commodes, Lits, Lampes, Rideaux, Tables, Tapis, Miroirs, Meubles TV, Detail",
            ],
          },
          {
            code: 71,
            name: "Pet & Pet Supply Stores",
            label: "Animaleries",
            keywords: [
              "Vente au detail",
              "Nourriture pour chats, Nourriture pour chiens, Nourriture pour poissons, Nourriture pour reptiles, Nourriture pour oiseaux, Bols a poissons, Jouets pour animaux, Laisses, Produits pour animaux, Medicaments pour animaux, Detail",
            ],
          },
          {
            code: 72,
            name: "Sporting Goods",
            label: "Articles de sport",
            keywords: [
              "Vente au detail",
              "Vente au detail, Velo, Ski, Snowboard, Course, Football, Soccer, Basball, Natation, Randonnee, Jogging, Athletisme, Hockey, Basketball, Tennis, Volleyball, Badminton",
            ],
          },
          {
            code: 73,
            name: "Party Rentals and Supplies",
            label: "Location d’équipement et fournitures de fêtes",
            keywords: ["Vente au detail, Ballons, Theme, Cadeaux, Costumes"],
          },
          {
            code: 74,
            name: "Photographers and Photography Studios",
            label: "Photographes et studios de photographie",
            keywords: [
              "Vente au detail",
              "Commerce de detail, Cameraman, Studio photo",
            ],
          },
          {
            code: 75,
            name: "Pawnshops",
            label: "Prêteurs sur gage",
            keywords: [
              "Vente au detail",
              "Detail, Preteur sur gages, Acheteur d'antiquites, Acheteur d'artefacts, Acheteur de valeurs, Commerce et pret",
            ],
          },
          {
            code: 76,
            name: "Screenprinting and Silkscreening",
            label: "Sérigraphie",
            keywords: [
              "Vente au detail",
              "Commerce de detail, Impression publicitaire",
            ],
          },
          {
            code: 77,
            name:
              "Toys, Comics, Hobbies, Gifts, Art Supplies, Books and Collectibles",
            label:
              "Jouets, Bandes dessinées, Passe-temps, Cadeaux, Fournitures d'art, Livres et objets de collection",
            keywords: [
              "Vente au detail",
              "Vente au detail, Jeux de societe, Jeux de cartes, Fournitures de peinture, Litterature",
            ],
          },
          {
            code: 78,
            name: "Vapes, E-cigarettes, Cigars, Smoke Supplies",
            label:
              "Vapoteuses, cigarettes électroniques, cigares et articles pour fumeurs",
            keywords: [
              "Vente au detail, Vape, E-Juice, Tabac, Tabac a pipe, Nicotine, Accessoires pour fumeurs",
            ],
          },
          {
            code: 79,
            name: "Vitamins, Supplements and Health Supplies",
            label: "Vitamines, articles et suppléments pour la santé",
            keywords: [
              "Vente au detail, Protein, Mineraux, Bien-etre, Herbes, Naturel",
            ],
          },
          {
            code: 80,
            name: "Garden Stores / Nurseries",
            label: "Jardineries, matériel de jardin / pépinières",
            keywords: [
              "Vente au detail, Plantes, Jardinerie, Centre de jardinage, Arbres, Arbustes, Plantes, Semences, Bulbes et gazon.",
            ],
          },
          {
            code: 81,
            name: "E-commerce Retail",
            label: "Commerce électronique au détail",
            keywords: [
              "Vente au detail, Vente en ligne, Services de billetterie, Achats en ligne",
            ],
          },
          {
            code: 82,
            name: "Computer Repair",
            label: "Réparation d'ordinateurs",
            keywords: [
              "Vente au detail, Reparation d'appareils electroniques, Macs, PCs, PC, Ordinateurs portables, Ordinateurs de bureau, Moniteur LCD",
            ],
          },
          {
            code: 83,
            name: "Hardware & Building Materials Retail",
            label: "Quincailleries, bricolage et matériaux de construction",
            keywords: [
              "Vente au detail, Materiaux de construction, Peintures, Portes, Fenetres, Outils,",
            ],
          },
          {
            code: 84,
            name: "Machinery & Equipment Sales",
            label: "Ventes de machines et d'équipements",
            keywords: ["Vente au detail, Equipement d'exterieur motorise"],
          },
          {
            code: 85,
            name: "Art Galleries",
            label: "Galeries d'art",
            keywords: ["Vente au detail, Peintures, Oeuvres d'art"],
          },
          {
            code: 86,
            name: "Cannabis Retail & Dispensaries",
            label: "Dispensaires de vente au détail de cannabis",
            keywords: [
              "Vente au detail, Cafe de cannabis, Magasins de marijuana, Magasins d'herbe, Bongs",
            ],
          },
          {
            code: 87,
            name: "Florists",
            label: "Fleuristes",
            keywords: [
              "Vente au detail, Magasin de fleurs, Fleurs, Bouquets, Arrangements floraux",
            ],
          },
          {
            code: 88,
            name: "Music & Musical Instrument Stores",
            label: "Magasins de musique et d'instruments de musique",
            keywords: [
              "Vente au detail, Guitare, Piano, Violon, Clavier, Basse, Batterie, Violoncelle, Flute a bec, Trompette, Harpe, Clarinette, Flute traversiere",
            ],
          },

          {
            code: 89,
            name: "Vending Machines",
            label: "Distributeurs automatiques",
            keywords: ["Vente au detail, Soda, Snacks, Chips, Boissons"],
          },
          {
            code: 90,
            name: "Bridal Shops",
            label: "Boutiques de mariage",
            keywords: ["Vente au detail, Robe de mariage, Robes de mariage"],
          },
          {
            code: 91,
            name: "Home Appliance Sales & Repair Services",
            label: "Ventes et réparation d'électroménager",
            keywords: [
              "Vente au detail, Lave-linge, Seche-linge, Refrigerateur, Refrigerateur, Micro-ondes, Lave-vaisselle, Extracteur, Cuisiniere, Plaques de cuisson, Hottes de cuisine",
            ],
          },
          {
            code: 92,
            name: "Hunting and Fishing Supplies",
            label: "Fournitures pour la chasse et la pêche",
            keywords: [
              "Vente au detail d'armes a feu, de munitions, d'armes de poing, de pistolets, de fusils, de fusils de chasse, d'armes a feu et de revolvers. ",
            ],
          },
          {
            code: 93,
            name: "Other retail to consumers",
            label: "Autres ventes au détail aux consommateurs",
            keywords: ["Vente au detail"],
          },
          {
            code: 94,
            name: "Other retail to industrial and commercial customers",
            label:
              "Autres ventes au détail aux clients industriels et commerciaux",
            keywords: ["Vente au detail"],
          },
          {
            code: 95,
            name: "Video / Video Game Sales and Rentals",
            label: "Vente et location de vidéos / jeux vidéo",
            keywords: [
              "Vente au detail",
              "Console, jeux video, VR, PC, jeux d'ordinateur, vente au detail",
            ],
          },
          {
            code: 96,
            name: "Winemaking & Brewing Supplies",
            label: "Fournitures de vinification et de brassage",
            keywords: [
              "Vente au detail",
              "Vin, biere, brassage a domicile, fermentation, filtration, soutirage, vente au detail",
            ],
          },
          {
            code: 124,
            name: "Pharmacies",
            keywords: ["Vente au detail", "Pharmacies"],
          },
          {
            code: 97,
            name: "Other",
            label: "Autres",
            keywords: ["Vente au detail"],
          },
        ],
      },
      {
        industry: "Construction",
        label: "Construction",
        subIndustries: [
          {
            code: 98,
            name: "Construction Trades",
            label: "Métiers de la construction",
            keywords: [
              "Cloison seche, isolation, finition, charpente, vitrage, maconnerie, toiture, revetement, construction",
            ],
          },
          {
            code: 99,
            name: "Flooring Sales & Maintenance",
            label: "Vente et entretien de revêtements de sol",
            keywords: ["Plancher, Installation, Construction"],
          },
          {
            code: 100,
            name: "General Construction",
            label: "Construction générale",
            keywords: [
              "Entrepreneurs generaux, commerciaux, residentiels, construction",
            ],
          },
          {
            code: 101,
            name: "Painting",
            label: "Peinture",
            keywords: ["Peintre, Commercial, Construction"],
          },
          {
            code: 102,
            name: "Landscaping & Snow Removal",
            label: "Aménagement paysager et déneigement",
            keywords: ["Jardinage, Jardinier, Construction"],
          },
          {
            code: 103,
            name: "HVAC Sales & Maintenance",
            label: "Vente et entretien de systèmes CVC",
            keywords: [
              "Installation, Climatisation, Chauffage, Ventilation, Construction",
            ],
          },
          {
            code: 104,
            label: "Métiers d'entretien",
            name: "Maintenance Trades",
            keywords: [
              "Construction",
              "Electricien, serrurier, plombier, inspecteur en batiment, entretien des ascenseurs",
            ],
          },
          {
            code: 105,
            name: "Other",
            label: "Autres",
            keywords: ["Construction"],
          },
        ],
      },
      {
        industry: "Other",
        label: "Autres Secteurs",
        subIndustries: [
          {
            code: 106,
            name: "Agriculture & Farming",
            label: "Agriculture et exploitation agricole",
            keywords: ["Fermes de petits fruits, vergers, Autres Secteurs"],
          },
          {
            code: 107,
            name: "Farm Products Retail",
            label: "Vente au détail de produits agricoles",
            keywords: ["Alimentation animale, Autres Secteurs"],
          },
          {
            code: 108,
            name: "Manufacturing",
            label: "Fabrication",
            keywords: [
              "Usinage, Textiles, Transformation, Lignes d'assemblage, Prefabrication, Quincaillerie, Autres Secteurs",
            ],
          },
          {
            code: 109,
            name: "Storage Facilities",
            label: "Installations de stockage",
            keywords: [
              "Unites d'entreposage, unites d'entreposage libre-service, Autres Secteurs",
            ],
          },
          {
            code: 110,
            name: "Forestry",
            label: "Foresterie",
            keywords: [
              "Menuiserie, scieries, preservation du bois, Autres Secteurs",
            ],
          },
          {
            code: 111,
            name: "Energy / Oil & Gas / Mining / Minerals Extraction",
            label: "Énergie / pétrole / gaz / mines / extraction de minéraux",
            keywords: ["Extraction de ressources, Autres Secteurs"],
          },
          {
            code: 112,
            name: "Oilfield Services",
            label: "Services pétroliers",
            keywords: [
              "Entretien, traitement, stockage, champs de gaz, appareils de forage, Autres Secteurs",
            ],
          },
          {
            code: 113,
            name: "Biotechnology and Pharmaceuticals",
            label: "Biotechnologie et produits pharmaceutiques",
            keywords: ["Medecine, recherche medicale, Autres Secteurs"],
          },
          {
            code: 114,
            name: "Utilities & Telecommunications",
            label: "Services publics et télécommunications",
            keywords: ["Telephone, electricite, chauffage, Autres Secteurs"],
          },
          {
            code: 115,
            name: "Funeral Homes / Crematoriums",
            label: "Pompes funèbres / Crématoriums",
            keywords: ["Service commemoratif, Autres Secteurs"],
          },
          {
            code: 116,
            name:
              "Commercial printing, publishing and other marketing supplies",
            label:
              "Impression commerciale, édition et autres fournitures de marketing",
            keywords: [
              "Emballage, etiquetage, signalisation, fournitures de marketing, Autres Secteurs",
            ],
          },
          {
            code: 117,
            name: "Parking Facilities",
            label: "Parcs de stationnement",
            keywords: ["Parc de stationnement, garages, Autres Secteurs"],
          },
          {
            code: 118,
            name: "Software",
            label: "Logiciels",
            keywords: [
              "Autres Secteurs",
              "Application, jeux video, conception, developpement, vente, programme informatique, autre",
            ],
          },
          {
            code: 119,
            name: "Entertainment Production",
            label: "Produits de divertissement",
            keywords: [
              "Autres Secteurs",
              "Musique, Film, Theatre, Radiodiffusion, Coordinateur d'evenements et de conferences, Autre",
            ],
          },
          {
            code: 120,
            name: "Financial Services",
            label: "Services financiers",
            keywords: [
              "Autres Secteurs",
              "Prets, compagnies d'assurance, banques, autres",
            ],
          },
          {
            code: 121,
            name: "Property Management",
            label: "Gestion immobilière",
            keywords: ["Autres Secteurs", "Location, Commercial, Autre"],
          },
          {
            code: 122,
            name: "Real Estate Rentals",
            label: "Location de biens immobiliers",
            keywords: ["Autres Secteurs", "Courtier en hypotheque, Autres"],
          },
          {
            code: 123,
            name: "Property Development",
            label: "Développement immobilier",
            keywords: [
              "Autres Secteurs",
              "Promoteur de biens residentiels, Promoteur de logements, Autre",
            ],
          },
        ],
      },
    ];
  }
}

type StepIndustryState = {
  industry: string;
  subIndustry: string;
  handleChange: Function;
  handleChangeManual: Function;
};

type Industry = {
  industry: string;
  label?: string;
  subIndustries: Array<SubIndustry>;
};

type SubIndustry = {
  name: string;
  label?: string;
  keywords: string[];
  code: number;
};
