import { PartnerResponse } from "../store/Store";
import { changeRgbaOpacity, setFavicon } from "../utils/HelperFunctions";
import { Translation, EnglishTranslation } from "./Translation";

export enum AppState {
  LOADING = "loading",
  READY = "ready",
  ERROR = "error",
}

export const NoIndexList = [
  "https://new.merchantgrowth.com/welcome?language=fr&utm_source=website&utm_medium=website&utm_campaign=website",
  "https://new.merchantgrowth.com/welcome?utm_source=website&utm_medium=website&utm_campaign=website&_ga=2.127900557.1630453009.1612200730-803409354.1607020359",
  "https://new.merchantgrowth.com/welcome?utm_source=website&utm_medium=website&utm_campaign=website&_ga=2.127634637.1907387922.1615926120-1560227256.1614014937",
  "https://new.merchantgrowth.com/welcome?utm_source=website&utm_medium=website&utm_campaign=website",
  "https://ecommerce.merchantgrowth.com/welcome?utm_source=website&utm_medium=hello_bar&utm_campaign=ecommerce",
];

export const Styles = {
  textColorPrimary: "#005887",
  textColorPrimaryBright: "#79b8db",
  textColorSecondary: "#67C288",
  textColorSecondaryBright: "#67C288",
  textColorBullet: "#005887",
  textColorDescription: "#616161",
  textColorSubmit: "#fff",
  textColorForm: "#67C288",
  textColorSecondaryAlt: "transparent",
  textColorSuccess: "#2DCE89",
  textColorWarning: "#F5365C",
  textColorStart: "#FEDDAA",
  textColorQuote: "#616161",
  textColorError: "#F5365C",
  textColorTitleSecondary: "#fff",
  textColorInitialTitle: "#005887",
  textColorInitialTitleReverse: "#fff",
  defaultColor: "#fff",
  menuColor: "#3376B7",
  iconFill: "#67C288",
  bgStepper: "#67C288",
  bgStepperOpacity: 1,
  bgInitialTop: "#F4F5F7",
  bgInitialBottom: "#c7f0d6",
  bgInitialBottomOpacity: 1,
  bgInitialTopOpacity: 1,
  bgInitialRight: "#fff",
  bgInitialLeft: "#005887",
  bgInitialMiddle: "#C4DFC6",
  bgInitialButtonSubmit: "#005887",
  bgInitialFooterContainer: "rgba(211,211,211,0.7)",
  bgDropzone: "#80ABCD",
  shieldColor: "#00B67A",
  documentUploadBorderRadius: 10,
  sliderDotSize: 16,
  sliderDotSizeHandle: 32,
  sliderLineHeight: 8,
  assetPath: process.env.PUBLIC_URL,
};

export const LocalSettings: Settings = {
  language: "en",
  logoUrl: `${process.env.PUBLIC_URL}/assets/svg/merchant_logo_transparent.svg`,
  logoMobileUrl: `${process.env.PUBLIC_URL}/assets/svg/merchant_logo_transparent.svg`,
  logoPoweredByUrl: `${process.env.PUBLIC_URL}/assets/svg/poweredby.svg`,
  logoPoweredByWhiteUrl: `${process.env.PUBLIC_URL}/assets/svg/poweredby_white.svg`,
  logoPoweredByVisible: false,
  logoBottomUrl: `${process.env.PUBLIC_URL}/assets/svg/merchant_logo_transparent.svg`,
  googleMapsUrl: `https://maps.google.com/?q=${`200-171 Water St, Vancouver, BC V6B 1A7`}`,
  bgLogoInitialTopOffsetDesktop: -10,
  bgLogoTopOffsetDesktop: -10,
  favicon16Url: `${process.env.PUBLIC_URL}/favicon-16x16.png`,
  favicon32Url: `${process.env.PUBLIC_URL}/favicon-32x32.png`,
  faviconAppleUrl: `${process.env.PUBLIC_URL}/apple-touch-icon.png`,
  finishBackgroundImage: `${process.env.PUBLIC_URL}/assets/img/hipstercoffee.png`,
  companyName: "Merchant Growth",
  companyNameOriginal: "Merchant Growth",
  formattedAddress: "171 Water St #200, Vancouver, BC V6B 1A7",
  formattedAddressVisible: true,
  formattedPhone: "+1 866-240-3694",
  email: "clients@merchantgrowth.com",
  phone: "8662403694",
  website: "https://merchantgrowth.com",
  testimonialImage: `${process.env.PUBLIC_URL}/assets/img/ammar_kapoor.png`,
  companyImage: `${process.env.PUBLIC_URL}/assets/img/sources/seo.png`,
  defaultIndustry: null,
  defaultSubIndustry: null,
  useColorMobileLogo: false,
  useEmail: false,
  translation: EnglishTranslation,
  useLandingPage: true,
};

type Settings = {
  language: "en" | "fr";
  logoUrl: string;
  logoMobileUrl: string;
  logoPoweredByUrl: string;
  logoPoweredByWhiteUrl: string;
  logoPoweredByVisible: boolean;
  logoBottomUrl: string;
  googleMapsUrl: string;
  bgLogoInitialTopOffsetDesktop: number;
  bgLogoTopOffsetDesktop: number;
  favicon16Url: string;
  favicon32Url: string;
  faviconAppleUrl: string;
  finishBackgroundImage: string;
  companyName: string;
  companyNameOriginal: string;
  formattedAddress: string;
  formattedAddressVisible: boolean;
  formattedPhone: string;
  email: string;
  phone: string;
  website: string;
  testimonialImage: string;
  companyImage: string;
  defaultIndustry: string | null;
  defaultSubIndustry: string | null;
  useColorMobileLogo: boolean;
  useEmail: boolean;
  useLandingPage: boolean;
  translation: Translation;
};

export const setPartnerData = (partnerData: PartnerResponse) => {
  const {
    primaryColor,
    whiteLabel,
    buttonColor,
    inverseTitleColor,
    logoMobileUrl,
    logoUrl,
    faviconUrl,
    companyName,
    formattedAddress,
    testimonalText,
    testimonalTextFr,
    testimonialAuthor,
    testimonialAuthorFr,
    testimonialImage,
    companyTextFirstParagraph,
    companyTextFirstParagraphFr,
    companyTextSecondParagraph,
    companyTextSecondParagraphFr,
    companyImage,
    phone,
    email,
    website,
    specificIndustry,
    specificSubIndustry,
    useColourMobileLogo,
    useEmail,
    useLandingPage,
  } = partnerData;

  const opacityTop = 0.1;
  const opacityBottom = 0.3;

  if (logoUrl) {
    LocalSettings.logoUrl = logoUrl;
    LocalSettings.logoPoweredByVisible = !whiteLabel;
  } else {
    // If main logo is not set up, displaying "Powered by" logo instead
    // And hiding the "Powered by" from the right screen side
    LocalSettings.logoUrl = LocalSettings.logoPoweredByUrl;
    LocalSettings.logoPoweredByVisible = false;
  }

  if (whiteLabel) {
    LocalSettings.bgLogoInitialTopOffsetDesktop = -10;
    LocalSettings.bgLogoTopOffsetDesktop = -10;
  } else {
    LocalSettings.bgLogoInitialTopOffsetDesktop = 10;
    LocalSettings.bgLogoTopOffsetDesktop = 10;
  }

  if (logoMobileUrl) {
    LocalSettings.logoMobileUrl = logoMobileUrl;
  } else {
    LocalSettings.logoMobileUrl = LocalSettings.logoUrl;
  }

  LocalSettings.useColorMobileLogo = useColourMobileLogo;
  LocalSettings.useEmail = useEmail;
  LocalSettings.useLandingPage = useLandingPage;
  LocalSettings.companyName = companyName;

  if (formattedAddress) {
    LocalSettings.formattedAddress = formattedAddress;
  } else {
    LocalSettings.formattedAddressVisible = false;
  }

  LocalSettings.phone = phone;
  LocalSettings.email = email;
  LocalSettings.formattedPhone = phone;
  LocalSettings.logoBottomUrl = logoUrl;
  LocalSettings.favicon16Url = faviconUrl;
  LocalSettings.website = website;

  LocalSettings.googleMapsUrl = `https://maps.google.com/?q=${formattedAddress}`;

  //Setting testimonial and company images and text:
  //Both testimonial author and text should be specified:
  const initialPageTranslation = LocalSettings.translation.initialPage;

  switch (LocalSettings.language) {
    case "en":
      if (testimonalText && testimonialAuthor) {
        initialPageTranslation.testimonialText = testimonalText;
        initialPageTranslation.testimonialAuthor = testimonialAuthor;
      }
      break;
    case "fr":
      if (testimonalTextFr && testimonialAuthorFr) {
        initialPageTranslation.testimonialText = testimonalTextFr;
        initialPageTranslation.testimonialAuthor = testimonialAuthorFr;
      }
      break;
  }
  if (testimonialImage) {
    LocalSettings.testimonialImage = testimonialImage;
  }

  //Will be set if any text paragraph is specified:
  switch (LocalSettings.language) {
    case "en":
      if (companyTextFirstParagraph || companyTextSecondParagraph) {
        initialPageTranslation.bottomTextFirstParagraph =
          companyTextFirstParagraph;
        initialPageTranslation.bottomTextSecondParagraph =
          companyTextSecondParagraph;
      } else {
        initialPageTranslation.bottomTextFirstParagraph = `Why apply for small business financing with ${LocalSettings.companyName} instead of a plain old business loan with another company? Not all business loans in Canada are created equally, and we're not your average lender. We help small business owners get fast & easy, no-collateral access to the cash they need so they can focus on their business. It's all we do, and we love doing it! 
Unlike a traditional small loan in Canada, ${LocalSettings.companyName} has incredible customer service, complete transparency with repayment (no hidden fees), and we use the newest and best technology (that's what makes us faster than the other guys). Getting funding for a business doesn’t have to be complicated, and it doesn’t have to take away from you actually running your business. We've helped thousands of Canadian small businesses grow!`;
        initialPageTranslation.bottomTextSecondParagraph = `Let's talk about how we can help your business. There are no restrictions on how you use your cash. You might be looking for a small business loan for renos, for staffing, for seasonal inventory, for growth... whatever it is that you need it for, our business financing has got you covered.`;
      }
      break;
    case "fr":
      if (companyTextFirstParagraphFr || companyTextSecondParagraphFr) {
        initialPageTranslation.bottomTextFirstParagraph =
          companyTextFirstParagraphFr;
        initialPageTranslation.bottomTextSecondParagraph =
          companyTextSecondParagraphFr;
      } else {
        initialPageTranslation.bottomTextFirstParagraph = `Pourquoi demander un financement pour votre entreprise avec ${LocalSettings.companyName} plutôt qu'un simple prêt commercial auprès d'une autre société ? Tous les prêts commerciaux au Canada ne sont pas créés de la même façon, et nous ne sommes pas un prêteur ordinaire. Nous aidons les propriétaires de petites entreprises à obtenir rapidement et facilement, sans garantie, l'argent dont ils ont besoin pour se concentrer sur leur entreprise. C'est tout ce que nous faisons, et nous aimons le faire ! 
Contrairement à un petit prêt traditionnel au Canada, ${LocalSettings.companyName} offre un service à la clientèle incroyable, une transparence totale en matière de remboursement (pas de frais cachés), et nous utilisons la meilleure et la plus récente technologie (c'est ce qui nous rend plus rapides que les autres). Obtenir du financement pour une entreprise ne doit pas être compliqué, et cela ne doit pas vous empêcher de gérer votre entreprise. Nous avons aidé des milliers de petites entreprises canadiennes à se développer !`;
        initialPageTranslation.bottomTextSecondParagraph =
          "Voyons comment nous pouvons aider votre entreprise. Il n'y a aucune restriction quant à l'utilisation de vos liquidités. Vous pouvez rechercher un prêt aux petites entreprises pour des rénovations, pour du personnel, pour des stocks saisonniers, pour la croissance... quel que soit l'usage que vous en fassiez, notre financement des entreprises vous couvre.";
      }
      break;
  }

  if (specificIndustry) {
    LocalSettings.defaultIndustry = specificIndustry;
  }

  if (specificSubIndustry) {
    LocalSettings.defaultSubIndustry = specificSubIndustry;
  }

  if (companyImage) {
    LocalSettings.companyImage = companyImage;
  }

  setFavicon(faviconUrl, "favicon-16");
  setFavicon(faviconUrl, "favicon-32");

  const textColor = "#fff";
  const descriptionColor = "#616161";
  const bulletColor = "#616161";

  if (inverseTitleColor) {
    Styles.textColorInitialTitle = textColor;
    Styles.textColorTitleSecondary = textColor;
  } else {
    Styles.textColorInitialTitle = bulletColor;
    Styles.textColorTitleSecondary = bulletColor;
  }

  Styles.textColorForm = primaryColor;
  Styles.textColorPrimary = primaryColor;
  Styles.textColorPrimaryBright = changeRgbaOpacity(primaryColor, 0.2);
  Styles.bgDropzone = buttonColor;
  Styles.textColorSecondary = buttonColor;
  Styles.iconFill = primaryColor;
  Styles.bgInitialBottom = primaryColor;
  Styles.bgInitialBottomOpacity = opacityBottom;
  Styles.bgInitialTopOpacity = opacityTop;
  // Styles.shieldColor = primaryColor;
  Styles.bgInitialTop = primaryColor;
  Styles.bgInitialButtonSubmit = buttonColor;
  Styles.bgStepper = primaryColor;
  Styles.bgStepperOpacity = 0.8;
  Styles.textColorSubmit = textColor;
  Styles.textColorBullet = bulletColor;
  Styles.textColorDescription = descriptionColor;
};
