import React from 'react';
import ReactDOM from 'react-dom';
import Smartlook from "smartlook-client";
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {makeStore} from './store/Store';
import {StoreContext} from './store/Store';
import "./assets/css/argon-dashboard-pro-react.css";

const store = makeStore();

if (process.env.REACT_APP_SMARTLOOK_PROJECT_KEY) {
  Smartlook.init(process.env.REACT_APP_SMARTLOOK_PROJECT_KEY, {
    advancedNetwork: {
      allowedUrls: [/.*/],
    },
  });
  Smartlook.record({ forms: true, numbers: true, emails: false, ips: false });
}

ReactDOM.render(
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>,
    document.getElementById('root'),
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
