import React from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Form, Col, Row } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import StepperSlider, { StepperMark } from "../StepperSlider";

export default function StepYearsInBusiness() {
  const dispatch = useDispatch();
  const data = useStepYearsInBusiness();

  const yibStepTranslation =
    LocalSettings.translation.applicationPage.yearsInBusinessStep;

  const marksData = getMarksData();
  const value = getSliderValue();

  return (
    <div id="stepYearsInBusiness">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {yibStepTranslation.mainTitle}
      </h2>
      <div className="stepper-form">
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup className="stepper-item">
                <h2
                  style={{
                    color: Styles.textColorPrimary,
                    textAlign: "center" as "center",
                  }}
                >
                  {renderHeader()}
                </h2>
                <StepperSlider
                  marks={marksData.marks}
                  value={value}
                  step={1}
                  min={marksData.minValue}
                  max={marksData.maxValue}
                  handleChange={(value?: number) => {
                    switch (data.yearsInBusinessRange) {
                      case "1":
                        data.handleChangeManual("monthsInBusiness", value);
                        data.handleChangeManual("yearsInBusiness", 0);
                        break;
                      case "2":
                      case "3":
                        data.handleChangeManual("yearsInBusiness", value);
                        data.handleChangeManual("monthsInBusiness", 0);
                        break;
                    }
                  }}
                ></StepperSlider>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  function getSliderValue(): number | undefined {
    switch (data.yearsInBusinessRange) {
      case "1":
        return data.monthsInBusiness;
      case "2":
      case "3":
        return data.yearsInBusiness;
    }
  }

  function getMarksData(): { marks: StepperMark; maxValue: number; minValue: number } {
    let marks: StepperMark = {} as StepperMark;
    let iterativeAmount = 0;
    let maxValue = 0;
    let minValue = 0;
    switch (data.yearsInBusinessRange) {
      case "1":
        while (iterativeAmount <= 11) {
          let label = iterativeAmount.toString();

          marks[iterativeAmount] = {
            style: { top: 10, fontSize: "1rem" },
            label: label,
          };
          iterativeAmount += 1;
        }
        maxValue = 11;
        minValue = 0;
        break;
      case "2":
        while (iterativeAmount <= 10) {
          let label = iterativeAmount.toString();
          marks[iterativeAmount] = {
            style: { top: 10, fontSize: "1rem" },
            label: label,
          };
          iterativeAmount += 2;
        }
        maxValue = 10;
        minValue = 0;
        break;
      case "3":
        iterativeAmount = 10
        while (iterativeAmount <= 20) {
          let label = iterativeAmount.toString();
          marks[iterativeAmount] = {
            style: { top: 10, fontSize: "1rem" },
            label: label,
          };
          iterativeAmount += 2;
        }
        maxValue = 20;
        minValue = 10;
        break;
    }

    return { marks, maxValue, minValue };
  }

  function renderHeader() {
    switch (data.yearsInBusinessRange) {
      case "1":
        if (data.monthsInBusiness != null) {
          return `${data.monthsInBusiness} ${
            data.monthsInBusiness == 1
              ? yibStepTranslation.month
              : yibStepTranslation.months
          }`;
        } else {
          return yibStepTranslation.howManyMonths;
        }
      case "2":
      case "3":
        if (data.yearsInBusiness != null) {
          return `${data.yearsInBusiness} ${
            data.yearsInBusiness == 1
              ? yibStepTranslation.year
              : yibStepTranslation.years
          }`;
        } else {
          return yibStepTranslation.howManyYears;
        }
    }
  }

  function useStepYearsInBusiness(): StepYearsInBusinessState {
    const { input } = useMappedState((state) => state);
    const handleChangeManual = useCallback(
      (name: string, value: any) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: name,
        }),
      []
    );
    return {
      monthsInBusiness: input.monthsInBusiness || undefined,
      yearsInBusiness: input.yearsInBusiness || undefined,
      yearsInBusinessRange: input.yearsInBusinessRange,
      handleChangeManual: handleChangeManual,
    };
  }
}

type StepYearsInBusinessState = {
  yearsInBusiness: number | undefined;
  monthsInBusiness: number | undefined;
  yearsInBusinessRange: string;
  handleChangeManual: Function;
};
