import { useEffect, useState } from "react";

let cachedScripts: string[] = [];
function useScript(url: string) {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    // If cachedScripts already contains the url,
    // it means that another instance of this hook already loaded this script, so no need to load it again
    if (cachedScripts.includes(url)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(url);

      // Create script
      let script: HTMLScriptElement;
      script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.type = "text/javascript";

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedScripts.indexOf(url);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);
      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
        // document.body.removeChild(script);
      };
    }
  }, [url]);

  return [state.loaded, state.error];
}

export default useScript;
