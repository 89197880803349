import React, { useEffect, useState } from "react";
import { useMappedState, useDispatch, StepperPageInput } from "../store/Store";
import { useCallback } from "react";
import { ActionType } from "../store/Store";
import StepIndustry from "./StepperPageSteps/StepIndustry"; // 1
import StepAnnualRevenueRange from "./StepperPageSteps/StepAnnualRevenueRange"; // 2
import StepAnnualRevenue from "./StepperPageSteps/StepAnnualRevenue"; // 3
import StepLegalBusinessName from "./StepperPageSteps/StepLegalBusinessName"; // 5
import StepYearsInBusiness from "./StepperPageSteps/StepYearsInBusiness"; // 6
import StepCustomerPersonalDetails from "./StepperPageSteps/StepCustomerPersonalDetails"; // 7
import StepHomeAddress from "./StepperPageSteps/StepHomeAddress"; // 8
import StepBusinessAddress from "./StepperPageSteps/StepBusinessAddress"; // 9
import StepTermsAndConditions from "./StepperPageSteps/StepTermsAndConditions"; // 10
import { validateStepInput } from "../configuration/StepperConfig";
import { AppState, LocalSettings, Styles } from "../configuration/AppConfig";
import { Button } from "reactstrap";
import Api from "../utils/Api";
import LoadingScreen from "./LoadingScreen";
import Background from "./Background";
import { useParams, useHistory  } from "react-router-dom";
import "./StepperPage.css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { HelperFunctions, track } from "../utils/HelperFunctions";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import queryString from "query-string";
import { StepperProgress } from "./StepperProgress";
import StepYearsInBusinessRange from "./StepperPageSteps/StepYearsInBusinessRange";

var keyDownCallback: any = null;
var popStateCallback: any = null;

function StepperPage() {
  const styles = {
    continueButton: {
      margin: 10,
      backgroundColor: Styles.textColorPrimary,
      borderColor: Styles.textColorPrimary,
      color: Styles.defaultColor,
      fontSize: "1rem",
    },
    backButton: {
      margin: 10,
      backgroundColor: Styles.textColorSecondary,
      borderColor: Styles.textColorSecondary,
      color: Styles.defaultColor,
      fontSize: "1rem",
    },
  };

  const [skipBusinessStep, setSkipBusinessStep] = useState(false);

  const continueToNextStep = () => {
    // Submit the updated data on the background and do not redirect to the next screen (banking details)
    // OA-67: We disabled the progressive profiling for now,
    // TODO: this functionality has been alreayd implemented in Lead Deprecation branch
    // submitApplication(false);
    switch (data.currentStepNumber) {
      case 2:
        track("Annual revenue range completed");
        break;
      case 5:
        track("Years in business range completed");
        break;
    }
    data.goToNextStep();
    pushStepToHistory(data.currentStepNumber);
    HelperFunctions.scrollToTop(10, 30);
  }

  const dispatch = useDispatch();
  const data = useStepperState();
  const { applicationId } = useParams<{ applicationId: string }>();
  const history = useHistory();

  // let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  let size: "sm" | "lg" | undefined = "lg";
  let continueAction: any;

  if (!applicationId) throw new Error("Application id should be provided!");

  let queryParams = queryString.parse(window.location.search);

  //OA-25: Get the redirect url
  let redirectPage = queryParams.redirectPage || null;
  let userId = queryParams.userId || null;
  let companyName = queryParams.companyName || null;

  const stepperTranslation = LocalSettings.translation.applicationPage.stepper;
  const pageTranslation = LocalSettings.translation.applicationPage;




  const setState = useCallback(
    (state: AppState) =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: state }),
    [dispatch]
  );

  window.removeEventListener("keydown", keyDownCallback, true);
  keyDownCallback = function (e: any) {
    handleUserKeyPress(e);
  };
  window.addEventListener("keydown", keyDownCallback, true);

  useEffect(() => {
    window.removeEventListener("popstate", popStateCallback, true);
    popStateCallback = function (e: any) {
      handleBrowserButton(e);
    };
    window.addEventListener("popstate", popStateCallback, true);
  }, [data.currentStepNumber]);

  useEffect(() => {
    HelperFunctions.setPageTitle(
      `${pageTranslation.pageName} - ${LocalSettings.companyName}`
    );
    ReactGA.initialize("UA-15983938-3");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const tagManagerArgs = {
      gtmId: "GTM-KW3ZJC7",
    };
    TagManager.initialize(tagManagerArgs);

    setTimeout(() => {
      const input = {
        type: "Self",
        applicationId: applicationId,
      };
      Api.getApplication(
        input,
        (response: any) => {
          const { state, input } = response.data;

          if(state.isNew) {
            input.externalId = applicationId;
            Api.createApplication({
                ...input,
                language: LocalSettings.language,
                type: "Lead",
              }, 
              (createResponse: any) => {
                history.push(`/application/${createResponse.data.applicationId}?language=en`);
                window.location.reload();
              }, 
              (error: any) => {
                alert(JSON.stringify(error));
                setState(AppState.ERROR);
              }
            );
            return;
          }

          const { homeAddress, businessAddress } = input;

          data.handleSetInitialData(input);

          const stepsToSkip: string[] = [];
          const skipBusinessAddressStep =
            homeAddress && homeAddress === businessAddress;
          setSkipBusinessStep(skipBusinessAddressStep);

          if (skipBusinessAddressStep) {
            stepsToSkip.push("business_addr");
          }

          let steps = getStepsArray(stepsToSkip);

          let currentAppStep = state.currentApplicationSubstep;
          if (currentAppStep > steps.length) {
            currentAppStep = steps.length;
          }

          data.handleSetInitialStep(currentAppStep);

          // This is needed to allow moving backwards using browser's back button
          for (let i = currentAppStep; i > 0; i--) {
            pushStepToHistory(i);
          }

          setState(AppState.READY);
        },
        (error: any) => {
          alert(JSON.stringify(error));
          setState(AppState.ERROR);
        }
      );
    }, 1500);
  }, []);



  const renderedStepper = renderStepper();
  return renderedStepper;



  function renderRemainingTime() {
    return (
      <span style={{ fontSize: 13 }}>
        {Math.round((data.totalSteps - data.currentStepNumber + 1) / 2)}{" "}
        {stepperTranslation.minutesFromFinish}
      </span>
    );
  }

  function renderStepper() {
    const percentageCompleted =
      (data.currentStepNumber * 100) / data.totalSteps;

    const imageName = "stepper";
    return (
      <div>
        <div
          style={{
            display:
              data.applicationState === AppState.LOADING ? "block" : "none",
          }}
        >
          <LoadingScreen />
        </div>
        <div
          style={{
            display:
              data.applicationState === AppState.READY ? "block" : "none",
          }}
        >
          <Background
            image={imageName}
            useTemplate={true}
            useFooter={true}
            useSvg={true}
          >
            <div style={{ color: Styles.textColorPrimary }}>
              <div style={{ textAlign: "center", position: "relative" }}>
                {renderRemainingTime()}
                <div
                  style={{ maxWidth: 600, textAlign: "center", margin: "auto" }}
                >
                  <StepperProgress
                    value={percentageCompleted}
                  ></StepperProgress>
                </div>
              </div>
              <div>
                <div style={{ width: "100%" }}>
                  <ReactCSSTransitionGroup
                    transitionName={(() => {
                      switch (data.direction) {
                        case StepperDirection.BACKWARD:
                          return "step-transition-reverse";
                        case StepperDirection.FORWARD:
                          return "step-transition";
                        default:
                          throw new Error("Unknown stepper direction!");
                      }
                    })()}
                    transitionEnterTimeout={0}
                    transitionLeaveTimeout={0}
                    component="div"
                    className="stepper-wrapper"
                  >
                    <div
                      className="stepper-indent"
                      key={`step_${data.currentStepNumber}`}
                    >
                      {data.currentStep}
                    </div>
                  </ReactCSSTransitionGroup>
                </div>
                <div>
                  <div style={{ textAlign: "center" }}>{renderButtons()}</div>
                </div>
                <div>
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="stepper-image"
                      src={`${process.env.PUBLIC_URL}/assets/img/${data.currentImage}`}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </Background>
        </div>
        <div
          style={{
            display:
              data.applicationState === AppState.ERROR ? "block" : "none",
          }}
        >
          <div>An error has occured, please contact support</div>;
        </div>
      </div>
    );
  }

  function handleBrowserButton(event: any) {
    if (data.currentStepNumber !== 1) {
      data.goToPrevStep();
    }
  }

  function handleUserKeyPress(event: KeyboardEvent): void {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      // event.stopPropagation();
      if (continueAction && data.valid) continueAction();
    }
  }

  function getVisibility(stepName: string): "visible" | "hidden" {
    switch (stepName) {
      case "years_range":
        if (data.input.yearsInBusinessRange === "") {
          return "hidden";
        } else {
          return "visible";
        }
      case "revenue_range":
        if (data.input.approximateAnnualRevenueRange === "") {
          return "hidden";
        } else {
          return "visible";
        }
      default:
        return "visible";
    }
  }

  function renderButtons(): Array<any> {
    let array: Array<any> = [];

    let backAction = () => {
      window.history.back();
    };

    if (!data.firstStep)
      array.push(
        <Button
          key="button_back"
          style={{
            ...styles.backButton,
            visibility: getVisibility(data.currentStepName),
          }}
          color="secondary"
          onClick={backAction}
          size={size}
        >
          {stepperTranslation.back}
        </Button>
      );

    let nextButtonCaption, nextbuttonClassName;

    if (data.finalStep) {
      nextButtonCaption = isMobile
        ? stepperTranslation.submitMobile
        : stepperTranslation.submit;
      // Submit the application and redirect to the next screen (banking details)
      continueAction = () => submitApplication(true); //executeRecaptcha();
    } else {
      nextButtonCaption = stepperTranslation.continue;
      continueAction = () => {
        submitApplication(false);
        continueToNextStep();
      };
    }

    let pulsateButtonCondition = data.valid;

    switch (data.currentStep.stepName) {
      // Annual Revenue:
      case "revenue":
        pulsateButtonCondition =
          pulsateButtonCondition &&
          data.input.approximateAnnualRevenue !== null &&
          data.input.approximateAnnualRevenue >= 80000;
        break;
      // Years in Business:
      // case "years":
      //   // pulsateButtonCondition =
      //   //   pulsateButtonCondition &&
      //   //   data.input.yearsInBusiness !== null &&
      //   //   data.input.yearsInBusiness > 0;
      //   break;
      // Terms And Conditions Step, will introduce the enumeration later:
      case "terms":
        pulsateButtonCondition = pulsateButtonCondition && data.input.consent;
        break;
    }

    nextbuttonClassName = pulsateButtonCondition ? "pulsate-bck" : "";
    array.push(
      <Button
        key="button_continue"
        id={`application_step_${data.currentStepNumber}`}
        style={{
          ...styles.continueButton,
          visibility: getVisibility(data.currentStepName),
        }}
        onClick={continueAction}
        disabled={!data.valid}
        color="primary"
        size={size}
        className={nextbuttonClassName}
      >
        {nextButtonCaption}
      </Button>
    );
    return array;
  }



  function pushStepToHistory(currentStepNumber: number) {
    window.history.pushState(
      { step: currentStepNumber },
      "step" + currentStepNumber
    );
  }

  function addUserId(symbol: "&" | "?") {
    return userId === null ? "" : `${symbol}userId=${userId}`;
  }

  function submitApplication(redirectToNextScreen: boolean) {
    // Set application state to LOADING:
    if (redirectToNextScreen) setState(AppState.LOADING);

    const callbackSuccess = (result : any) => {
      const applicationId = result.data.applicationId;

      // Move to the next page:
      if (redirectToNextScreen) {
        let url: string;
        if (redirectPage != null) {
          url = `/${redirectPage}/${applicationId}?language=${LocalSettings.language}`;
        } else {
          window.uetq.push("event", "purchase", {});
          url = `/banking/${applicationId}?language=${LocalSettings.language}`;
        }
        if (companyName != null) {
          url += `&companyName=${companyName}`;
        }
        window.location.href = url + addUserId("&");
      }
    };
    const callbackError = (error: any) => {
      alert(JSON.stringify(error));
      setState(AppState.ERROR);
    };

    let nextStepNumber: number;
    if (data.currentStepNumber < 10) {
      nextStepNumber = data.currentStepNumber + 1;
    } else {
      nextStepNumber = 10;
    }

    let currentStepName: string = data.currentStepName;

    //MSD-4319: If both of these fields are set,
    //Always overriding industry/subindustry with these values
    if (
      LocalSettings.defaultIndustry !== null &&
      LocalSettings.defaultSubIndustry !== null
    ) {
      data.input.industry = LocalSettings.defaultIndustry;
      data.input.subIndustry = LocalSettings.defaultSubIndustry;
    }

    const input = {
      ...data.input,
      applicationId: applicationId as string,
      // recaptchaResponse,
      type: "Self",
      isPartialUpdate: !redirectToNextScreen,
      // Next step (we're submitting data before switching the step index)
      currentApplicationSubStep: nextStepNumber,
      currentApplicationSubStepName: currentStepName,
    };

    switch (data.currentStepName) {
      case "industry":
        track("Industry completed");
        break;
      case "revenue":
        track("Annual revenue completed");
        break;
      case "business_name":
        track("Legal business name completed");
        break;
      case "years":
        track("Years in business completed");
        break;
      case "personal_details":
        track("DOB and Phone number completed");
        break;
      case  "home_addr":
        track("Home address completed");
        break;
      case "business_addr":
        track("Business address completed");
        break;
      case "home_and_business_addr":
        track("Home address completed");
        track("Business address completed");
        break;
      case "terms":
        data.input.consent ? track("Terms and conditions accepted") : track("Terms and conditions not accepted");
        track("Application submitted");
    }

    Api.updateApplication(input, callbackSuccess, callbackError);
  }

  function getStepsArray(
    stepsToSkip: string[] = []
  ): { step: any; stepName: string; image: string }[] {
    let stepsArray = [];

    if (LocalSettings.defaultSubIndustry == null) {
      stepsArray.push({
        step: <StepIndustry />,
        stepName: "industry",
        image: "animations_loading_normal.gif",
      });
    }
    stepsArray.push({
      step: <StepAnnualRevenueRange callback={continueToNextStep}/>,
      stepName: "revenue_range",
      image: "animations_scoring_normal.gif",
    });
    stepsArray.push({
      step: <StepAnnualRevenue />,
      stepName: "revenue",
      image: "animations_quoting_normal.gif",
    });
    stepsArray.push({
      step: <StepLegalBusinessName />,
      stepName: "business_name",
      image: "animations_loading_normal.gif",
    });
    stepsArray.push({
      step: <StepYearsInBusinessRange callback={continueToNextStep}/>,
      stepName: "years_range",
      image: "animations_loading_normal.gif",
    });
    stepsArray.push({
      step: <StepYearsInBusiness />,
      stepName: "years",
      image: "animations_loading_normal.gif",
    });
    stepsArray.push({
      step: <StepCustomerPersonalDetails />,
      stepName: "personal_details",
      image: "animations_loading_normal.gif",
    });
    
    if (!skipBusinessStep && !stepsToSkip?.includes("business_addr")) {
      stepsArray.push({
        step: (
          <StepHomeAddress
            skipBusinessAddress={skipBusinessStep}
            setBusinessAddressCallback={(skipBusinessStep: boolean) =>
              setSkipBusinessStep(skipBusinessStep)
            }
          />
        ),
        stepName: "home_addr",
        image: "animations_loading_normal.gif",
      });
      stepsArray.push({
        step: <StepBusinessAddress />,
        stepName: "business_addr",
        image: "animations_loading_normal.gif",
      });
    }
    else {
      stepsArray.push({
        step: (
          <StepHomeAddress
            skipBusinessAddress={skipBusinessStep}
            setBusinessAddressCallback={(skipBusinessStep: boolean) =>
              setSkipBusinessStep(skipBusinessStep)
            }
          />
        ),
        stepName: "home_and_business_addr",
        image: "animations_loading_normal.gif",
      });
    }
    stepsArray.push({
      step: <StepTermsAndConditions />,
      stepName: "terms",
      image: "animations_quoting_normal.gif",
    });

    return stepsArray;
  }

  function useStepperState(): StepperState {
    const { pageIndex, input, applicationState } = useMappedState(
      (state) => state
    );
    const [direction, setDirection] = useState(StepperDirection.FORWARD);

    const dispatch = useDispatch();

    const steps = getStepsArray();

    let nextStepIndex = pageIndex + 1;
    let prevStepIndex = pageIndex - 1;

    const nextStep = useCallback(() => {
      setDirection(StepperDirection.FORWARD);
      dispatch({ type: ActionType.CHANGE_PAGE, value: nextStepIndex });
    }, [dispatch, nextStepIndex]);
    const prevStep = useCallback(() => {
      setDirection(StepperDirection.BACKWARD);
      dispatch({ type: ActionType.CHANGE_PAGE, value: prevStepIndex });
    }, [dispatch, prevStepIndex]);

    const handleSetInitialData = useCallback((value: any) => {
      dispatch({
        type: ActionType.CHANGE_INPUT_ALL,
        value: value,
      });
    }, []);

    const handleSetInitialStep = useCallback((value: number) => {
      dispatch({
        type: ActionType.CHANGE_PAGE,
        value: value,
      });
    }, []);

    let currentPageIndex;
    if (pageIndex > steps.length) {
      currentPageIndex = steps.length;
    } else {
      currentPageIndex = pageIndex;
    }

    const currentStep = steps[currentPageIndex - 1];

    return {
      applicationState: applicationState,
      currentStepNumber: currentPageIndex,
      currentStep: currentStep.step,
      currentImage: currentStep.image,
      currentStepName: currentStep.stepName,
      allSteps: steps,
      totalSteps: steps.length,
      goToNextStep: nextStep,
      goToPrevStep: prevStep,
      finalStep: currentPageIndex === steps.length,
      firstStep: currentPageIndex === 1,
      input: input,
      valid: validateStepInput(input, currentStep.stepName),
      direction: direction,
      handleSetInitialData: handleSetInitialData,
      handleSetInitialStep: handleSetInitialStep,
    };
  }
}

type StepperState = {
  applicationState: AppState;
  currentStepNumber: number;
  currentStep: any;
  currentImage: string;
  currentStepName: string;
  allSteps: any[];
  totalSteps: number;
  goToNextStep: Function;
  goToPrevStep: Function;
  finalStep: boolean;
  firstStep: boolean;
  input: StepperPageInput;
  valid: boolean;
  direction: StepperDirection;
  handleSetInitialData(value: StepperPageInput): void;
  handleSetInitialStep(value: number): void;
};

enum StepperDirection {
  FORWARD = "forward",
  BACKWARD = "backward",
}

export default StepperPage;
