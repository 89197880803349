import React, { useState, ChangeEvent, useEffect } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import ModalDialog from "../ModalDialog";
import TermsAndConditions from "../TermsAndConditions";
import LinkedInTag from 'react-linkedin-insight';

const styles = {
  itemCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap" as "wrap",
  },
  container: {
    padding: 30,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
  },
  item: {
    margin: 10,
  },
};

export default function StepTermsAndConditions() {
  const dispatch = useDispatch();
  const data = useStepTermsAndConditions();
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);

  useEffect(() => {
    LinkedInTag.init('3430250');
    LinkedInTag.track('5199058');
  },[])


  const tcStepTranslation =
    LocalSettings.translation.applicationPage.termsAndConditionsStep;

  return (
    <div id="stepTermsAndConditions">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {tcStepTranslation.mainTitle}
      </h2>
      <div className="stepper-form">
        <div style={styles.container}>
          <div style={styles.itemCheckbox}>
            <div style={styles.item}>{tcStepTranslation.agreeSwitch}</div>
            <label style={styles.item} className="switch">
              <input
                readOnly={true}
                checked={data.consent}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  data.handleChangeManual(e.target.checked, "consent")
                }
                id="agreementCheck"
                name="consent"
                type="checkbox"
              />
              <span
                style={{
                  backgroundColor: data.consent
                    ? Styles.textColorPrimary
                    : "#b0b0b0",
                }}
                className="slider round"
              ></span>
            </label>
          </div>
          <div>
            <div
              style={{
                color: Styles.textColorPrimary,
                textAlign: "center" as "center",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="terms-link"
              onClick={() => {
                setTermsDialogOpen(true);
              }}
            >
              {tcStepTranslation.termsLink}
            </div>
          </div>
        </div>
      </div>
      <ModalDialog
        title={tcStepTranslation.termsAndConditionsTitle}
        textStyle={{ color: "#fff", fontWeight: 300, fontSize: "20px" }}
        agreeText={tcStepTranslation.termsAgree}
        disagreeText={tcStepTranslation.termsDisagree}
        handleClose={() => setTermsDialogOpen(false)}
        handleAgreed={() => {
          data.handleChangeManual(true, "consent");
          setTermsDialogOpen(false);
        }}
        handleDisagreed={() => setTermsDialogOpen(false)}
        hideCloseButton={false}
        contentStyle={{
          backgroundColor: Styles.textColorPrimary,
        }}
        buttonAgreeStyle={{
          backgroundColor: Styles.textColorSecondaryAlt,
          borderColor: Styles.textColorSecondaryAlt,
          color: Styles.defaultColor,
          boxShadow: "unset",
        }}
        buttonDisagreeStyle={{
          backgroundColor: Styles.textColorSecondaryAlt,
          borderColor: Styles.textColorSecondaryAlt,
          color: Styles.defaultColor,
          boxShadow: "unset",
        }}
        open={termsDialogOpen}
      >
        <TermsAndConditions textStyle={{ color: "#fff" }} />
      </ModalDialog>
    </div>
  );

  function useStepTermsAndConditions(): StepTermsAndConditionsState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );
    const handleChangeManual = useCallback(
      (value: string, propertyName: string) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        }),
      []
    );
    return {
      consent: input.consent,
      handleChange: handleChange,
      handleChangeManual: handleChangeManual,
    };
  }
}

type StepTermsAndConditionsState = {
  consent: boolean;
  handleChange: Function;
  handleChangeManual: Function;
};
