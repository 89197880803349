import { IAction, IState, INITIAL_STATE, ActionType } from "./Store";
import update from 'react-addons-update';

export default function reducer(
  state: IState = INITIAL_STATE,
  action: IAction
): IState {
  switch (action.type) {
    case ActionType.CHANGE_PAGE:
        return update(state, {
            pageIndex: {$set: action.value}
        })
    case ActionType.CHANGE_APP_STATE:
        return update(state, {
            applicationState: {$set: action.value}
        })
    case ActionType.CHANGE_DOCUMENT_DETAILS:
        if (!action.index)
            throw new Error("CHANGE_DOCUMENT_DETAILS requires document index specified");
        return update(state, {
            documents: {
                [action.index] : {$set : action.value}
            }
        })
    case ActionType.CHANGE_DOCUMENTS:
        return update(state, {
            documents: {$set: action.value}
        })
    case ActionType.CHANGE_INPUT:
        if (!action.propertyName)
            throw new Error("CHANGE_INPUT requires propertyName specified")
        return update(state, {
            input: {
                [action.propertyName] : {$set : action.value}
            }
        })
    case ActionType.CHANGE_INPUT_ALL:
        return update(state, {
            input: {$set: action.value}
        })
    case ActionType.CHANGE_BANK_AUTH:
        return update(state, {
            bankAuthorized: {$set: action.value}
        })
    default:
      return state;
  }
}
