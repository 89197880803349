import React from "react";
import { Styles } from "../../configuration/AppConfig";
import "./ShieldIcon.css";

export default function ShieldIcon() {
    return (
        <div className="icon-shield">
            <svg width="50" height="50" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1.5L4.5 7.5V16.5C4.5 24.825 10.26 32.61 18 34.5C25.74 32.61 31.5 24.825 31.5 16.5V7.5L18 1.5ZM28.5 16.5C28.5 23.28 24.03 29.535 18 31.395C11.97 29.535 7.5 23.28 7.5 16.5V9.44997L18 4.78497L28.5 9.44997V16.5ZM9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5L9 19.5Z" 
                    fill={Styles.shieldColor} />
            </svg>
        </div>
    )
}