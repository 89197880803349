import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "reactstrap";
import "./PartnerCard.css";
import { HelperFunctions } from "../utils/HelperFunctions";
import { LocalSettings, Styles } from "../configuration/AppConfig";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Api from "../utils/Api";
import { version } from "../../package.json";

export default function PartnerCard(): JSX.Element {
  const [data, setData] = useState<any>();
  const [appStatus, setAppStatus] = useState<
    "pending" | "detail" | "summary" | "pending-detail" | "loading" | "error"
  >("loading");

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    const partnerToken = queryParams.partnerToken || null;

    if (partnerToken !== null && typeof partnerToken === "string") {
      const isFull = queryParams.isFull || null;

      Api.getPartnerDetails(
        {
          applicationId: partnerToken,
          type: "Partner_details",
        },
        (result) => {
          const { applicationStatus, fields, partnerApplicationId } =
            result.data;
          let status: "summary" | "detail" | "pending-detail" | "pending";
          switch (applicationStatus) {
            case "summary":
              setData(fields);
              status = isFull === "true" ? "detail" : "summary";
              setAppStatus(status);
              break;
            case "pending":
              status = isFull === "true" ? "pending-detail" : "pending";
              setAppStatus(status);
              break;
            default:
              redirectToStart(partnerApplicationId);
              break;
          }
        },
        (e) => {
          setAppStatus("error");
        }
      );
    } else {
      alert("Token should be provided");
    }
  }, []);

  const DisplayComponent = (props: any) => {
    return (
      <>
        <p className="text-muted text-center card-small-text very-close-to-bottom-sibling">
          {props.text}
        </p>
        <p
          className={`text-dark text-center ${
            props.displayLarge ? "card-large-text" : "card-medium-text"
          }`}
        >
          {props.value}
        </p>
      </>
    );
  };

  const DisplayErrorComponent = (props: any) => {
    return <>An error has occured when processing your request</>;
  };

  const DisplayDetailComponent = (props: any) => {
    return (
      <Row className="mb-3">
        <Col className="text-muted card-small-text" xs="auto">
          {props.text}
        </Col>
        <Col className="filler-space"></Col>
        <Col className="text-dark card-small-text bold-text" xs="auto">
          {getAppropriateFormattedValue(props.text)}
        </Col>
      </Row>
    );
  };

  const getAppropriateFormattedValue = (key: string): string => {
    let element = data.find((e: any) => e.text === key);
    switch (element.type) {
      case "currency":
        return HelperFunctions.formatCurrency(
          element.val,
          0,
          2,
          LocalSettings.language
        );
      case "percentage":
        return HelperFunctions.formatPercentage(element.val);
      case "period":
        return `${element.val} months`;
      default:
        return "N/A";
    }
  };

  const showDetailView = () => {
    setAppStatus("detail");
  };

  // const showPendingDetailView = () => {
  //   setAppStatus("pending-detail");
  // };

  const redirectToMerchantGrowthAccount = () => {
    window.location.href = "https://portal.merchantadvance.com";
  };

  const redirectToStart = (partnerApplicationId: string) => {
    const partnerUserId = queryParams.partnerUserId || null;
    let partnerStr = partnerUserId !== null ? `&userId=${partnerUserId}` : ``;
    window.location.href = `/welcome?externalId=${partnerApplicationId}${partnerStr}&v=${version}`;
  };

  const callMerchantGrowthSupport = () => {
    window.open("tel:18662403694", "_self");
  };

  const displaySummaryView = () => {
    return (
      <div className="partner-card-container mt-4 mx-auto px-2">
        <div className="partner-logo mb-4">
          <img src={LocalSettings.logoMobileUrl} alt="Partner Logo Picture" />
        </div>
        <Row className="loan-amount-container">
          <Col>
            <DisplayComponent
              text="Loan Amount"
              value={HelperFunctions.formatCurrency(
                getValueFromData("Loan Amount"),
                0,
                0,
                LocalSettings.language
              )}
              displayLarge={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DisplayComponent
              text="Weekly Payment"
              value={HelperFunctions.formatCurrency(
                getValueFromData("Weekly Payment"),
                2,
                2,
                LocalSettings.language
              )}
            />
          </Col>
          <Col>
            <DisplayComponent
              text="Loan Term"
              value={getValueFromData("Loan Term") + " months"}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <DisplayComponent
              text="Repayment Amount"
              value={HelperFunctions.formatCurrency(
                getValueFromData("Repayment Amount"),
                0,
                2,
                LocalSettings.language
              )}
            />
          </Col>
          <Col>
            <DisplayComponent
              text="Interest Rate"
              value={HelperFunctions.formatPercentage(
                getValueFromData("Simple Interest Rate")
              )}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <button
            className="btn partner-card-button mb-4"
            onClick={showDetailView}
          >
            View Loan Details
          </button>
        </Row>
      </div>
    );
  };

  const displayDetailedView = () => {
    return (
      <div className="partner-card-detail-container mt-4 mx-auto px-3">
        <div className="partner-logo mb-4">
          <img src={LocalSettings.logoMobileUrl} alt="Partner Logo Picture" />
        </div>
        <div className="data-container">
          {data?.map((r: any, index: number) => (
            <DisplayDetailComponent text={r.text} key={index} />
          ))}
        </div>
        <Row className="button-container justify-content-center align-items-bottom mt-5 mb-4">
          <button
            className="btn partner-card-button mb-3 mx-0"
            onClick={redirectToMerchantGrowthAccount}
          >
            Access My Merchant Growth Account
          </button>
          <button
            className="btn partner-card-button mb-4 mx-0"
            onClick={callMerchantGrowthSupport}
          >
            Contact Customer Service
          </button>
        </Row>
      </div>
    );
  };

  const displayPendingView = () => {
    return (
      <div className="partner-card-container mt-4 mx-auto px-3">
        <div className="partner-logo mb-4">
          <img src={LocalSettings.logoMobileUrl} alt="Partner Logo Picture" />
        </div>
        <div className="pending-data-container mb-5">
          <p className="text-body text-center large-font mb-4">
            Thanks for applying!
          </p>
          <p className="text-body text-center">
            You will receive an email within 24 hours with the status of your
            application, or a request for additional details. Please keep an eye
            out for an email from success@merchantgrowth.com
          </p>
        </div>
        {/* <Row className="justify-content-center">
          {isStateOpen ? (
            <button
              className="btn partner-card-button mx-0 mb-4"
              onClick={callMerchantGrowthSupport}
            >
              Contact Customer Service
            </button>
          ) : (
            <button
              className="btn partner-card-button mx-0 mb-4"
              onClick={showPendingDetailView}
            >
              View Details
            </button>
          )}
        </Row> */}
      </div>
    );
  };

  const displayError = () => {
    return (
      <Row className="loan-amount-container">
        <Col>
          <DisplayErrorComponent />
        </Col>
      </Row>
    );
  };

  const displayLoading = () => {
    return (
      <Row className="loading-container">
        <Col>
          <div className="loading-animation-container">
            <Spinner type="grow" style={{ color: Styles.textColorPrimary }} />
            <Spinner type="grow" style={{ color: Styles.textColorPrimary }} />
            <Spinner type="grow" style={{ color: Styles.textColorPrimary }} />
            <Spinner type="grow" style={{ color: Styles.textColorPrimary }} />
          </div>
        </Col>
      </Row>
    );
  };

  function getValueFromData(key: string): number {
    if (data.length > 0) {
      return data.find((e: any) => e.text === key)?.val;
    }
    return 0.0;
  }

  switch (appStatus) {
    case "summary":
      return displaySummaryView();
    case "detail":
      return displayDetailedView();
    case "pending-detail":
    case "pending":
      return displayPendingView();
    case "error":
      return displayError();
    case "loading":
      return displayLoading();
  }
}
