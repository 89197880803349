import { AppState } from "../configuration/AppConfig";
import { Input, IState } from "../store/Store";
import { HelperFunctions } from "../utils/HelperFunctions";

export default function StepperConfig(): IState {
  return {
    applicationId: "",
    pageIndex: 1,
    applicationState: AppState.LOADING,
    input: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      requestedAmount: null,
      approximateAnnualRevenue: null,
      approximateAnnualRevenueRange: "",
      yearsInBusiness: null,
      monthsInBusiness: null,
      yearsInBusinessRange: "",
      phoneNumber: "",
      email: "",
      businessName: "",
      homeAddress: "",
      businessAddress: "",
      industry: "",
      subIndustry: "",
      legalBusinessName: "",
      consent: false,
    },
    bankAuthorized: false,
    bankInProcess: false,
    documents: [],
  };
}

export function validateStepInput(input: Input, stepName: string): boolean {
  switch (stepName) {
    case "industry":
      return input.industry !== "" && input.subIndustry !== "";
    case "revenue_range":
      return input.approximateAnnualRevenueRange !== "";
    case "revenue":
      return (
        input.approximateAnnualRevenue !== null &&
        input.approximateAnnualRevenue > 0
      );
    case "amount":
      return (
        input.requestedAmount !== null &&
        input.requestedAmount >= 5000 &&
        input.requestedAmount <= 500000
      );
    case "business_name":
      return (
        input.legalBusinessName != null &&
        input.legalBusinessName !== "" &&
        input.legalBusinessName.length > 3
      );
    case "years_range":
      return (
        input.yearsInBusinessRange != null && input.yearsInBusinessRange != ""
      );
    case "years":
      return (
        (input.yearsInBusiness != null && input.yearsInBusiness > 0) ||
        (input.monthsInBusiness != null && input.monthsInBusiness > 0)
      );
    case "personal_details":
      return (
        input.dateOfBirth != null &&
        input.dateOfBirth != "" &&
        HelperFunctions.dateIsValid(input.dateOfBirth) &&
        HelperFunctions.getAgeFromDateOfBirth(input.dateOfBirth) >= 18 &&
        HelperFunctions.getAgeFromDateOfBirth(input.dateOfBirth) <= 99 &&
        input.phoneNumber != null &&
        input.phoneNumber !== "" &&
        HelperFunctions.phoneIsValid(input.phoneNumber)
      );
    case "home_addr":
    case "home_and_business_addr":  
      return input.homeAddress != null && input.homeAddress != "";
    case "business_addr":
      return input.businessAddress != null && input.businessAddress != "";
    case "terms":
      return true;
    default:
      throw new Error(`Unknown step: ${stepName}`);
  }
}
