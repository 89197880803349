import React from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Label, Row, Col, Button } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";

export default function StepYearsInBusinessRange(
  props: StepYearsInBusinessRangeProps
) {
  const data = useStepYearsInBusinessRange();
  const dispatch = useDispatch();

  const yearsInBusinessRangeTranslation =
    LocalSettings.translation.applicationPage.yearsInBusinessRangeStep;
  let size: "sm" | "lg" | undefined = "lg";

  return (
    <div id="stepAmounts">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {yearsInBusinessRangeTranslation.mainTitle}
      </h2>
      <div className="stepper-form">
        <Row form>
          <Col md={12}>
            <FormGroup className="stepper-item">{renderRange()}</FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );

  function renderRange() {
    const selectedStyle = {
      backgroundColor: Styles.textColorSecondary,
      borderColor: Styles.textColorSecondary,
      color: Styles.defaultColor,
      boxShadow: "unset",
      margin: 10,
    };

    const unSelectedStyle = {
      backgroundColor: Styles.textColorPrimary,
      borderColor: Styles.textColorPrimary,
      color: Styles.defaultColor,
      boxShadow: "unset",
      margin: 10,
    };

    return (
      <div style={{ textAlign: "center" }}>
        <Label>{yearsInBusinessRangeTranslation.secondaryTitle}</Label>
        <div>
          <Button
            style={
              data.yearsInBusinessRange === "1"
                ? selectedStyle
                : unSelectedStyle
            }
            onClick={() => {
              raiseCallback();
              data.handleChangeManual("yearsInBusinessRange", "1");
            }}
            size="lg"
          >
            {yearsInBusinessRangeTranslation.range_1}
          </Button>
          <Button
            style={
              data.yearsInBusinessRange === "2"
                ? selectedStyle
                : unSelectedStyle
            }
            onClick={() => {
              raiseCallback();
              data.handleChangeManual("yearsInBusinessRange", "2");
            }}
            size="lg"
          >
            {yearsInBusinessRangeTranslation.range_2}
          </Button>
          <Button
            style={
              data.yearsInBusinessRange === "3"
                ? selectedStyle
                : unSelectedStyle
            }
            onClick={() => {
              raiseCallback();
              data.handleChangeManual("yearsInBusinessRange", "3");
            }}
            size="lg"
          >
            {yearsInBusinessRangeTranslation.range_3}
          </Button>
        </div>
      </div>
    );
  }

  function raiseCallback() {
    if (data.yearsInBusinessRange == "") {
      props.callback();
    }
  }

  function useStepYearsInBusinessRange(): StepYearsInBusinessRangeState {
    const { input } = useMappedState((state) => state);
    const handleChangeManual = useCallback(
      (propertyName: string, value: any) => {
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        });
      },
      []
    );

    return {
      yearsInBusinessRange: input.yearsInBusinessRange,
      handleChangeManual,
    };
  }
}

type StepYearsInBusinessRangeState = {
  yearsInBusinessRange: string;
  handleChangeManual: Function;
};

type StepYearsInBusinessRangeProps = {
  callback: () => void;
};
