import React, {
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { useMappedState, useDispatch, ActionType } from "../store/Store";
import { useCallback } from "react";
import { Styles } from "../configuration/AppConfig";
import { Form, Row, Col } from "reactstrap";
import Slider, { SliderProps } from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { isMobile } from "react-device-detect";

//@ts-ignore
const createSliderWithTooltip = Slider.createSliderWithTooltip;
//@ts-ignore
const Handle = Slider.Handle;
const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    // <Tooltip
    //   prefixCls="rc-slider-tooltip"
    //   overlay={value}
    //   visible={dragging}
    //   placement="top"
    //   key={index}
    // >
    <Handle value={value} {...restProps} />
    // </Tooltip>
  );
};

const wrapperStyle = { width: "80%", margin: "auto", marginTop: 20 };
export default function StepperSlider(props: StepperSliderProps) {
  const [demoValue, setDemoValue] = useState(0);
  const [demoIsFinished, setDemoIsFinished] = useState(false);
  const [direction, setDirection] = useState<"forward" | "backward">("forward");
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!demoIsFinished && props.value === undefined) {
      let width = componentRef.current?.clientWidth || 0;
      let track = document.getElementsByClassName(
        "rc-slider-track"
      )[0] as HTMLElement;
      track.style.width = `${demoValue}px`;

      let timeout: number | undefined = undefined;
      switch (direction) {
        case "forward":
          if (demoValue < width / 2) {
            timeout = setTimeout(() => {
              setDemoValue(demoValue + 6);
            }, isMobile ? 15 : 2);
          } else {
            setDirection("backward");
          }
          break;
        case "backward":
          if (demoValue > 0) {
            timeout = setTimeout(() => {
              setDemoValue(demoValue - 6);
            }, isMobile ? 15 : 2);
          } else {
            setDemoIsFinished(true);
          }
          break;
      }
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [demoValue, demoIsFinished, direction]);

  return (
    <div style={wrapperStyle} ref={componentRef}>
      <Slider
        step={props.step}
        marks={props.marks}
        trackStyle={{
          backgroundColor: Styles.textColorPrimary,
          height: Styles.sliderLineHeight,
        }}
        railStyle={{
          backgroundColor: Styles.textColorPrimaryBright,
          height: Styles.sliderLineHeight,
        }}
        dotStyle={{
          borderColor: Styles.textColorPrimary,
          backgroundColor: Styles.defaultColor,
          height: Styles.sliderDotSize,
          width: Styles.sliderDotSize,
          bottom: -8,
        }}
        activeDotStyle={{
          borderColor: Styles.textColorPrimary,
          backgroundColor: Styles.defaultColor,
          height: Styles.sliderDotSize,
          width: Styles.sliderDotSize,
        }}
        handleStyle={{
          borderColor: Styles.textColorPrimary,
          backgroundColor: Styles.defaultColor,
          height: Styles.sliderDotSizeHandle,
          width: Styles.sliderDotSizeHandle,
          marginTop: -12,
          boxShadow: "none",
          marginLeft: demoValue,
          // transform: `translateX(${}px)!important`
          // WebkitAnimation:
          //   props.value === undefined
          //     ? "shake-horizontal 1.2s cubic-bezier(0.455, 0.030, 0.515, 0.955) both"
          //     : "",
          // animation:
          //   props.value === undefined
          //     ? "shake-horizontal 1.2s cubic-bezier(0.455, 0.030, 0.515, 0.955) both"
          //     : "",
        }}
        value={props.value}
        onChange={(value: number) => {
          props.handleChange(value);
        }}
        min={props.min}
        max={props.max}
        handle={handle}
      />
    </div>
  );
}

export type StepperSliderProps = {
  value?: number;
  min: number;
  max: number;
  step?: number;
  marks?: any;
  handleChange: Function;
};

export interface StepperMark {
  [number: number]: { style: any; label: string };
}
