import { Styles } from "../../configuration/AppConfig";
import React from "react";

export default function StepperBackground() {
    return (
        <svg width="1440" height="900" viewBox="0 0 1440 900" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="1440" height="900" fill={Styles.defaultColor} />
            <path d="M0 0H1440V562.387L0 616V0Z" fill={Styles.bgStepper} opacity={Styles.bgStepperOpacity} />
        </svg>
    )
}