import axios from "axios";
import { LocalSettings } from "../configuration/AppConfig";
import { StepperPageTransferData, InitialPageTransferData, GetTransferData, AddressLookupTransferData, BankingOutcomeData, PartnerRequest, BankingStatusUpdate, PartnerDetailsRequest, BankingLoginAndAccountUpdate } from "../store/Store";

const webApiAddress = process.env.REACT_APP_WEB_API_ADDRESS;

const Api = {
    lookupAddress: async function (input: AddressLookupTransferData, successCallback: Function, errorCallback: Function) {
        const url = webApiAddress + `/api/application/get`;
    },
    getApplication: async function (input: GetTransferData, successCallback: Function, errorCallback: Function) {
        const url = webApiAddress + `/api/application/get`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    createApplication: async function (input: InitialPageTransferData, successCallback: Function, errorCallback: Function) {
        const url = webApiAddress + `/api/application/create`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    updateApplication: async function (input: StepperPageTransferData, successCallback: Function, errorCallback: Function) {
        let url = webApiAddress + `/api/application/update`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    updateBankingStatus: async function (input: BankingStatusUpdate, successCallback: Function, errorCallback: Function) {
        let url = webApiAddress + `/api/application/update`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    updateBankingLoginAndAccount: async function (input: BankingLoginAndAccountUpdate, successCallback: Function, errorCallback: Function) {
        let url = webApiAddress + `/api/application/update-banking-information`;
        await makePatchRequest(input, url, null, successCallback, errorCallback);
    },
    skipBankingStep: async function (input: BankingOutcomeData, successCallback: Function, errorCallback: Function) {
        let url = webApiAddress + `/api/application/update`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    uploadFile: async function (input: FormData, successCallback: Function, errorCallback: Function, progressCallback: Function) {
        const url = webApiAddress + `/api/application/upload`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (e: any) => { progressCallback(e) }
        }
        await makePostRequest(input, url, config, successCallback, errorCallback);
    },
    getUploadUrl: function (): string {
        return webApiAddress + '/api/application/upload';
    },
    getBankingDataUrl: function (): string {
        let language = LocalSettings.language;
        let flinksUrl = process.env.REACT_APP_FLINKS_URL || '';
        flinksUrl = flinksUrl.replace('language=', `language=${language}`);
        return flinksUrl;
    },
    getHubUrl: function (): string {
        return `${webApiAddress}/apphub`;
    },
    getPartnerData: async function (input: PartnerRequest, successCallback: (result: any) => void, errorCallback: (error: any) => void) {
        let url = webApiAddress + `/api/application/get`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    },
    getPartnerDetails: async function (input: PartnerDetailsRequest, successCallback: (result: any) => void, errorCallback: (error: any) => void) {
        let url = webApiAddress + `/api/application/get`;
        await makePostRequest(input, url, null, successCallback, errorCallback);
    }
}

export default Api;

async function makePostRequest(
    payload: any,
    url: string,
    config: any,
    successCallback: Function,
    errorCallback: Function
) {
    try {
        const response = await axios.post(url, payload, config);
        successCallback(response);
    } catch (errorResponse) {
        errorCallback(errorResponse);
    }
}

async function makePatchRequest(
    payload: any,
    url: string,
    config: any,
    successCallback: Function,
    errorCallback: Function
) {
    try {
        const response = await axios.patch(url, payload, config);
        successCallback(response);
    } catch (errorResponse) {
        errorCallback(errorResponse);
    }
}

