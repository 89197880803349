import React, { useEffect, useState } from "react";
import "./ScrollToTopButton.css";
import { Styles } from "../configuration/AppConfig";
import { HelperFunctions } from "../utils/HelperFunctions";

export default function ScrollButton(props: ScrollButtonProps) {
  useEffect(() => {
    window.addEventListener("scroll", setVisibility);
    return () => {
      window.removeEventListener("scroll", setVisibility);
    };
  }, []);

  const [visible, setVisible] = useState(false);

  function setVisibility() {
    setVisible(window.pageYOffset > 300);
  }

  return (
    <button
      title="Back to top"
      style={{
        backgroundColor: Styles.textColorPrimary,
        width: 60,
        height: 60,
        position: "fixed",
        bottom: 15,
        right: 15,
        borderRadius: 5,
        border: "none",
        visibility: visible ? "visible" : "hidden"
      }}
      className="scroll pulsate-fwd"
      onClick={() => {
        HelperFunctions.scrollToTop(props.delayInMs,props.scrollStepInPx);
      }}
    >
      <span className="arrow up"></span>
    </button>
  );
}

type ScrollButtonProps = {
  scrollStepInPx: number;
  delayInMs: number;
};
