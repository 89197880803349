import { Styles } from "../../configuration/AppConfig";
import React from "react";

export default function InitialBackground() {
    return (
        <svg width="1440" height="900" viewBox="0 0 1440 900" fill={Styles.defaultColor} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <rect width="1440" height="900" fill={Styles.bgInitialTop} opacity={Styles.bgInitialTopOpacity}/>
                <ellipse cx="1099.5" cy="354.5" rx="550.5" ry="545.5" fill={Styles.bgInitialRight} />
                <g filter="url(#filter0_d)">
                    <ellipse cx="-47" cy="876.5" rx="830" ry="876.5" fill={Styles.bgInitialBottom} opacity={Styles.bgInitialBottomOpacity}/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d" x="-881" y="0" width="1668" height="1761" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    {/* <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" /> */}
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    {/* <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /> */}
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                {/* <clipPath id="clip0">
                    <rect width="1440" height="900" fill={Styles.defaultColor} />
                </clipPath> */}
            </defs>
        </svg>

    )
}

export type CheckIconProps = {
    fill: string;
}