import React from "react";
import { LocalSettings, Styles } from "../configuration/AppConfig";
import { Button } from "reactstrap";

export function DocumentPageAction(props: DocumentPageActionProps) {
  const styles = {
    uploadButton: {
      backgroundColor: Styles.textColorStart,
      borderColor: Styles.textColorStart,
      color: Styles.defaultColor,
      width: 120,
    },
    completedButton: {
      backgroundColor: Styles.defaultColor,
      borderColor: Styles.defaultColor,
      color: Styles.textColorSecondary,
      boxShadow: "unset",
    },
    actionContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: Styles.documentUploadBorderRadius,
      backgroundColor: Styles.defaultColor,
      boxShadow:
        "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
      padding: 10,
      margin: "auto",
    },
    textSpan: {
      color: Styles.textColorPrimary,
      fontSize: "1rem",
    },
    textItemDescription: {
      color: "rgb(117, 117, 117)",
      fontSize: "0.8rem",
    },
    icon: {
      margin: 10,
    },
    itemName: {
      width: 300,
      textAlign: "left" as "left",
    },
  };

  const documentPageTranslation = LocalSettings.translation.documentsPage;

  return (
    <div>
      <div className="action-container" style={styles.actionContainer}>
        <div>
          <img
            className="action-icon"
            alt="action-icon"
            src={getImage(props.status)}
            style={styles.icon}
          />
        </div>
        <div style={styles.itemName}>
          <div style={styles.textSpan} className="item-name">
            {props.itemName}
          </div>
          <div style={styles.textItemDescription}>{props.description}</div>
        </div>
        <div>
          <div>{getActionButton(props.status)}</div>
        </div>
      </div>
    </div>
  );

  function getActionButton(status: string) {
    switch (status) {
      // Incompleted
      case `${documentPageTranslation.requestedButtonText}`:
        return (
          <div>
            <Button
              className="start-button"
              style={styles.uploadButton}
              onClick={() => props.action()}
            >
              {documentPageTranslation.requestedButtonText}
            </Button>
          </div>
        );
      // In Progress
      case `${documentPageTranslation.continueButtonText}`:
        return (
          <div>
            <Button
              className="start-button"
              style={styles.uploadButton}
              onClick={() => props.action()}
            >
              {documentPageTranslation.continueButtonText}
            </Button>
          </div>
        );
      // Completed
      case `${documentPageTranslation.completedButtonText}`:
        return (
          <div>
            <Button disabled style={styles.completedButton}>
              {documentPageTranslation.completedButtonText}
            </Button>
          </div>
        );
      default:
        throw new Error(`Unknown Status: ${status}`);
    }
  }

  function getImage(status: string) {
    switch (status) {
      case `${documentPageTranslation.continueButtonText}`:
      case `${documentPageTranslation.requestedButtonText}`:
        return `${process.env.PUBLIC_URL}/assets/svg/warning.svg`;
      case `${documentPageTranslation.completedButtonText}`:
        return `${process.env.PUBLIC_URL}/assets/svg/completed.svg`;
      default:
        throw new Error(`Unknown Status: ${status}`);
    }
  }
}

export type DocumentPageActionProps = {
  itemName: string;
  description: string;
  status: string;
  action: Function;
};
