import React, { useState, useEffect, useCallback } from "react";
import ModalDialog from "./ModalDialog";
import { Styles, AppState, LocalSettings } from "../configuration/AppConfig";
import { Input, Button } from "reactstrap";
import Background from "./Background";
import { isMobile } from "react-device-detect";
import { useMappedState, ActionType, useDispatch } from "../store/Store";
import LoadingScreen, { LoadingAnimation } from "./LoadingScreen";
import { HelperFunctions } from "../utils/HelperFunctions";
import { useParams } from "react-router-dom";
//@ts-ignore
import CurrencyFormat from "react-currency-format";
import Api from "../utils/Api";
import { SignalRMapping, useSignalR } from "../hooks/UseSignalR";
import "./QuotePage.css";

const styles = {
  quoteDialogContent: {
    minWidth: 300,
  },
  quoteMultilineText: {
    minWidth: 300,
    width: "75%",
    margin: "auto",
  },
  modalBody: {
    fontSize: "15px",
    fontWeight: 600,
    maxWidth: 500,
    margin: "auto",
    marginBottom: 20,
  },
};

const commonStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
  },
  header: {
    color: "rgb(139, 139, 139)",
    margin: 20,
    fontWeight: 400,
    textAlign: "left" as "left",
  },
  itemGroup: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    float: "right" as "right",
    justifyContent: "center",
    width: "100%",
  },
  itemStyle: {
    margin: 25,
  },
  itemStyleSmall: {
    margin: 5,
  },
  margin: {
    margin: 15,
  },
  label: {
    color: "#559091",
  },
  centered: {
    textAlign: "center" as "center",
    padding: 10,
    color: "#559091",
  },
  bold: {
    fontWeight: 700,
  },
  uppercase: {
    textTransform: "uppercase" as "uppercase",
  },
  underlined: {
    textDecoration: "underline" as "underline",
  },
  bottomBorder: {
    borderBottom: "3px solid #C5D7D6",
  },
  boldText: {
    fontWeight: 800,
  },
  gradient: {
    background: "linear-gradient(90deg, #3FC3EE 3.23%, #33B787 96.48%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "rgba(255,255,255,0)",
  },
};

const quoteStyles = {
  quoteStyle: {
    minWidth: 250,
    textAlign: "center" as "center",
  },
  quotesTitle: {
    margin: 20,
    color: "#919191",
    textAlign: "left" as "left",
    fontWeight: 400,
  },
  group: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center" as "center",
    flexWrap: "wrap" as "wrap",
  },
  item: {
    margin: "auto",
    width: "100%",
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    borderBottom: "1px dotted rgb(145, 145, 145)",
  },
  helpText: {
    margin: 8,
    minWidth: 120,
    textAlign: "left" as "left",
  },
  title: {
    color: Styles.textColorPrimary,
  },
  largeTitle: {
    fontSize: "2.5rem",
  },
  label: {
    color: "#919191",
    fontWeight: 500,
    // position: "absolute",
    left: 0,
  },
  value: {
    color: "#919191",
    fontWeight: 500,
    textAlign: "left" as "left",
    // position: "absolute",
    right: 0,
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  image: {
    width: 150,
    float: "left",
  },
  relative: {
    position: "relative" as "relative",
    padding: 0,
  },
  error: {
    color: "red",
  },
};

const sidebarStyles = {
  itemStyle: {
    margin: 25,
  },
  buttonStyle: {
    color: Styles.defaultColor,
    boxShadow: "none",
  },
  declineButton: {
    backgroundColor: Styles.textColorWarning,
    borderColor: Styles.textColorWarning,
  },
  acceptButton: {
    backgroundColor: Styles.textColorSecondary,
    borderColor: Styles.textColorSecondary,
  },
  changeButton: {
    backgroundColor: Styles.textColorPrimary,
    borderColor: Styles.textColorPrimary,
  },
  value: {
    color: "#919191",
    fontWeight: "500",
    margin: 15,
  },
  headerStyle: {
    color: "#919191",
    margin: 20,
    fontWeight: 400,
  },
  paperStyle: {
    color: "#919191",
    width: "100%",
    float: "left",
  },
};

export default function QuotePage(props: QuoteProps) {
  const dispatch = useDispatch();
  const data = useQuoteState(props);

  const { applicationId } = useParams<{ applicationId: string }>();
  if (applicationId == null)
    throw new Error("applicationId should be specified!");

  const [dialogName, setDialogName] = useState(QuoteDialog.NONE);

  const [quoteLoadingState, setQuoteLoadingState] = useState<QuoteLoadingState>(
    {
      loadingAnimation: LoadingAnimation.DEFAULT,
      loadingMessage: "Please wait",
      loadingMessageDescription: "We're loading your application",
    }
  );

  const [quoteAmount, setQuoteAmount] = useState(0);

  useEffect(() => {
    // HelperFunctions.setPageTitle("Application - Merchant Growth");
  }, []);

  const setReadyState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.READY }),
    [dispatch]
  );

  let handlers: Array<SignalRMapping> = [
    {
      hubFunctionName: "NotifyStatus",
      hubCallback: (result: any) => {
        switch (result.status) {
          case 0:
            setQuoteLoadingState({
              loadingAnimation: LoadingAnimation.DEFAULT,
              loadingMessage: "Please wait",
              loadingMessageDescription: "We're loading your application",
            });
            break;
          case 1:
            setQuoteLoadingState({
              loadingAnimation: LoadingAnimation.PROCESSING,
              loadingMessage: "Processing you bank statements",
              loadingMessageDescription: "This can take up to 2 minutes",
            });
            break;
          case 2:
            setQuoteLoadingState({
              loadingAnimation: LoadingAnimation.SCORING,
              loadingMessage: "Calculating your score",
              loadingMessageDescription: "We're calculating your score",
            });
            break;
          case 3:
            setQuoteLoadingState({
              loadingAnimation: LoadingAnimation.QUOTING,
              loadingMessage: "Preparing the quote",
              loadingMessageDescription:
                "We're preparing a personalized quote for you",
            });
            break;
          case 4:
            setTimeout(() => {
              const input = {
                type: "Self",
                applicationId: applicationId,
              };
              Api.getApplication(
                input,
                (response: any) => {
                  let data = response.data;
                  setQuoteAmount(data.quoteAmount);
                  setReadyState();
                },
                (error: any) => {
                  alert(JSON.stringify(error));
                  // setErrorState();
                }
              );
            }, 1500);
            break;
        }
      },
    },
  ];

  useSignalR(applicationId, Api.getHubUrl(), handlers);

  useEffect(() => {
    HelperFunctions.setPageTitle(`Quote - ${LocalSettings.companyName}`);
  }, []);

  return renderQuotePage();

  function useQuoteState(props: QuoteProps): QuoteState {
    const { applicationState } = useMappedState((state) => state);
    return {
      applicationState: applicationState,
    };
  }

  function renderQuotePage() {
    switch (data.applicationState) {
      case AppState.LOADING:
        return (
          <LoadingScreen
            animation={quoteLoadingState.loadingAnimation}
            loadingMessage={quoteLoadingState.loadingMessage}
            loadingMessageDescription={
              quoteLoadingState.loadingMessageDescription
            }
          />
        );
      case AppState.READY:
        return (
          <Background
            image="initial"
            useTemplate={true}
            useFooter={true}
            useSvg={true}
          >
            {renderQuoteBody()}
            {renderQuoteModalDialog()}
          </Background>
        );
      default:
        throw new Error(`Unsupported state: ${data.applicationState}`);
    }
  }

  function renderQuoteBody() {
    return (
      <div
        className="quoteContainer"
        //   ref="quoteContainer"
        style={commonStyles.container}
      >
        <div
          style={{
            // ...commonStyles.bottomBorder,
            ...commonStyles.bold,
          }}
        >
          <div>
            <div
              style={{
                ...commonStyles.centered,
                ...quoteStyles.title,
                fontWeight: 700,
              }}
            >
              Congratulations! You've been pre-qualified for:
            </div>
            <div>
              <div>
                <div
                  style={{
                    ...commonStyles.centered,
                    ...quoteStyles.title,
                    fontSize: isMobile ? "2.5rem" : "3rem",
                    margin: 10,
                    fontWeight: 400,
                  }}
                  className={
                    dialogName === QuoteDialog.NONE ? "pulsate-bck" : ""
                  }
                >
                  {HelperFunctions.formatCurrency(quoteAmount, 2, 2)}
                  <img
                    style={{ margin: "auto" }}
                    width={50}
                    height={50}
                    src={`${process.env.PUBLIC_URL}/assets/svg/quote_finished.svg`}
                    alt="quote_finished"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={quoteStyles.quoteStyle}>
          <div className="quotesHeader" style={commonStyles.header}></div>
          <div>
            <div>
              <div style={quoteStyles.relative}>{renderQuoteDetails()}</div>
            </div>
          </div>
        </div> */}
        {renderQuoteButtons()}
      </div>
    );
  }

  function renderQuoteDetails() {
    let quote = {
      isSuccessful: true,
      weeklyPaymentAmount: 400,
      totalInterest: 10,
      percentage: 4,
      reason: "",
      term: 12,
    };

    if (quote.isSuccessful) {
      return (
        <div className="quoteGroup">
          {/* <div style={quoteStyles.item}>
                <div style={quoteStyles.label}>Total Loan Amount:</div>
                <div style={quoteStyles.value}>
                  <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    value={quote.purchasePrice}
                  />
                </div>
              </div> */}
          <div style={quoteStyles.item}>
            <div style={quoteStyles.label}>Weekly Payment:</div>
            <div style={quoteStyles.value}>
              <CurrencyFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={quote.weeklyPaymentAmount}
              />
            </div>
          </div>
          <div style={quoteStyles.item}>
            <div style={quoteStyles.label}>Terms:</div>
            <div style={quoteStyles.value}>{quote.term} Months</div>
          </div>
          <div style={quoteStyles.item}>
            <div style={quoteStyles.label}>Total Interest:</div>
            <div style={quoteStyles.value}>
              <CurrencyFormat
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                value={quote.totalInterest}
              />
            </div>
          </div>
          <div style={quoteStyles.item}>
            <div style={quoteStyles.label}>Simple Percentage:</div>
            <div style={quoteStyles.value}>{quote.percentage.toFixed(2)}%</div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={quoteStyles.group} className="quoteGroup">
          <div style={quoteStyles.helpText}>{quote.reason}</div>
        </div>
      );
    }
  }

  function renderQuoteButtons() {
    if (isMobile) {
      return (
        <div className="buttonsSmall" style={commonStyles.itemGroup}>
          <div style={{ ...commonStyles.itemStyleSmall, ...{ width: "100%" } }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.acceptButton,
                ...{ width: "100%" },
              }}
              // className={dialogName === QuoteDialog.NONE ? "pulsate-bck" : ""}
              onClick={() => setDialogName(QuoteDialog.ACCEPT)}
            >
              <span className="btn-inner--icon">
                {/* <DoneIcon className={classes.leftIcon} /> */}
              </span>
              <span className="btn-inner--text" style={commonStyles.uppercase}>
                Tap to accept quote
              </span>
            </Button>
            {/* </Swipe> */}
          </div>
          <div style={{ ...commonStyles.itemStyleSmall, ...{ flexGrow: 1 } }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.declineButton,
                ...{ width: "100%", flexGrow: 1 },
              }}
              onClick={() => setDialogName(QuoteDialog.DECLINE)}
            >
              <span className="btn-inner--icon">
                {/* <ClearIcon className={classes.leftIcon} /> */}
              </span>
              <span className="btn-inner--text" style={commonStyles.uppercase}>
                Decline quote
              </span>
            </Button>
          </div>
          <div style={{ ...commonStyles.itemStyleSmall, ...{ flexGrow: 1 } }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.changeButton,
                ...{ width: "100%" },
              }}
              onClick={() => setDialogName(QuoteDialog.CHANGE_TERMS)}
            >
              <span className="btn-inner--icon">
                {/* <ListIcon className={classes.leftIcon} /> */}
              </span>
              <span className="btn-inner--text" style={commonStyles.uppercase}>
                Change quote
              </span>
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="buttonsRegular" style={commonStyles.itemGroup}>
          <div style={commonStyles.itemStyle}>
            <Button
              id="acceptQuoteButton"
              variant="contained"
              size="large"
              color="secondary"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.acceptButton,
              }}
              onClick={() => setDialogName(QuoteDialog.ACCEPT)}
            >
              {/* <DoneIcon className={classes.leftIcon} /> */}
              Accept Quote
            </Button>
          </div>
          <div style={commonStyles.itemStyle}>
            <Button
              id="declineQuoteButton"
              variant="contained"
              size="large"
              color="primary"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.declineButton,
              }}
              onClick={() => setDialogName(QuoteDialog.DECLINE)}
            >
              {/* <ClearIcon className={classes.leftIcon} /> */}
              Decline Quote
            </Button>
          </div>
          <div style={commonStyles.itemStyle}>
            <Button
              id="changeQuoteTermsButton"
              variant="contained"
              size="large"
              color="error"
              style={{
                ...sidebarStyles.buttonStyle,
                ...sidebarStyles.changeButton,
              }}
              onClick={() => setDialogName(QuoteDialog.CHANGE_TERMS)}
            >
              {/* <ListIcon className={classes.leftIcon} /> */}
              Change Terms
            </Button>
          </div>
        </div>
      );
    }
  }

  function redirectToNextStep() {
    window.location.href = `/documents/${applicationId}`;
  }

  function renderQuoteModalDialog() {
    let dialogDetails: any = {};

    switch (dialogName) {
      case QuoteDialog.ACCEPT:
        dialogDetails.buttonAgreeStyle = {
          color: Styles.defaultColor,
          backgroundColor: Styles.textColorSecondary,
          boxShadow: "none",
        };
        dialogDetails.buttonDisagreeStyle = { display: "none" };
        dialogDetails.agreeCaption = "Proceed with your application";
        dialogDetails.disagreeCaption = "Close";
        dialogDetails.hideCloseButton = true;
        dialogDetails.handleAgree = () => {
          redirectToNextStep();
        };
        dialogDetails.handleDisagree = () => {
          setDialogName(QuoteDialog.NONE);
        };
        dialogDetails.body = (
          <div style={{ textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/modal_check.svg`}
              alt="success"
            />
            <h1>Success!</h1>
            <div style={styles.modalBody}>
              <div>Thank you for accepting the quote!</div>
              <div>Please proceed to complete the application</div>
            </div>
            {/* <Input
                type="textarea"
                placeholder="Any Additional Notes?"
                value={quoteMessages.accepted}
                style={styles.quoteMultilineText}
                onChange={e => {
                  this.props.handleQuoteMessageChange("accepted", e);
                }}
              /> */}
          </div>
        );
        break;
      case QuoteDialog.DECLINE:
        dialogDetails.agreeCaption = "Submit";
        dialogDetails.disagreeCaption = "Go Back";
        dialogDetails.buttonAgreeStyle = {
          color: Styles.defaultColor,
          backgroundColor: Styles.textColorPrimary,
          boxShadow: "none",
        };
        dialogDetails.buttonDisagreeStyle = {
          color: Styles.textColorPrimary,
          backgroundColor: Styles.defaultColor,
          boxShadow: "none",
        };
        dialogDetails.handleAgree = () => {
          redirectToNextStep();
          // this.props.handleQuoteStatus("declined");
        };
        dialogDetails.handleDisagree = () => {
          setDialogName(QuoteDialog.NONE);
        };
        dialogDetails.body = (
          <div style={{ textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/modal_exclaimation.svg`}
              alt="exclaimation"
            />
            <h1>How can we do better?</h1>
            <div style={styles.modalBody}>
              <div>We would love to understand your needs better!</div>
              <div>
                Drop us a line below and one of our funding specialists will
                take a closer look at your application
              </div>
            </div>
            <Input
              placeholder="Reason for Rejection"
              type="textarea"
              //   value={quoteMessages.declined}
              value={"Declined!"}
              style={styles.quoteMultilineText}
              onChange={(e) => {
                // this.props.handleQuoteMessageChange("declined", e);
              }}
            />
          </div>
        );
        break;
      case QuoteDialog.CHANGE_TERMS:
        dialogDetails.agreeCaption = "Request Change";
        dialogDetails.disagreeCaption = "Close";
        dialogDetails.buttonAgreeStyle = {
          color: Styles.defaultColor,
          backgroundColor: Styles.textColorPrimary,
          boxShadow: "none",
        };
        dialogDetails.buttonDisagreeStyle = {
          color: Styles.textColorPrimary,
          backgroundColor: Styles.defaultColor,
          boxShadow: "none",
        };
        dialogDetails.handleAgree = () => {
          redirectToNextStep();
          // this.props.handleQuoteStatus("changeterms");
        };
        dialogDetails.handleDisagree = () => {
          setDialogName(QuoteDialog.NONE);
        };
        dialogDetails.body = (
          <div style={{ textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/modal_question.svg`}
              alt="exclaimation"
            />
            <h1>What should we change?</h1>
            <div style={styles.modalBody}>
              <div>Please let us know what terms you would like to modify.</div>
              <div>
                One of our funding specialists will be in contact with you
                shortly to discuss alternative options.
              </div>
            </div>
            <Input
              placeholder="Change terms"
              type="textarea"
              value="Changed!"
              //   value={quoteMessages.changeterms}
              style={styles.quoteMultilineText}
              onChange={(e) => {
                // this.props.handleQuoteMessageChange("changeterms", e);
              }}
            />
          </div>
        );
        break;
      default:
        return;
    }

    return (
      <ModalDialog
        title={dialogDetails.title}
        agreeText={dialogDetails.agreeCaption}
        disagreeText={dialogDetails.disagreeCaption}
        open={true}
        hideCloseButton={dialogDetails.hideCloseButton}
        buttonAgreeStyle={dialogDetails.buttonAgreeStyle}
        buttonDisagreeStyle={dialogDetails.buttonDisagreeStyle}
        handleAgreed={() => {
          dialogDetails.handleAgree();
        }}
        handleDisagreed={() => {
          dialogDetails.handleDisagree();
        }}
        handleClose={() => {
          setDialogName(QuoteDialog.NONE);
        }}
      >
        <div style={styles.quoteDialogContent}>{dialogDetails.body}</div>
      </ModalDialog>
    );
  }
}

enum QuoteDialog {
  ACCEPT = "accept",
  DECLINE = "decline",
  CHANGE_TERMS = "change_terms",
  NONE = "",
}

type QuoteState = {
  applicationState: AppState;
};

type QuoteProps = {};

type QuoteLoadingState = {
  loadingAnimation: LoadingAnimation;
  loadingMessage: string;
  loadingMessageDescription: string;
};
