import React, { ChangeEvent } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { Form, Col, Row } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import AddressLookup from "../AddressLookup";
import { isMobile } from "react-device-detect";

const styles = {
  itemCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap" as "wrap",
  },
  container: {
    padding: 30,
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
  },
  item: {
    margin: 10,
  },
};

export default function StepHomeAddress(props: StepHomeAddressProps) {
  const dispatch = useDispatch();
  const data = useStepHomeAddress();

  const homeAddressStepTranslation =
    LocalSettings.translation.applicationPage.homeAddressStep;

  //   let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  //   let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";
  let labelStyle = { fontSize: "1rem" };
  return (
    <div id="stepHomeAddress">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {homeAddressStepTranslation.homeAddress}
      </h2>
      {/* <h4
        style={{
          color: Styles.textColorTitleSecondary,
          textAlign: "center" as "center",
        }}
      >
      </h4>  */}
      <div className="stepper-form">
        <Form>
          <Row form>
            <Col md={12}>
              <AddressLookup
                onAddressChange={(address, propName) => {
                  data.handleChangeManual(address, propName);
                  if (props.skipBusinessAddress) {
                    data.handleChangeManual(
                      data.homeAddress,
                      "businessAddress"
                    );
                  }
                }}
                placeholder={homeAddressStepTranslation.addressPlaceHolder}
                value={data.homeAddress}
                name="homeAddress"
                label={homeAddressStepTranslation.homeAddress}
                className="stepper-item"
                bsSize={size}
                labelStyle={labelStyle}
              />
            </Col>
            <Col>
              <div style={styles.itemCheckbox}>
                <div style={styles.item}>
                  {homeAddressStepTranslation.skipBusinessAddress}
                </div>
                <label style={styles.item} className="switch">
                  <input
                    readOnly={true}
                    checked={props.skipBusinessAddress}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const skipBusinessAddress = e.target.checked;
                      props.setBusinessAddressCallback(skipBusinessAddress);
                      if (skipBusinessAddress) {
                        data.handleChangeManual(
                          data.homeAddress,
                          "businessAddress"
                        );
                      }
                    }}
                    id="businessAddressSwitch"
                    type="checkbox"
                  />
                  <span
                    style={{
                      backgroundColor: props.skipBusinessAddress
                        ? Styles.textColorPrimary
                        : "#b0b0b0",
                    }}
                    className="slider round"
                  ></span>
                </label>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  function useStepHomeAddress(): StepHomeAddressState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );
    const handleChangeManual = useCallback(
      (value: string, propertyName: string) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        }),
      []
    );
    return {
      homeAddress: input.homeAddress,
      handleChange: handleChange,
      handleChangeManual: handleChangeManual,
    };
  }
}

type StepHomeAddressState = {
  homeAddress: string;
  handleChange: Function;
  handleChangeManual: Function;
};

type StepHomeAddressProps = {
  skipBusinessAddress: boolean;
  setBusinessAddressCallback: (skipStep: boolean) => void;
};
