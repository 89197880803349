import React, { useEffect, useCallback } from "react";
import Background from "./Background";
import { useDispatch, ActionType, useMappedState } from "../store/Store";
import { AppState, LocalSettings, Styles } from "../configuration/AppConfig";
import LoadingScreen from "./LoadingScreen";
import { HelperFunctions } from "../utils/HelperFunctions";
import LinkedInTag from "react-linkedin-insight";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

export default function FinishPage() {
  const dispatch = useDispatch();
  const { applicationState } = useMappedState((state) => state);
  const setReadyState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.READY }),
    [dispatch]
  );

  const finishTranslation = LocalSettings.translation.finishPage;

  useEffect(() => {
    HelperFunctions.setPageTitle(
      `${finishTranslation.pageName} - ${LocalSettings.companyName}`
    );

    LinkedInTag.init("3430250");
    LinkedInTag.track();

    ReactGA.initialize("UA-15983938-3");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const tagManagerArgs = {
      gtmId: "GTM-KW3ZJC7",
    };

    TagManager.initialize(tagManagerArgs);

    setTimeout(() => {
      setReadyState();
      setTimeout(() => {
        if (LocalSettings.website) window.location.href = LocalSettings.website;
      }, 5000);
    }, 1500);
  }, []);

  return renderFinishPage();

  function renderFinishPage() {
    return (
      <div>
        <div
          style={{
            display: applicationState === AppState.LOADING ? "block" : "none",
          }}
        >
          <LoadingScreen />
        </div>
        <div
          style={{
            display: applicationState === AppState.READY ? "block" : "none",
          }}
        >
          <Background
            image="completed"
            useTemplate={true}
            useFooter={false}
            useLogo={true}
            useSvg={false}
          >
            <div
              style={{
                textAlign: "center",
                paddingBottom: 120,
                paddingTop: 60,
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/svg/completed_clap.svg`}
                alt="clap"
              ></img>
              <h1
                className="display-4"
                style={{ color: Styles.textColorPrimary }}
              >
                {finishTranslation.mainTitle}
              </h1>
              <div style={{ color: Styles.textColorPrimary }}>
                {finishTranslation.secondaryTitle}
              </div>
            </div>
          </Background>
        </div>
      </div>
    );
  }
}
