import React from "react";
import { Button, Modal } from "reactstrap";
import CSS from "csstype";
import "./ModalDialog.css"
import { Styles } from "../configuration/AppConfig";

export default function ModalDialog(props: ModalDialogProps) {
  const {
    open,
    textStyle,
    agreeText,
    agreeEnabled,
    disagreeText,
    buttonAgreeStyle,
    buttonDisagreeStyle,
    contentClassName,
    contentStyle,
    hideCloseButton,
    title,
    handleClose,
    handleAgreed,
    handleDisagreed,
    children
  } = props;

  function keyboardEvents(event : React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();
    console.log("PRESSED: " + event.key);
  }

  if (!open)
    return (<div></div>)

  return (
    <div onKeyPress={keyboardEvents} tabIndex={0}>
      <div className="modal animated fadeInBottom show" aria-labelledby="responsive-dialog-title">
        <div className="modal-dialog modal-dialog-centered">
          <div className={contentClassName ? `modal-content ${contentClassName}` : "modal-content"}
            style={contentStyle}
          >
            <div className="modal-header">
              <h6
                className="modal-title"
                style={textStyle}
              >
                {title}
              </h6>
              <button
                style={{ display: hideCloseButton ? "none" : "inherit", opacity: 1, fontSize: 12 }}
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => handleClose()}
              >
                <span style={{color: Styles.defaultColor}} aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{children}</p>
            </div>
            <div className="modal-footer" style={{ justifyContent: "center" }}>
              <Button
                style={buttonDisagreeStyle}
                onClick={() => handleDisagreed()}
              >
                {(() => {
                  if (disagreeText) return disagreeText;
                  else return "Disagree";
                })()}
              </Button>
              <Button
                style={buttonAgreeStyle}
                disabled={agreeEnabled !== undefined ? !agreeEnabled : false}
                onClick={() => handleAgreed()}
                autoFocus
              >
                {(() => {
                  if (agreeText) return agreeText;
                  else return "Agree";
                })()}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

type ModalDialogProps = {
  open: boolean;
  textStyle?: CSS.Properties;
  agreeText?: string;
  agreeEnabled?: boolean;
  disagreeText?: string;
  buttonAgreeStyle: CSS.Properties;
  buttonDisagreeStyle: CSS.Properties;
  contentClassName?: string;
  contentStyle?: CSS.Properties;
  hideCloseButton: boolean;
  title: string;
  handleClose: Function;
  handleDisagreed: Function;
  handleAgreed: Function;
  children: any;
};
