import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import "./AutoSuggestLookup.css";
import { isMobile } from "react-device-detect";
import { Styles } from "../configuration/AppConfig";

export default function AutoSuggestLookup(props: AutoSuggestLookup) {
  function getSuggestionValue(suggestion: { label: string, name: string }) {
    return suggestion.label ?? suggestion.name;
  }

  function renderSuggestion(suggestion: { name: string; label: string }) {
    return <span>{suggestion.label ?? suggestion.name}</span>;
  }

  function renderSectionTitle(section: { industry: string; label: string }) {
    return (
      <h3 style={{ color: Styles.textColorPrimary }}>
        {section.label ?? section.industry}
      </h3>
    );
  }

  function getSectionSuggestions(section: any) {
    return section.subIndustries;
  }

  const [value, setValue] = useState(props.value);
  const [suggestions, setSuggestions] = useState<Array<any>>([]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function onChange(event: any, { newValue, method }: any) {
    setValue(newValue);
    if (props.changeCallback) props.changeCallback();
  }

  function onFocus(event: any) {
    setSuggestions(props.getSuggestions(value));
  }

  function onSuggestionsFetchRequested(params: {
    value: string;
    reason: string;
  }) {
    setSuggestions(props.getSuggestions(params.value));
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any
  ) {
    if (props.selectedCallback)
      props.selectedCallback(suggestionValue, suggestion);
  }

  function shouldRenderSuggestions(value: any) {
    return true;
  }

  // let lookupClassName = "form-control-" + (isMobile ? "md" : "lg");
  let lookupClassName = "form-control-lg";

  const inputProps = {
    placeholder: props.placeholder,
    value,
    onChange: onChange,
    className: `${lookupClassName} form-control`,
    onFocus: onFocus,
  };

  return (
    <div onClick={props.clickCallback}>
      <Autosuggest
        multiSection={true}
        suggestions={suggestions}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  );
}

type AutoSuggestLookup = {
  placeholder: string;
  value: string;
  getSuggestions(suggestion: string): Array<any>;
  selectedCallback?(suggestionValue: string, sectionIndex: number): void;
  changeCallback?(): void;
  clickCallback?(): void;
};
