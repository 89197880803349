import { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

export const useSignalR = (
  applicationId: string,
  url: string,
  mapping: Array<SignalRMapping>
): void => {

  useEffect(() => {
    const createHubConnection = async () => {
      const hubConnect = new HubConnectionBuilder().withUrl(url).build();

      try {
        await hubConnect.start();
        console.log("Connection successful!");
      } catch (error) {
        alert(JSON.stringify(error));
      }

      mapping.forEach(mapping => {
        hubConnect.on(mapping.hubFunctionName, mapping.hubCallback);
      });

      const connectData : SignalRConnectData = {
          applicationId,
          // TODO: make it an input parameter
          type : "Self_Status"
      }

      hubConnect.invoke("Connect", JSON.stringify(connectData)).catch(function(data) {
        alert("Oops, we cannot connect to the server...");
      });

      return () => {
        hubConnect.stop();
      };
    };
    createHubConnection();
  }, []);
  
};

export type SignalRMapping = {
  hubCallback(...args: any[]): void;
  hubFunctionName: string;
};

type SignalRConnectData = {
    applicationId: string,
    type: string
}

